._tutorial-list_1u70p_11t5q_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:0;
  margin:0;
}

._tutorial-title_1u70p_11t5q_15:not(#\9){
  display:flex;
  align-items:flex-start;
  margin-left:calc(-1 * 0.4rem);
  margin-left:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
  padding-bottom:0.2rem;
  padding-bottom:var(--space-xxxs-10EX_Z, 0.2rem);
}

._task_1u70p_11t5q_22:not(#\9){
  list-style:none;
  padding-top:0;
}
._task_1u70p_11t5q_22:not(#\9):not(:first-child){
  margin-top:2.4rem;
  margin-top:var(--space-xl-3JXLlv, 2.4rem);
}

._task-title_1u70p_11t5q_30:not(#\9){
  display:flex;
  align-items:flex-start;
}

._current-task_1u70p_11t5q_35:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._successful-task_1u70p_11t5q_39:not(#\9){
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}

._congratulation-message_1u70p_11t5q_43:not(#\9){
  display:flex;
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
  margin-top:0.4rem;
  margin-top:var(--space-xxs-137Hig, 0.4rem);
}

._plaintext-congratulation-description_1u70p_11t5q_51:not(#\9){
  white-space:pre-line;
}

._divider_1u70p_11t5q_55:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._step-list_1u70p_11t5q_59:not(#\9){
  list-style:none;
  padding:0;
}

._step_1u70p_11t5q_59:not(#\9):not(:first-child){
  padding-top:0.4rem;
  padding-top:var(--space-xxs-137Hig, 0.4rem);
}

._expandable-section-wrapper_1u70p_11t5q_68:not(#\9){
  width:100%;
}
._wrapper_1wepg_zxjdx_3:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:flex;
}

._label-wrapper_1wepg_zxjdx_10:not(#\9){
  position:relative;
  display:flex;
}

._content_1wepg_zxjdx_15:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
}

._empty-content_1wepg_zxjdx_21:not(#\9){
  width:0px;
}

._description_1wepg_zxjdx_25:not(#\9){
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}
._description-bottom-padding_1wepg_zxjdx_30:not(#\9){
  padding-bottom:0.4rem;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._label_1wepg_zxjdx_10:not(#\9),
._description_1wepg_zxjdx_25:not(#\9){
  padding-left:0.8rem;
  padding-left:var(--space-xs-1QR0Ng, 0.8rem);
}
._label-disabled_1wepg_zxjdx_38:not(#\9),
._description-disabled_1wepg_zxjdx_39:not(#\9){
  color:#aab7b8;
  color:var(--color-text-control-disabled-KGAbe7, #aab7b8);
}

._control_1wepg_zxjdx_43:not(#\9){
  position:relative;
}
._control_1wepg_zxjdx_43 > input:not(#\9), ._control_1wepg_zxjdx_43 > svg:not(#\9), ._control_1wepg_zxjdx_43 > ._outline_1wepg_zxjdx_46:not(#\9){
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
}
._control_1wepg_zxjdx_43 > input:not(#\9){
  opacity:0;
  z-index:1;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  cursor:default;
  margin:0;
}
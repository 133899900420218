._item-element_93a1u_1kjp4_3:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:block;
  position:relative;
  z-index:1;
  border:1px solid transparent;
  list-style:none;
  padding:0.4rem 2rem;
  padding:var(--space-xxs-137Hig, 0.4rem) var(--space-l-3cXvov, 2rem);
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
  margin-top:-1px;
}
._item-element_93a1u_1kjp4_3:not(#\9):not(._disabled_93a1u_1kjp4_16){
  cursor:pointer;
}
._item-element_93a1u_1kjp4_3:not(#\9):first-child{
  margin-top:0;
}
._item-element_93a1u_1kjp4_3:not(#\9):focus{
  outline:none;
}
._item-element_93a1u_1kjp4_3._last_93a1u_1kjp4_25:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-dropdown-group-PaVVo1, #eaeded);
}
._item-element_93a1u_1kjp4_3._highlighted_93a1u_1kjp4_28:not(#\9), ._item-element_93a1u_1kjp4_3:not(#\9):hover:not(._disabled_93a1u_1kjp4_16){
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
  color:#16191f;
  color:var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f);
  border-color:#879596;
  border-color:var(--color-border-dropdown-item-hover-3Saafg, #879596);
  cursor:pointer;
  z-index:2;
}
._item-element_93a1u_1kjp4_3._disabled_93a1u_1kjp4_16:not(#\9){
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
}
._item-element_93a1u_1kjp4_3._has-category-header_93a1u_1kjp4_38:not(#\9){
  padding-left:3.2rem;
  padding-left:var(--space-xxl-3_q1V_, 3.2rem);
}
._item-element_93a1u_1kjp4_3._item-link_93a1u_1kjp4_41:not(#\9){
  padding:0;
}
._item-element_93a1u_1kjp4_3._item-link_93a1u_1kjp4_41 > a:not(#\9){
  display:block;
  padding:0.4rem 2rem;
  padding:var(--space-xxs-137Hig, 0.4rem) var(--space-l-3cXvov, 2rem);
  color:currentColor;
  text-decoration:none;
}
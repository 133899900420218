._grid_14yj0_17rq4_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  flex-wrap:wrap;
  margin:calc(2rem / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / -2);
}
._grid_14yj0_17rq4_3._no-gutters_14yj0_17rq4_15:not(#\9){
  margin:0;
}

._grid-column_14yj0_17rq4_19:not(#\9){
  box-sizing:border-box;
  position:relative;
  padding:calc(2rem / 2);
  padding:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
}
._grid_14yj0_17rq4_3._no-gutters_14yj0_17rq4_15 > ._grid-column_14yj0_17rq4_19:not(#\9){
  padding:0;
}
._grid-column_14yj0_17rq4_19._colspan-1_14yj0_17rq4_27:not(#\9){
  flex:0 0 8.3333333333%;
  max-width:8.3333333333%;
}
._grid-column_14yj0_17rq4_19._push-1_14yj0_17rq4_31:not(#\9){
  left:8.3333333333%;
}
._grid-column_14yj0_17rq4_19._pull-1_14yj0_17rq4_34:not(#\9){
  right:8.3333333333%;
}
._grid-column_14yj0_17rq4_19._colspan-2_14yj0_17rq4_37:not(#\9){
  flex:0 0 16.6666666667%;
  max-width:16.6666666667%;
}
._grid-column_14yj0_17rq4_19._push-2_14yj0_17rq4_41:not(#\9){
  left:16.6666666667%;
}
._grid-column_14yj0_17rq4_19._pull-2_14yj0_17rq4_44:not(#\9){
  right:16.6666666667%;
}
._grid-column_14yj0_17rq4_19._colspan-3_14yj0_17rq4_47:not(#\9){
  flex:0 0 25%;
  max-width:25%;
}
._grid-column_14yj0_17rq4_19._push-3_14yj0_17rq4_51:not(#\9){
  left:25%;
}
._grid-column_14yj0_17rq4_19._pull-3_14yj0_17rq4_54:not(#\9){
  right:25%;
}
._grid-column_14yj0_17rq4_19._colspan-4_14yj0_17rq4_57:not(#\9){
  flex:0 0 33.3333333333%;
  max-width:33.3333333333%;
}
._grid-column_14yj0_17rq4_19._push-4_14yj0_17rq4_61:not(#\9){
  left:33.3333333333%;
}
._grid-column_14yj0_17rq4_19._pull-4_14yj0_17rq4_64:not(#\9){
  right:33.3333333333%;
}
._grid-column_14yj0_17rq4_19._colspan-5_14yj0_17rq4_67:not(#\9){
  flex:0 0 41.6666666667%;
  max-width:41.6666666667%;
}
._grid-column_14yj0_17rq4_19._push-5_14yj0_17rq4_71:not(#\9){
  left:41.6666666667%;
}
._grid-column_14yj0_17rq4_19._pull-5_14yj0_17rq4_74:not(#\9){
  right:41.6666666667%;
}
._grid-column_14yj0_17rq4_19._colspan-6_14yj0_17rq4_77:not(#\9){
  flex:0 0 50%;
  max-width:50%;
}
._grid-column_14yj0_17rq4_19._push-6_14yj0_17rq4_81:not(#\9){
  left:50%;
}
._grid-column_14yj0_17rq4_19._pull-6_14yj0_17rq4_84:not(#\9){
  right:50%;
}
._grid-column_14yj0_17rq4_19._colspan-7_14yj0_17rq4_87:not(#\9){
  flex:0 0 58.3333333333%;
  max-width:58.3333333333%;
}
._grid-column_14yj0_17rq4_19._push-7_14yj0_17rq4_91:not(#\9){
  left:58.3333333333%;
}
._grid-column_14yj0_17rq4_19._pull-7_14yj0_17rq4_94:not(#\9){
  right:58.3333333333%;
}
._grid-column_14yj0_17rq4_19._colspan-8_14yj0_17rq4_97:not(#\9){
  flex:0 0 66.6666666667%;
  max-width:66.6666666667%;
}
._grid-column_14yj0_17rq4_19._push-8_14yj0_17rq4_101:not(#\9){
  left:66.6666666667%;
}
._grid-column_14yj0_17rq4_19._pull-8_14yj0_17rq4_104:not(#\9){
  right:66.6666666667%;
}
._grid-column_14yj0_17rq4_19._colspan-9_14yj0_17rq4_107:not(#\9){
  flex:0 0 75%;
  max-width:75%;
}
._grid-column_14yj0_17rq4_19._push-9_14yj0_17rq4_111:not(#\9){
  left:75%;
}
._grid-column_14yj0_17rq4_19._pull-9_14yj0_17rq4_114:not(#\9){
  right:75%;
}
._grid-column_14yj0_17rq4_19._colspan-10_14yj0_17rq4_117:not(#\9){
  flex:0 0 83.3333333333%;
  max-width:83.3333333333%;
}
._grid-column_14yj0_17rq4_19._push-10_14yj0_17rq4_121:not(#\9){
  left:83.3333333333%;
}
._grid-column_14yj0_17rq4_19._pull-10_14yj0_17rq4_124:not(#\9){
  right:83.3333333333%;
}
._grid-column_14yj0_17rq4_19._colspan-11_14yj0_17rq4_127:not(#\9){
  flex:0 0 91.6666666667%;
  max-width:91.6666666667%;
}
._grid-column_14yj0_17rq4_19._push-11_14yj0_17rq4_131:not(#\9){
  left:91.6666666667%;
}
._grid-column_14yj0_17rq4_19._pull-11_14yj0_17rq4_134:not(#\9){
  right:91.6666666667%;
}
._grid-column_14yj0_17rq4_19._colspan-12_14yj0_17rq4_137:not(#\9){
  flex:0 0 100%;
  max-width:100%;
}
._grid-column_14yj0_17rq4_19._push-12_14yj0_17rq4_141:not(#\9){
  left:100%;
}
._grid-column_14yj0_17rq4_19._pull-12_14yj0_17rq4_144:not(#\9){
  right:100%;
}
._grid-column_14yj0_17rq4_19._push-0_14yj0_17rq4_147:not(#\9){
  left:auto;
}
._grid-column_14yj0_17rq4_19._pull-0_14yj0_17rq4_150:not(#\9){
  right:auto;
}
._grid-column_14yj0_17rq4_19._offset-1_14yj0_17rq4_153:not(#\9){
  margin-left:8.3333333333%;
}
._grid-column_14yj0_17rq4_19._offset-2_14yj0_17rq4_156:not(#\9){
  margin-left:16.6666666667%;
}
._grid-column_14yj0_17rq4_19._offset-3_14yj0_17rq4_159:not(#\9){
  margin-left:25%;
}
._grid-column_14yj0_17rq4_19._offset-4_14yj0_17rq4_162:not(#\9){
  margin-left:33.3333333333%;
}
._grid-column_14yj0_17rq4_19._offset-5_14yj0_17rq4_165:not(#\9){
  margin-left:41.6666666667%;
}
._grid-column_14yj0_17rq4_19._offset-6_14yj0_17rq4_168:not(#\9){
  margin-left:50%;
}
._grid-column_14yj0_17rq4_19._offset-7_14yj0_17rq4_171:not(#\9){
  margin-left:58.3333333333%;
}
._grid-column_14yj0_17rq4_19._offset-8_14yj0_17rq4_174:not(#\9){
  margin-left:66.6666666667%;
}
._grid-column_14yj0_17rq4_19._offset-9_14yj0_17rq4_177:not(#\9){
  margin-left:75%;
}
._grid-column_14yj0_17rq4_19._offset-10_14yj0_17rq4_180:not(#\9){
  margin-left:83.3333333333%;
}
._grid-column_14yj0_17rq4_19._offset-11_14yj0_17rq4_183:not(#\9){
  margin-left:91.6666666667%;
}
._text-content_6absk_4nepk_4:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._text-content_6absk_4nepk_4 h1:not(#\9),
._text-content_6absk_4nepk_4 h2:not(#\9),
._text-content_6absk_4nepk_4 h3:not(#\9),
._text-content_6absk_4nepk_4 h4:not(#\9),
._text-content_6absk_4nepk_4 h5:not(#\9),
._text-content_6absk_4nepk_4 p:not(#\9){
  font-family:inherit;
  font-weight:normal;
  text-decoration:none;
  margin:0;
  padding:0.4rem 0;
  padding:var(--space-xxs-137Hig, 0.4rem) 0;
}
._text-content_6absk_4nepk_4 h1:not(#\9),
._text-content_6absk_4nepk_4 h2:not(#\9),
._text-content_6absk_4nepk_4 h3:not(#\9),
._text-content_6absk_4nepk_4 h4:not(#\9),
._text-content_6absk_4nepk_4 h5:not(#\9){
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}
._text-content_6absk_4nepk_4 h1:not(#\9){
  font-size:2.8rem;
  font-size:var(--size-font-heading-500-14QHat, 2.8rem);
  line-height:3.6rem;
  line-height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
}
._text-content_6absk_4nepk_4 h2:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._text-content_6absk_4nepk_4 h3:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._text-content_6absk_4nepk_4 h4:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._text-content_6absk_4nepk_4 h5:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._text-content_6absk_4nepk_4 h2:not(#\9),
._text-content_6absk_4nepk_4 h4:not(#\9),
._text-content_6absk_4nepk_4 b:not(#\9),
._text-content_6absk_4nepk_4 strong:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}
._text-content_6absk_4nepk_4 p:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:inherit;
}
._text-content_6absk_4nepk_4 small:not(#\9){
  display:inline-block;
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  color:#687078;
  color:var(--color-text-small-2ZkiuH, #687078);
}
._text-content_6absk_4nepk_4 small a:not(#\9){
  font-size:inherit;
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  letter-spacing:0.01em;
}
._text-content_6absk_4nepk_4 code:not(#\9),
._text-content_6absk_4nepk_4 pre:not(#\9),
._text-content_6absk_4nepk_4 samp:not(#\9){
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
}
._text-content_6absk_4nepk_4 code:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}
._text-content_6absk_4nepk_4 a:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
}
._text-content_6absk_4nepk_4 a:not(#\9):hover, ._text-content_6absk_4nepk_4 a:not(#\9):active, ._text-content_6absk_4nepk_4 a:not(#\9):focus{
  text-decoration:underline;
}
._text-content_6absk_4nepk_4 a:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
}
._text-content_6absk_4nepk_4 ul:not(#\9),
._text-content_6absk_4nepk_4 ol:not(#\9){
  padding-left:2rem;
  padding-left:var(--space-l-3cXvov, 2rem);
  margin:0.4rem 0;
  margin:var(--space-xxs-137Hig, 0.4rem) 0;
  list-style-position:outside;
}
._text-content_6absk_4nepk_4 ul + ul:not(#\9),
._text-content_6absk_4nepk_4 ul + ol:not(#\9),
._text-content_6absk_4nepk_4 ul > li + ul:not(#\9),
._text-content_6absk_4nepk_4 ul > li + li:not(#\9),
._text-content_6absk_4nepk_4 ul > li + ol:not(#\9),
._text-content_6absk_4nepk_4 ul > li > ul:not(#\9),
._text-content_6absk_4nepk_4 ul > li > ol:not(#\9),
._text-content_6absk_4nepk_4 ol + ul:not(#\9),
._text-content_6absk_4nepk_4 ol + ol:not(#\9),
._text-content_6absk_4nepk_4 ol > li + ul:not(#\9),
._text-content_6absk_4nepk_4 ol > li + li:not(#\9),
._text-content_6absk_4nepk_4 ol > li + ol:not(#\9),
._text-content_6absk_4nepk_4 ol > li > ul:not(#\9),
._text-content_6absk_4nepk_4 ol > li > ol:not(#\9){
  padding-top:0.4rem;
  padding-top:var(--space-xxs-137Hig, 0.4rem);
}
._text-content_6absk_4nepk_4 ul > li > ul:not(#\9),
._text-content_6absk_4nepk_4 ul > li > ol:not(#\9),
._text-content_6absk_4nepk_4 ol > li > ul:not(#\9),
._text-content_6absk_4nepk_4 ol > li > ol:not(#\9){
  margin:0;
}
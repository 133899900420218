._textarea_qk1j1_t47e5_7:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  resize:auto;
  cursor:text;
  white-space:pre-wrap;
  padding:0.4rem 0.8rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xs-1QR0Ng, 0.8rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  max-width:100%;
  width:100%;
  display:block;
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border-radius:2px;
  border:1px solid #aab7b8;
  border:1px solid var(--color-border-input-default-27WVST, #aab7b8);
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
}
._textarea_qk1j1_t47e5_7._textarea-readonly_qk1j1_t47e5_30:not(#\9){
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border:1px solid #eaeded;
  border:1px solid var(--color-border-input-disabled-1QUbGm, #eaeded);
}
._textarea_qk1j1_t47e5_7:not(#\9):-ms-input-placeholder{
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
  opacity:1;
}
._textarea_qk1j1_t47e5_7:not(#\9)::placeholder{
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
  opacity:1;
}
._textarea_qk1j1_t47e5_7:not(#\9):-ms-input-placeholder{
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._textarea_qk1j1_t47e5_7:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._textarea_qk1j1_t47e5_7:not(#\9):disabled{
  background-color:#eaeded;
  background-color:var(--color-background-input-disabled-38loDV, #eaeded);
  border:1px solid #eaeded;
  border:1px solid var(--color-border-input-disabled-1QUbGm, #eaeded);
  color:#879596;
  color:var(--color-text-input-disabled-3ki3SQ, #879596);
  cursor:auto;
  cursor:default;
}
._textarea_qk1j1_t47e5_7:not(#\9):invalid{
  box-shadow:none;
}
._textarea_qk1j1_t47e5_7._textarea-invalid_qk1j1_t47e5_59:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  border-color:#d13212;
  border-color:var(--color-text-status-error-1jj2sL, #d13212);
  padding-left:calc( 0.8rem - (4px - 1px) );
  padding-left:calc( var(--space-xs-1QR0Ng, 0.8rem) - (4px - 1px) );
  border-left-width:4px;
}
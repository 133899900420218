._root_wih1l_1vath_3:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._tools_wih1l_1vath_11:not(#\9){
  display:flex;
  align-items:flex-start;
  flex-wrap:wrap;
  padding:0.8rem 0 0.4rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) 0 var(--space-scaled-xxs-wz9S8K, 0.4rem);
}
._tools-filtering_wih1l_1vath_17:not(#\9){
  flex:1 1 0%;
  margin-right:2rem;
  margin-right:var(--space-l-3cXvov, 2rem);
}
._tools-align-right_wih1l_1vath_21:not(#\9){
  display:flex;
  margin-left:auto;
}
._tools-pagination_wih1l_1vath_25 + ._tools-preferences_wih1l_1vath_25:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  box-sizing:border-box;
  margin-left:0.8rem;
  margin-left:var(--space-xs-1QR0Ng, 0.8rem);
  padding-left:0.8rem;
  padding-left:var(--space-xs-1QR0Ng, 0.8rem);
}
._tools-small_wih1l_1vath_31 > ._tools-filtering_wih1l_1vath_17:not(#\9){
  margin-right:0;
  margin-bottom:0.8rem;
  margin-bottom:var(--space-scaled-xs-19adjU, 0.8rem);
  flex-basis:100%;
}

._table_wih1l_1vath_37:not(#\9){
  width:100%;
  border-spacing:0;
}
._table-layout-fixed_wih1l_1vath_41:not(#\9){
  table-layout:fixed;
}

._wrapper_wih1l_1vath_45:not(#\9){
  position:relative;
  width:100%;
  overflow-x:auto;
}

._cell_wih1l_1vath_51:not(#\9){
  box-sizing:border-box;
  padding:0.8rem 2rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-scaled-l-17GFGI, 2rem);
}

._header-cell_wih1l_1vath_56:not(#\9){
  position:relative;
  text-align:left;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  background:#fafafa;
  background:var(--color-background-container-header-1OJlgw, #fafafa);
  color:#545b64;
  color:var(--color-text-column-header-3qT4rX, #545b64);
  font-weight:bold;
  padding:0.4rem 0.8rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-scaled-xs-19adjU, 0.8rem);
}
._header-cell-sticky_wih1l_1vath_65:not(#\9){
  border-bottom:0;
}
._header-cell_wih1l_1vath_56:not(#\9):first-child{
  padding-left:0.8rem;
  padding-left:var(--space-xs-1QR0Ng, 0.8rem);
}
._header-cell_wih1l_1vath_56:not(#\9):last-child, ._header-cell_wih1l_1vath_56._header-cell-sortable_wih1l_1vath_71:not(#\9){
  padding-right:0.8rem;
  padding-right:var(--space-xs-1QR0Ng, 0.8rem);
}
._header-cell_wih1l_1vath_56:not(#\9):not(:last-child):before{
  content:"";
  position:absolute;
  right:0;
  bottom:25%;
  height:50%;
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  box-sizing:border-box;
}

._body-cell_wih1l_1vath_84:not(#\9){
  border-top:1px solid transparent;
  word-wrap:break-word;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._body-cell_wih1l_1vath_84:not(#\9):not(._body-cell-wrap_wih1l_1vath_89){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
._body-cell_wih1l_1vath_84:not(#\9):first-child{
  border-left:1px solid transparent;
  padding-left:calc(2rem - 1px);
  padding-left:calc(var(--space-l-3cXvov, 2rem) - 1px);
}
._body-cell_wih1l_1vath_84:not(#\9):last-child{
  border-right:1px solid transparent;
  padding-right:calc(2rem - 1px);
  padding-right:calc(var(--space-l-3cXvov, 2rem) - 1px);
}
._row_wih1l_1vath_102:not(#\9):last-child > ._body-cell_wih1l_1vath_84{
  border-bottom:1px solid transparent;
}
._row_wih1l_1vath_102._row-selected_wih1l_1vath_105 > ._body-cell_wih1l_1vath_84:not(#\9){
  background-color:#f1faff;
  background-color:var(--color-background-item-selected-WkeJRF, #f1faff);
  border-top:1px solid #00a1c9;
  border-top:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  border-bottom:1px solid #00a1c9;
  border-bottom:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
}
._row_wih1l_1vath_102._row-selected_wih1l_1vath_105 > ._body-cell_wih1l_1vath_84:not(#\9):first-child{
  border-left:1px solid #00a1c9;
  border-left:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
}
._row_wih1l_1vath_102._row-selected_wih1l_1vath_105 > ._body-cell_wih1l_1vath_84:not(#\9):last-child{
  border-right:1px solid #00a1c9;
  border-right:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
}
._row_wih1l_1vath_102._row-selected_wih1l_1vath_105 + ._row-selected_wih1l_1vath_105 > ._body-cell_wih1l_1vath_84:not(#\9){
  border-top:1px solid transparent;
}

._cell-merged_wih1l_1vath_120:not(#\9){
  text-align:center;
  padding:0;
}
._cell-merged-content_wih1l_1vath_124:not(#\9){
  box-sizing:border-box;
  padding:1.6rem 2rem 2rem;
  padding:var(--space-scaled-m-p3tAne, 1.6rem) var(--space-l-3cXvov, 2rem) var(--space-scaled-l-17GFGI, 2rem);
  position:-webkit-sticky;
  position:sticky;
  left:0;
}

._empty_wih1l_1vath_131:not(#\9){
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
}

._selection-control_wih1l_1vath_139:not(#\9){
  position:relative;
  box-sizing:border-box;
  width:5.4rem;
  max-width:5.4rem;
  min-width:5.4rem;
}
._selection-control_wih1l_1vath_139._header-cell_wih1l_1vath_56:not(#\9){
  padding:0.8rem 2rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-scaled-l-17GFGI, 2rem);
}

._sorting-icon_wih1l_1vath_150:not(#\9){
  position:absolute;
  top:0.4rem;
  top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
  right:0.4rem;
  right:var(--space-xxs-137Hig, 0.4rem);
  color:#879596;
  color:var(--color-text-icon-caret-BxOb2m, #879596);
}

._header-cell-disabled_wih1l_1vath_157._header-cell-sorted_wih1l_1vath_157 > ._header-cell-content_wih1l_1vath_157 > ._sorting-icon_wih1l_1vath_150:not(#\9){
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}

._header-cell-content_wih1l_1vath_157:not(#\9){
  position:relative;
  padding:0.4rem 1.2rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-s-2tKeb0, 1.2rem);
}
._header-cell-sortable_wih1l_1vath_71 > ._header-cell-content_wih1l_1vath_157:not(#\9){
  padding-right:calc(2.4rem + 0.4rem);
  padding-right:calc(var(--space-xl-3JXLlv, 2.4rem) + var(--space-xxs-137Hig, 0.4rem));
}
._header-cell-content_wih1l_1vath_157:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._header-cell-content_wih1l_1vath_157[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._header-cell-content_wih1l_1vath_157[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._header-cell-content_wih1l_1vath_157[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._header-cell-sortable_wih1l_1vath_71:not(#\9):not(._header-cell-disabled_wih1l_1vath_157) > ._header-cell-content_wih1l_1vath_157{
  cursor:pointer;
}
._header-cell-sortable_wih1l_1vath_71:not(#\9):not(._header-cell-disabled_wih1l_1vath_157) > ._header-cell-content_wih1l_1vath_157:hover, ._header-cell-sortable_wih1l_1vath_71:not(#\9):not(._header-cell-disabled_wih1l_1vath_157)._header-cell-sorted_wih1l_1vath_157 > ._header-cell-content_wih1l_1vath_157{
  color:#16191f;
  color:var(--color-text-interactive-active-18qOom, #16191f);
}
._header-cell-sortable_wih1l_1vath_71:not(#\9):not(._header-cell-disabled_wih1l_1vath_157) > ._header-cell-content_wih1l_1vath_157:hover > ._sorting-icon_wih1l_1vath_150, ._header-cell-sortable_wih1l_1vath_71:not(#\9):not(._header-cell-disabled_wih1l_1vath_157)._header-cell-sorted_wih1l_1vath_157 > ._header-cell-content_wih1l_1vath_157 > ._sorting-icon_wih1l_1vath_150{
  color:#16191f;
  color:var(--color-text-interactive-active-18qOom, #16191f);
}

._header-cell-text_wih1l_1vath_206:not(#\9):not(._header-cell-text-wrap_wih1l_1vath_206){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}

._sticky-scrollbar_wih1l_1vath_212:not(#\9){
  height:15px;
  position:-webkit-sticky;
  position:sticky;
  display:none;
  overflow-x:auto;
  overflow-y:hidden;
  margin-top:-15px;
  bottom:0;
  width:100%;
}
._sticky-scrollbar-content_wih1l_1vath_222:not(#\9){
  height:15px;
}
._sticky-scrollbar-visible_wih1l_1vath_225:not(#\9){
  display:block;
}

._header-secondary_wih1l_1vath_229:not(#\9){
  overflow:auto;
  -ms-overflow-style:none;
  scrollbar-width:none;
  width:100%;
}
._header-secondary_wih1l_1vath_229:not(#\9)::-webkit-scrollbar{
  display:none;
}

._header-controls_wih1l_1vath_242:not(#\9){
  padding:1.2rem 2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-l-3cXvov, 2rem);
}
._header-controls_wih1l_1vath_242 + ._header-secondary_wih1l_1vath_229:not(#\9){
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._arrow_xjuzf_f98th_5:not(#\9){
  width:20px;
  height:10px;
}
._arrow-outer_xjuzf_f98th_9:not(#\9), ._arrow-inner_xjuzf_f98th_9:not(#\9){
  position:absolute;
  overflow:hidden;
  width:20px;
  height:10px;
  top:0;
  left:0;
}
._arrow-outer_xjuzf_f98th_9:not(#\9)::after, ._arrow-inner_xjuzf_f98th_9:not(#\9)::after{
  content:"";
  box-sizing:border-box;
  display:inline-block;
  position:absolute;
  border-radius:2px 0 0 0;
  bottom:0;
  left:0;
  width:14px;
  height:14px;
  transform:rotate(45deg);
  transform-origin:0 100%;
}
._arrow-outer_xjuzf_f98th_9:not(#\9)::after{
  background-color:#d5dbdb;
  background-color:var(--color-border-popover-hSim3z, #d5dbdb);
}
._arrow-inner_xjuzf_f98th_9:not(#\9){
  top:2px;
}
._arrow-inner_xjuzf_f98th_9:not(#\9)::after{
  border-radius:1px 0 0 0;
  background-color:#ffffff;
  background-color:var(--color-background-popover-BPqwPK, #ffffff);
}
._arrow-position-right-top_xjuzf_f98th_40 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after, ._arrow-position-right-bottom_xjuzf_f98th_40 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after{
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-left-top_xjuzf_f98th_43 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after, ._arrow-position-left-bottom_xjuzf_f98th_43 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after{
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-top-center_xjuzf_f98th_46 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after, ._arrow-position-top-responsive_xjuzf_f98th_46 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after{
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-bottom-center_xjuzf_f98th_49 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after, ._arrow-position-bottom-responsive_xjuzf_f98th_49 > ._arrow-outer_xjuzf_f98th_9:not(#\9)::after{
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._body_xjuzf_f98th_55:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  box-sizing:border-box;
  border-radius:2px;
  padding:1.2rem 1.6rem;
  padding:var(--space-s-2tKeb0, 1.2rem) var(--space-m-25I9Cr, 1.6rem);
  background-color:#ffffff;
  background-color:var(--color-background-popover-BPqwPK, #ffffff);
  box-shadow:0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0px 1px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
  border:1px solid #d5dbdb;
  border:1px solid var(--color-border-popover-hSim3z, #d5dbdb);
}

._variant-annotation_xjuzf_f98th_74:not(#\9){
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
  border-color:#0073bb;
  border-color:var(--color-border-status-info-3uW6lA, #0073bb);
}

._body-size-small_xjuzf_f98th_79:not(#\9){
  max-width:210px;
}
._body-size-small_xjuzf_f98th_79._fixed-width_xjuzf_f98th_82:not(#\9){
  width:210px;
}

._body-size-medium_xjuzf_f98th_86:not(#\9){
  max-width:310px;
}
._body-size-medium_xjuzf_f98th_86._fixed-width_xjuzf_f98th_82:not(#\9){
  width:310px;
}

._body-size-large_xjuzf_f98th_93:not(#\9){
  max-width:460px;
}
._body-size-large_xjuzf_f98th_93._fixed-width_xjuzf_f98th_82:not(#\9){
  width:460px;
}

._has-dismiss_xjuzf_f98th_100:not(#\9){
  display:flex;
  align-items:flex-start;
}

._dismiss_xjuzf_f98th_105:not(#\9){
  margin:calc(-1 * (0.4rem + 0.2rem));
  margin:calc(-1 * (var(--space-xxs-137Hig, 0.4rem) + var(--space-xxxs-10EX_Z, 0.2rem)));
  margin-left:0;
  flex:0 0 auto;
  order:1;
}

._header_xjuzf_f98th_116:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
  margin-bottom:0.8rem;
  margin-bottom:var(--space-xs-1QR0Ng, 0.8rem);
  flex:1 1 auto;
}
._header_xjuzf_f98th_116 > h2:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
  display:inline;
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}

._content_xjuzf_f98th_132:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  flex:1 1 auto;
}

._content-overflow-visible_xjuzf_f98th_140:not(#\9){
  overflow:visible;
}

._container_xjuzf_f98th_144:not(#\9){
  display:inline-block;
  position:fixed;
  top:-9999px;
  left:-9999px;
  z-index:2000;
}

._container-arrow_xjuzf_f98th_152:not(#\9){
  position:absolute;
  display:inline-block;
}
._container-arrow-position-right-top_xjuzf_f98th_156:not(#\9), ._container-arrow-position-right-bottom_xjuzf_f98th_156:not(#\9){
  transform:rotate(-90deg);
  transform-origin:0 100%;
}
._container-arrow-position-right-top_xjuzf_f98th_156:not(#\9){
  top:calc(12px + 10px);
  left:0;
}
._container-arrow-position-right-bottom_xjuzf_f98th_156:not(#\9){
  bottom:12px;
  left:0;
}
._container-arrow-position-left-top_xjuzf_f98th_168:not(#\9), ._container-arrow-position-left-bottom_xjuzf_f98th_168:not(#\9){
  transform:rotate(90deg);
  transform-origin:100% 100%;
}
._container-arrow-position-left-top_xjuzf_f98th_168:not(#\9){
  top:calc(12px + 10px);
  right:0;
}
._container-arrow-position-left-bottom_xjuzf_f98th_168:not(#\9){
  bottom:12px;
  right:0;
}
._container-arrow-position-top-center_xjuzf_f98th_180:not(#\9), ._container-arrow-position-top-responsive_xjuzf_f98th_180:not(#\9){
  transform:rotate(180deg);
  transform-origin:50% 50%;
}
._container-arrow-position-top-center_xjuzf_f98th_180:not(#\9){
  bottom:-10px;
  left:calc(50% - 10px);
}
._container-arrow-position-bottom-center_xjuzf_f98th_188:not(#\9){
  top:-10px;
  left:calc(50% - 10px);
}
._container_xjuzf_f98th_144:not(#\9){
  animation:_awsui-motion-fade-in_xjuzf_f98th_1 180ms ease-out;
  animation:_awsui-motion-fade-in_xjuzf_f98th_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in_xjuzf_f98th_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._container_xjuzf_f98th_144:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._container_xjuzf_f98th_144:not(#\9){
  animation:none;
  transition:none;
}

._root_xjuzf_f98th_217:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  color:inherit;
}

._trigger_xjuzf_f98th_228:not(#\9){
  display:inline-block;
  color:inherit;
}

._trigger-type-text_xjuzf_f98th_233:not(#\9){
  border:0;
  margin:0;
  padding:0;
  background-color:transparent;
  cursor:pointer;
  border-bottom:1px dashed currentColor;
}
._trigger-type-text_xjuzf_f98th_233:not(#\9):focus{
  outline:none;
}
._trigger-type-text_xjuzf_f98th_233[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._trigger-type-text_xjuzf_f98th_233[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(1px - 1px);
}
._trigger-type-text_xjuzf_f98th_233[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-1px;
  top:-1px;
  width:calc(100% + 2 * 1px);
  height:calc(100% + 2 * 1px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._trigger-type-text_xjuzf_f98th_233 > span:not(#\9){
  position:relative;
}
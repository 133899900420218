._root_1mabk_1v3m1_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._radio_1mabk_1v3m1_14 + ._radio_1mabk_1v3m1_14:not(#\9){
  margin-top:0.4rem;
  margin-top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._radio--has-description_1mabk_1v3m1_22 + ._radio_1mabk_1v3m1_14:not(#\9){
  margin-top:0.8rem;
  margin-top:var(--space-scaled-xs-19adjU, 0.8rem);
}

._radio-control_1mabk_1v3m1_26:not(#\9){
  margin-top:calc((2.2rem - 1.4rem) / 2);
  margin-top:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - 1.4rem) / 2);
  min-height:1.4rem;
  min-width:1.4rem;
  height:1.4rem;
  width:1.4rem;
}

._input_1mabk_1v3m1_34[data-awsui-focus-visible=true]:not(#\9):focus + div{
  position:relative;
}
._input_1mabk_1v3m1_34[data-awsui-focus-visible=true]:not(#\9):focus + div{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._input_1mabk_1v3m1_34[data-awsui-focus-visible=true]:not(#\9):focus + div::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:50%;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._styled-circle-border_1mabk_1v3m1_53:not(#\9){
  stroke:#aab7b8;
  stroke:var(--color-border-control-default-27WVST, #aab7b8);
  fill:#ffffff;
  fill:var(--color-background-control-default-528DLz, #ffffff);
}
._styled-circle-border_1mabk_1v3m1_53._styled-circle-disabled_1mabk_1v3m1_57:not(#\9){
  fill:#d5dbdb;
  fill:var(--color-background-control-disabled-11nezK, #d5dbdb);
  stroke:#d5dbdb;
  stroke:var(--color-background-control-disabled-11nezK, #d5dbdb);
}

._styled-circle-fill_1mabk_1v3m1_62:not(#\9){
  stroke:#0073bb;
  stroke:var(--color-background-control-checked-3uW6lA, #0073bb);
  fill:#ffffff;
  fill:var(--color-foreground-control-default-3g5IJp, #ffffff);
  opacity:0;
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
._styled-circle-fill_1mabk_1v3m1_62._styled-circle-checked_1mabk_1v3m1_68:not(#\9){
  opacity:1;
}
._styled-circle-fill_1mabk_1v3m1_62._styled-circle-disabled_1mabk_1v3m1_57:not(#\9){
  fill:#ffffff;
  fill:var(--color-foreground-control-disabled-3d6ker, #ffffff);
  stroke:#d5dbdb;
  stroke:var(--color-background-control-disabled-11nezK, #d5dbdb);
}
@media (prefers-reduced-motion: reduce){
  ._styled-circle-fill_1mabk_1v3m1_62:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._styled-circle-fill_1mabk_1v3m1_62:not(#\9){
  animation:none;
  transition:none;
}
._empty_n4qlp_fbnq3_3:not(#\9){
  animation:_awsui-motion-fade-in_n4qlp_fbnq3_1 180ms ease-out;
  animation:_awsui-motion-fade-in_n4qlp_fbnq3_1 var(--motion-duration-transition-show-paced-2crOWV, 180ms) var(--motion-easing-transition-show-paced-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in_n4qlp_fbnq3_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._empty_n4qlp_fbnq3_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._empty_n4qlp_fbnq3_3:not(#\9){
  animation:none;
  transition:none;
}

._root_n4qlp_fbnq3_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._empty_n4qlp_fbnq3_3:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
}

._button-container_n4qlp_fbnq3_62:not(#\9){
  padding-top:calc(0.4rem + 2.2rem);
  padding-top:calc(var(--space-xxs-137Hig, 0.4rem) + var(--size-lineHeight-body-100-3TEa20, 2.2rem));
}

._divider_n4qlp_fbnq3_66:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._additional-info_n4qlp_fbnq3_70:not(#\9){
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  display:block;
  word-wrap:break-word;
  margin-top:0.4rem;
  margin-top:var(--space-xxs-137Hig, 0.4rem);
}
._additional-info_n4qlp_fbnq3_70 > a:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  letter-spacing:0.01em;
}
._root_1xupv_1k1ti_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._wizard_1xupv_1k1ti_13:not(#\9){
  display:flex;
}

._form_1xupv_1k1ti_17:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  width:100%;
}

._navigation_1xupv_1k1ti_33:not(#\9){
  color:#aab7b8;
  color:var(--color-text-disabled-TVd7uP, #aab7b8);
  display:inline-block;
  width:20rem;
  min-width:20rem;
  margin-right:calc(2 * 4rem);
  margin-right:calc(2 * var(--space-xxxl-3dxeuJ, 4rem));
  padding-top:0.4rem;
  padding-top:var(--space-xxs-137Hig, 0.4rem);
}

._hidden_1xupv_1k1ti_42:not(#\9){
  display:none;
}

._navigation-links_1xupv_1k1ti_46:not(#\9){
  list-style:none;
  padding-left:0;
  margin:0;
}
._navigation-links_1xupv_1k1ti_46 > li:not(#\9){
  padding-bottom:1.6rem;
  padding-bottom:var(--space-scaled-m-p3tAne, 1.6rem);
  padding-top:0;
}
._navigation-links_1xupv_1k1ti_46 > li:not(#\9):not(:first-child){
  margin-top:1.6rem;
  margin-top:var(--space-scaled-m-p3tAne, 1.6rem);
}
._navigation-links_1xupv_1k1ti_46 > li:not(#\9):not(:last-child){
  border-bottom:1px solid #d5dbdb;
  border-bottom:1px solid var(--color-border-layout-11nezK, #d5dbdb);
}

._navigation-link-active_1xupv_1k1ti_68:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
}

._navigation-link-disabled_1xupv_1k1ti_73:not(#\9){
  color:#aab7b8;
  color:var(--color-text-control-disabled-KGAbe7, #aab7b8);
}
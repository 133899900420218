._code-editor_1gl9c_7v384_13 .ace_editor:not(#\9){
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-size:14px;
  line-height:20px;
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_error:not(#\9),
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_warning:not(#\9){
  cursor:pointer;
  background-repeat:no-repeat;
  background-size:16px 16px;
  background-position:4px 2px;
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_info:not(#\9){
  background-image:none;
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_error:not(#\9){
  color:#d13212;
  color:var(--color-red-600-2N9Epo, #d13212);
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d13212' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_error:not(#\9){
    color:#ff5d64;
    color:var(--color-red-500-c21Nsx, #ff5d64);
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23ff5d64' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_warning:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d13212' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell.ace_warning:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23ff5d64' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-cell:not(#\9){
  padding-left:24px;
  padding-right:16px;
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget:not(#\9){
  width:14px;
  margin-right:-15px;
  background-color:transparent;
  border:none;
  box-shadow:none;
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_marker-layer > .ace_active-line:not(#\9){
  background:transparent;
  box-sizing:border-box;
  border-top:1px solid #d5dbdb;
  border-bottom:1px solid #d5dbdb;
}
._code-editor_1gl9c_7v384_13 .ace_editor.ace_dark .ace_marker-layer > .ace_active-line:not(#\9){
  border-top:1px solid #687078;
  border-bottom:1px solid #687078;
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter:not(#\9){
  background-color:#f2f3f3;
  background-color:var(--color-background-code-editor-gutter-default-ikVlHL, #f2f3f3);
  color:#16191f;
  color:var(--color-text-code-editor-gutter-default-mcmuEw, #16191f);
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_open:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23545b64' fill='%23545b64' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_open:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_open:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_open:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23545b64' fill='%23545b64' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_fold-widget.ace_closed:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line:not(#\9){
  background-color:#687078;
  background-color:var(--color-background-code-editor-gutter-active-line-default-3mUVYZ, #687078);
  color:#ffffff;
  color:var(--color-text-code-editor-gutter-active-line-DnITSk, #ffffff);
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_open:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line > .ace_fold-widget.ace_closed:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error:not(#\9){
  color:white;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fff' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
  background-color:#d13212;
  background-color:var(--color-background-code-editor-gutter-active-line-error-1jj2sL, #d13212);
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error:not(#\9){
    color:#16191f;
    color:var(--color-grey-900-w0NbTF, #16191f);
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Ccircle cx='8' cy='8' r='7'/%3E %3Cpath d='M10.828 5.172l-5.656 5.656M10.828 10.828L5.172 5.172'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fff' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill-opacity='0' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M8 1l7 14H1L8 1z'/%3E %3Cpath d='M7.99 12H8v.01h-.01zM8 6v4'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9), ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9), .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9):hover, ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_open:not(#\9):hover, .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_open:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2'%3E %3Cpath d='M4 5h8l-4 6-4-6z' stroke-linejoin='round'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9), ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9){
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23d5dbdb' fill='%23d5dbdb' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9), .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9){
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23414750' fill='%23414750' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9):hover, ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9):hover{
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23fafafa' fill='%23fafafa' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
}
@supports (--css-variable-support-check: #000){
  .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_error > .ace_fold-widget.ace_closed:not(#\9):hover, .awsui-polaris-dark-mode ._code-editor_1gl9c_7v384_13 .ace_editor .ace_gutter-active-line.ace_warning > .ace_fold-widget.ace_closed:not(#\9):hover{
    background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%2316191f' fill='%2316191f' stroke-width='2' stroke-linejoin='round'%3E %3Cpath d='M5 4v8l6-4-6-4z'/%3E %3C/svg%3E");
  }
}
._pane_1gl9c_7v384_244:not(#\9){
  display:flex;
  position:relative;
  flex-direction:row;
  flex:1;
  border-top:1px solid #d5dbdb;
  border-top:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
  background:#f2f3f3;
  background:var(--color-background-code-editor-status-bar-ikVlHL, #f2f3f3);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
}
._pane__close-container_1gl9c_7v384_253:not(#\9){
  position:absolute;
  top:0;
  right:calc(1.2rem / 2);
  right:calc(var(--space-s-2tKeb0, 1.2rem) / 2);
}
._pane__close-container_1gl9c_7v384_253 > button:not(#\9){
  margin:0;
}
._pane__list_1gl9c_7v384_261:not(#\9){
  flex:1;
  overflow:auto;
  max-height:100%;
  box-sizing:border-box;
  margin-right:calc(2.2rem + 2 * 0.8rem);
  margin-right:calc(var(--size-lineHeight-body-100-3TEa20, 2.2rem) + 2 * var(--space-xs-1QR0Ng, 0.8rem));
}
._pane__table_1gl9c_7v384_268:not(#\9){
  width:100%;
  border-spacing:0;
  margin:1.2rem 0;
  margin:var(--space-s-2tKeb0, 1.2rem) 0;
}
._pane__item_1gl9c_7v384_273:not(#\9){
  border:1px solid transparent;
  border:1px solid var(--color-transparent-25e1K_, transparent);
}
._pane__item_1gl9c_7v384_273:not(#\9):hover, ._pane__item_1gl9c_7v384_273:not(#\9):focus, ._pane__item--highlighted_1gl9c_7v384_276:not(#\9){
  cursor:pointer;
  background-color:#eaeded;
  background-color:var(--color-background-code-editor-pane-item-hover-38loDV, #eaeded);
  outline:1px solid #687078;
  outline:1px solid var(--color-border-code-editor-pane-item-hover-3mUVYZ, #687078);
  outline-offset:-1px;
}
._pane__location_1gl9c_7v384_282:not(#\9), ._pane__description_1gl9c_7v384_282:not(#\9){
  padding:0.4rem 1.2rem;
  padding:var(--space-xxs-137Hig, 0.4rem) var(--space-s-2tKeb0, 1.2rem);
}
._pane__location_1gl9c_7v384_282:not(#\9){
  vertical-align:baseline;
  white-space:nowrap;
  padding-left:calc(2rem + 1.2rem);
  padding-left:calc(var(--space-l-3cXvov, 2rem) + var(--space-s-2tKeb0, 1.2rem));
}
._pane__description_1gl9c_7v384_282:not(#\9){
  padding-right:0;
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._focus-lock_1gl9c_7v384_297:not(#\9){
  height:100%;
}

._code-editor_1gl9c_7v384_13:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:inline-block;
  border:1px solid #d5dbdb;
  border:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
  width:100%;
}

._resizable-box_1gl9c_7v384_314:not(#\9){
  position:relative;
  width:100%;
}

._resizable-box-handle_1gl9c_7v384_319:not(#\9){
  position:absolute;
  right:0;
  bottom:0;
  z-index:10;
  width:2rem;
  width:var(--space-l-3cXvov, 2rem);
  height:2rem;
  height:var(--space-l-3cXvov, 2rem);
  background-repeat:no-repeat;
  background-origin:content-box;
  box-sizing:border-box;
  background-image:url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' stroke='%23687078' fill-opacity='0' stroke-width='1'%3E %3Cpath d='M 8 13 L 13 8 M 13 11 L 11 13 M 5 13 L 13 5'/%3E %3C/svg%3E");
  background-position:bottom right;
  background-size:2rem;
  background-size:var(--space-l-3cXvov, 2rem);
  cursor:ns-resize;
}

._editor_1gl9c_7v384_335:not(#\9){
  position:absolute;
  top:0;
  right:0;
  bottom:0;
  left:0;
}
._editor_1gl9c_7v384_335:not(#\9):focus{
  position:relative;
  position:absolute;
  overflow:visible;
}
._editor_1gl9c_7v384_335:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._editor_1gl9c_7v384_335:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._status-bar_1gl9c_7v384_363:not(#\9){
  display:flex;
  vertical-align:middle;
  border-top:1px solid #d5dbdb;
  border-top:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
  background-color:#f2f3f3;
  background-color:var(--color-background-code-editor-status-bar-ikVlHL, #f2f3f3);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}
._status-bar__left_1gl9c_7v384_372:not(#\9){
  flex:1;
  display:flex;
  flex-wrap:wrap;
  padding-left:2rem;
  padding-left:var(--space-l-3cXvov, 2rem);
  border-right:1px solid #d5dbdb;
  border-right:1px solid var(--color-border-code-editor-default-1MzhzU, #d5dbdb);
}
._status-bar__right_1gl9c_7v384_379:not(#\9){
  display:flex;
  align-items:center;
}
._status-bar__language-mode_1gl9c_7v384_383:not(#\9), ._status-bar__cursor-position_1gl9c_7v384_383:not(#\9){
  display:inline-block;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  padding:0.8rem 1.2rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-s-2tKeb0, 1.2rem);
}
._status-bar__cog-button_1gl9c_7v384_388:not(#\9){
  padding:calc(0.4rem - 1px) calc(0.8rem - 2px);
  padding:calc(var(--space-scaled-xxs-wz9S8K, 0.4rem) - 1px) calc(var(--space-xs-1QR0Ng, 0.8rem) - 2px);
}
._status-bar__cog-button_1gl9c_7v384_388 > button:not(#\9){
  margin:0;
}

._tab-button_1gl9c_7v384_395:not(#\9){
  position:relative;
  display:inline-block;
  padding:0.8rem 1.2rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-s-2tKeb0, 1.2rem);
  line-height:inherit;
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  background:none;
  border:none;
  font-weight:bold;
  outline:none;
  cursor:pointer;
  z-index:1;
}
._tab-button--active_1gl9c_7v384_408:not(#\9)::after, ._tab-button_1gl9c_7v384_395:not(#\9):hover::after{
  content:"";
  position:absolute;
  left:0;
  right:0;
  bottom:0;
  height:2px;
  background:#d13212;
  background:var(--color-text-status-error-1jj2sL, #d13212);
}
._tab-button_1gl9c_7v384_395:not(#\9):hover{
  color:#ffffff;
  color:var(--color-text-code-editor-tab-button-error-DnITSk, #ffffff);
  background:#d13212;
  background:var(--color-text-status-error-1jj2sL, #d13212);
}
._tab-button--disabled_1gl9c_7v384_421:not(#\9){
  font-weight:normal;
  color:#879596;
  color:var(--color-text-code-editor-status-bar-disabled-3ki3SQ, #879596);
  cursor:default;
}
._tab-button--disabled_1gl9c_7v384_421:not(#\9):hover{
  color:#879596;
  color:var(--color-text-code-editor-status-bar-disabled-3ki3SQ, #879596);
  background:transparent;
}
._tab-button--disabled_1gl9c_7v384_421:not(#\9)::after{
  display:none;
}
._tab-button_1gl9c_7v384_395[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._tab-button_1gl9c_7v384_395[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._tab-button_1gl9c_7v384_395[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._loading-screen_1gl9c_7v384_455:not(#\9),
._error-screen_1gl9c_7v384_456:not(#\9){
  display:flex;
  align-items:center;
  justify-content:center;
  height:480px;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  background:#f2f3f3;
  background:var(--color-background-code-editor-status-bar-ikVlHL, #f2f3f3);
}

._error-screen_1gl9c_7v384_456:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
:root{
  --color-grey-100-2AKqb7:#fafafa;
  --color-grey-150-3kuzTS:#f2f3f3;
  --color-grey-200-XNKkLQ:#eaeded;
  --color-grey-300-q6XEFF:#d5dbdb;
  --color-grey-400-30uhvb:#aab7b8;
  --color-grey-450-3RXUpq:#95a5a6;
  --color-grey-500-RUsejK:#879596;
  --color-grey-550-1_pyCK:#687078;
  --color-grey-600-1cltHv:#545b64;
  --color-grey-650-3EXt7H:#414750;
  --color-grey-700-2ayya1:#2a2e33;
  --color-grey-750-vMCyzl:#21252c;
  --color-grey-800-1UF29O:#1a2029;
  --color-grey-900-w0NbTF:#16191f;
  --color-grey-950-1aaxHz:#222222;
  --color-grey-transparent-heavy-3Le0m-:rgba(0, 28, 36, 0.5);
  --color-grey-transparent-1Qa-ea:rgba(0, 28, 36, 0.3);
  --color-grey-transparent-light-5LOYx8:rgba(0, 28, 36, 0.15);
  --color-grey-opaque-25-3gH_CO:rgba(255, 255, 255, 0.25);
  --color-grey-opaque-40-TOWdmw:rgba(0, 0, 0, 0.4);
  --color-grey-opaque-50-Y1DpJP:rgba(0, 0, 0, 0.5);
  --color-grey-opaque-70-1KHXJU:rgba(255, 255, 255, 0.7);
  --color-grey-opaque-80-1Db7V9:rgba(22, 25, 31, 0.8);
  --color-grey-opaque-90-2vUzuC:rgba(242, 243, 243, 0.9);
  --color-orange-100-2th-I8:#fef6f0;
  --color-orange-500-2Or5uE:#ec7211;
  --color-orange-600-FMAoZX:#eb5f07;
  --color-orange-700-2D5pfk:#dd6b10;
  --color-blue-100-1UlEIX:#f1faff;
  --color-blue-300-3K_wpm:#99cbe4;
  --color-blue-400-2_UE0j:#44b9d6;
  --color-blue-500-2Y609w:#00a1c9;
  --color-blue-600-3mTfxB:#0073bb;
  --color-blue-700-1Gxewg:#0a4a74;
  --color-blue-900-1NqxOj:#12293b;
  --color-blue-opaque-O9fuAK:rgba(51, 136, 221, 0.5);
  --color-green-100-1FQHEX:#f2f8f0;
  --color-green-500-jl-Bwc:#6aaf35;
  --color-green-600-3iv9Fc:#1d8102;
  --color-green-700-28ULdF:#1a520f;
  --color-green-900-jZfd0l:#172211;
  --color-red-100-1fXiDO:#fdf3f1;
  --color-red-500-c21Nsx:#ff5d64;
  --color-red-600-2N9Epo:#d13212;
  --color-red-700-BBZR7Y:#7c2718;
  --color-red-900-3SXvmY:#270a11;
  --color-black-2XzW-x:#000000;
  --color-white-3kAlam:#ffffff;
  --color-aws-squid-ink-3ksxto:#232f3e;
  --color-amazon-orange-1i3bQi:#ff9900;
  --color-transparent-25e1K_:transparent;
  --color-charts-red-300-iHH87A:#ea7158;
  --color-charts-red-400-2JfTL4:#dc5032;
  --color-charts-red-500-2KGzVl:#d13313;
  --color-charts-red-600-sMhEtb:#ba2e0f;
  --color-charts-red-700-1vSLrI:#a82a0c;
  --color-charts-red-800-2cvTzr:#972709;
  --color-charts-red-900-lyJiKz:#892407;
  --color-charts-red-1000-10wpD-:#7d2105;
  --color-charts-red-1100-2b6xrX:#721e03;
  --color-charts-red-1200-3h9HjA:#671c00;
  --color-charts-orange-300-3Fazlv:#e07941;
  --color-charts-orange-400-3rh-oy:#cc5f21;
  --color-charts-orange-500-29F5cF:#bc4d01;
  --color-charts-orange-600-3j_60e:#a84401;
  --color-charts-orange-700-d-ZF6X:#983c02;
  --color-charts-orange-800-3dMR9p:#8a3603;
  --color-charts-orange-900-3eCLL9:#7e3103;
  --color-charts-orange-1000-1ft3LA:#732c02;
  --color-charts-orange-1100-1rFHIx:#692801;
  --color-charts-orange-1200-haIcdJ:#602400;
  --color-charts-yellow-300-1QSL1Q:#b49116;
  --color-charts-yellow-400-1pmUpr:#9c7b0b;
  --color-charts-yellow-500-Fbq27g:#8a6b05;
  --color-charts-yellow-600-x0XTst:#7b5f04;
  --color-charts-yellow-700-uYNHas:#6f5504;
  --color-charts-yellow-800-2jsiC7:#654d03;
  --color-charts-yellow-900-1leOJ5:#5d4503;
  --color-charts-yellow-1000-G-JOv_:#553f03;
  --color-charts-yellow-1100-38YDD5:#4d3901;
  --color-charts-yellow-1200-ZEWsG4:#483300;
  --color-charts-green-300-Gk-x5D:#67a353;
  --color-charts-green-400-3Gp5UC:#41902c;
  --color-charts-green-500-1UIHe-:#1f8104;
  --color-charts-green-600-11aNBs:#1a7302;
  --color-charts-green-700-ZgWDvM:#176702;
  --color-charts-green-800-2ZMVcX:#145d02;
  --color-charts-green-900-h9AbBY:#125502;
  --color-charts-green-1000-2Obz4Q:#104d01;
  --color-charts-green-1100-X5c9eG:#0f4601;
  --color-charts-green-1200-3NUZfG:#0d4000;
  --color-charts-teal-300-1cN8Cn:#2ea597;
  --color-charts-teal-400-2vraJk:#1c8e81;
  --color-charts-teal-500-9FrMoX:#0d7d70;
  --color-charts-teal-600-2UUnei:#096f64;
  --color-charts-teal-700-NU-4qs:#06645a;
  --color-charts-teal-800-1mmgce:#045b52;
  --color-charts-teal-900-2R6Ae7:#03524a;
  --color-charts-teal-1000-3UqmIz:#014b44;
  --color-charts-teal-1100-1DI6zP:#01443e;
  --color-charts-teal-1200-1e8w30:#003e38;
  --color-charts-blue-1-300-NVZfma:#529ccb;
  --color-charts-blue-1-400-3EeZFw:#3184c2;
  --color-charts-blue-1-500-1l-PV1:#0273bb;
  --color-charts-blue-1-600-G6Tezt:#0166ab;
  --color-charts-blue-1-700-2TnoOg:#015b9d;
  --color-charts-blue-1-800-1eLbUq:#015292;
  --color-charts-blue-1-900-3_JXMA:#014a87;
  --color-charts-blue-1-1000-pMtBAm:#01437d;
  --color-charts-blue-1-1100-1tENEi:#003c75;
  --color-charts-blue-1-1200-1EYZ5e:#00366d;
  --color-charts-blue-2-300-17FRhH:#7492e7;
  --color-charts-blue-2-400-3axVbM:#5978e3;
  --color-charts-blue-2-500-2QbTAr:#4066df;
  --color-charts-blue-2-600-w14n0w:#3759ce;
  --color-charts-blue-2-700-37mL3D:#314fbf;
  --color-charts-blue-2-800-bUsied:#2c46b1;
  --color-charts-blue-2-900-33Y3FX:#273ea5;
  --color-charts-blue-2-1000-rzcdFI:#23379b;
  --color-charts-blue-2-1100-3q7MzB:#1f3191;
  --color-charts-blue-2-1200-2SqsCy:#1b2b88;
  --color-charts-purple-300-La1b0W:#a783e1;
  --color-charts-purple-400-2L2Pop:#9469d6;
  --color-charts-purple-500-2NvZZb:#8456ce;
  --color-charts-purple-600-Zh4UZk:#7749bf;
  --color-charts-purple-700-2hpuWi:#6b40b2;
  --color-charts-purple-800-mt0PPD:#6237a7;
  --color-charts-purple-900-1iuo0e:#59309d;
  --color-charts-purple-1000-1LOfvT:#512994;
  --color-charts-purple-1100-dcmh3E:#4a238b;
  --color-charts-purple-1200-3Bndoz:#431d84;
  --color-charts-pink-300-2XoT6f:#da7596;
  --color-charts-pink-400-1YTbxv:#ce567c;
  --color-charts-pink-500-1ueMSc:#c33d69;
  --color-charts-pink-600-rMNZvg:#b1325c;
  --color-charts-pink-700-2zpGOH:#a32952;
  --color-charts-pink-800--or4cb:#962249;
  --color-charts-pink-900-mI7qx_:#8b1b42;
  --color-charts-pink-1000-3Xhw0C:#81143b;
  --color-charts-pink-1100-8q1611:#780d35;
  --color-charts-pink-1200-2Q8hyB:#6f062f;
  --space-none-yzsLHp:0;
  --space-xxxs-10Z_bY:.2rem;
  --space-xxs-Pyh2mI:.4rem;
  --space-xs-3Eb7Zt:.8rem;
  --space-s-24EEro:1.2rem;
  --space-m-2nJwAn:1.6rem;
  --space-l-3JwfI_:2.0rem;
  --space-xl-1_REgj:2.4rem;
  --space-xxl-3huAay:3.2rem;
  --space-xxxl-IhpmRq:4.0rem;
  --size-base-oOehH1:1rem;
  --size-font-body-50-24EEro:1.2rem;
  --size-font-body-100-raVHfl:1.4rem;
  --size-font-heading-100-2nJwAn:1.6rem;
  --size-font-heading-200-1ZSlHP:1.8rem;
  --size-font-heading-500-14QHat:2.8rem;
  --size-font-display-200-AStMo2:4.4rem;
  --size-lineHeight-body-50-2nJwAn:1.6rem;
  --size-lineHeight-body-100-3TEa20:2.2rem;
  --size-lineHeight-heading-100-3JwfI_:2.0rem;
  --size-lineHeight-heading-200-3TEa20:2.2rem;
  --size-lineHeight-heading-500-3DvmnZ:3.6rem;
  --size-lineHeight-display-200-2-t5qR:5.6rem;
  --font-fontFamily-base-1sydl0:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  --font-fontFamily-monospace-1bAn4w:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  --font-fontWeight-bold-2stUXL:700;
  --font-fontWeight-normal-3o4GPz:400;
  --font-fontWeight-lighter-1ehTUD:300;
  --color-text-body-default-mcmuEw:var(--color-grey-900-w0NbTF);
  --color-text-body-secondary-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-heading-default-2S6vEC:var(--color-grey-900-w0NbTF);
  --color-text-heading-secondary-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-home-header-default-pzFO4O:var(--color-white-3kAlam);
  --color-text-home-header-secondary-1OFKGI:var(--color-grey-300-q6XEFF);
  --color-text-label-3qT4rX:var(--color-grey-600-1cltHv);
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-empty-6_f-gS:var(--color-grey-550-1_pyCK);
  --color-text-link-default-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-text-interactive-default-1oBHNI:var(--color-grey-600-1cltHv);
  --color-text-interactive-disabled-TVd7uP:var(--color-grey-400-30uhvb);
  --color-text-interactive-active-18qOom:var(--color-grey-900-w0NbTF);
  --color-text-interactive-hover-18qOom:var(--color-grey-900-w0NbTF);
  --color-text-small-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-inverted-DnITSk:var(--color-white-3kAlam);
  --color-text-disabled-TVd7uP:var(--color-grey-400-30uhvb);
  --color-text-form-default-mcmuEw:var(--color-grey-900-w0NbTF);
  --color-text-form-secondary-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-input-disabled-3ki3SQ:var(--color-grey-500-RUsejK);
  --color-text-input-placeholder-TVd7uP:var(--color-grey-400-30uhvb);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-status-error-1jj2sL:var(--color-red-600-2N9Epo);
  --color-text-status-warning-1jj2sL:var(--color-red-600-2N9Epo);
  --color-text-status-success-CuCgtA:var(--color-green-600-3iv9Fc);
  --color-text-status-info-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-text-status-inactive-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-status-pending-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-text-breadcrumb-6_f-gS:var(--color-grey-550-1_pyCK);
  --color-text-icon-caret-BxOb2m:var(--color-grey-500-RUsejK);
  --color-text-icon-subtle-22ofsX:var(--color-grey-550-1_pyCK);
  --color-text-column-header-3qT4rX:var(--color-grey-600-1cltHv);
  --color-text-tooltip-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-tooltip-link-1yIcmc:var(--color-blue-500-2Y609w);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-highlighted-2S6vEC:var(--color-grey-900-w0NbTF);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-filter-match-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-notification-icon-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-notification-icon-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-code-editor-gutter-default-mcmuEw:var(--color-grey-900-w0NbTF);
  --color-text-code-editor-gutter-active-line-DnITSk:var(--color-white-3kAlam);
  --color-text-code-editor-status-bar-disabled-3ki3SQ:var(--color-grey-500-RUsejK);
  --color-text-code-editor-tab-button-error-DnITSk:var(--color-white-3kAlam);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-foreground-control-disabled-3d6ker:var(--color-white-3kAlam);
  --color-background-home-header-ScmmEP:var(--color-aws-squid-ink-3ksxto);
  --color-background-layout-main-3Bydyi:var(--color-grey-150-3kuzTS);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-layout-panel-hover-38loDV:var(--color-grey-200-XNKkLQ);
  --color-background-item-selected-WkeJRF:var(--color-blue-100-1UlEIX);
  --color-background-modal-overlay-3ptHv2:var(--color-grey-opaque-90-2vUzuC);
  --color-background-status-error-3eo6Wg:var(--color-red-100-1fXiDO);
  --color-background-status-warning-3w9fMz:var(--color-white-3kAlam);
  --color-background-status-success-_mCzOj:var(--color-green-100-1FQHEX);
  --color-background-status-info-WkeJRF:var(--color-blue-100-1UlEIX);
  --color-background-progress-bar-layout-default-38loDV:var(--color-grey-200-XNKkLQ);
  --color-background-progress-bar-layout-in-flash-2azuvH:var(--color-grey-opaque-25-3gH_CO);
  --color-background-progress-bar-content-default-3uW6lA:var(--color-blue-600-3mTfxB);
  --color-background-progress-bar-content-in-flash-1gkz93:var(--color-grey-opaque-70-1KHXJU);
  --color-background-calendar-today-3Bydyi:var(--color-grey-150-3kuzTS);
  --color-background-container-header-1OJlgw:var(--color-grey-100-2AKqb7);
  --color-background-container-content-3w9fMz:var(--color-white-3kAlam);
  --color-background-control-default-528DLz:var(--color-white-3kAlam);
  --color-background-control-checked-3uW6lA:var(--color-blue-600-3mTfxB);
  --color-background-control-disabled-11nezK:var(--color-grey-300-q6XEFF);
  --color-background-input-default-528DLz:var(--color-white-3kAlam);
  --color-background-input-disabled-38loDV:var(--color-grey-200-XNKkLQ);
  --color-background-tiles-disabled-1gYBaj:var(--color-grey-200-XNKkLQ);
  --color-background-tooltip-sSa4Z-:var(--color-grey-900-w0NbTF);
  --color-background-popover-BPqwPK:var(--color-white-3kAlam);
  --color-background-toggle-default-3zU3ek:var(--color-grey-600-1cltHv);
  --color-background-toggle-checked-disabled-uMVvXl:var(--color-blue-300-3K_wpm);
  --color-background-button-normal-default-3w9fMz:var(--color-white-3kAlam);
  --color-background-button-normal-disabled-3w9fMz:var(--color-white-3kAlam);
  --color-background-button-normal-hover-1OJlgw:var(--color-grey-100-2AKqb7);
  --color-background-button-normal-active-2WWahd:var(--color-grey-200-XNKkLQ);
  --color-background-button-primary-default-2YgUpW:var(--color-text-accent-NYv9af);
  --color-background-button-primary-disabled-3w9fMz:var(--color-white-3kAlam);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-button-primary-active-1lqtcO:var(--color-orange-700-2D5pfk);
  --color-background-button-link-hover-1OJlgw:var(--color-grey-100-2AKqb7);
  --color-background-button-link-active-2WWahd:var(--color-grey-200-XNKkLQ);
  --color-background-dropdown-item-default-3w9fMz:var(--color-white-3kAlam);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-dropdown-item-hover-35tDd2:var(--color-grey-150-3kuzTS);
  --color-background-dropdown-item-filter-match-WkeJRF:var(--color-blue-100-1UlEIX);
  --color-background-notification-grey-3n2Isj:var(--color-grey-600-1cltHv);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-background-code-editor-gutter-default-ikVlHL:var(--color-grey-150-3kuzTS);
  --color-background-code-editor-gutter-active-line-default-3mUVYZ:var(--color-grey-550-1_pyCK);
  --color-background-code-editor-gutter-active-line-error-1jj2sL:var(--color-red-600-2N9Epo);
  --color-background-code-editor-status-bar-ikVlHL:var(--color-grey-150-3kuzTS);
  --color-background-code-editor-pane-item-hover-38loDV:var(--color-grey-200-XNKkLQ);
  --color-border-item-focused-2uP71z:var(--color-blue-500-2Y609w);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-status-warning-27WVST:var(--color-grey-400-30uhvb);
  --color-border-status-info-3uW6lA:var(--color-blue-600-3mTfxB);
  --color-border-container-top-1gYBaj:var(--color-grey-200-XNKkLQ);
  --color-border-control-default-27WVST:var(--color-grey-400-30uhvb);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-control-hover-3OSsIc:var(--color-grey-500-RUsejK);
  --color-border-input-default-27WVST:var(--color-grey-400-30uhvb);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-segment-default-3mUVYZ:var(--color-grey-550-1_pyCK);
  --color-border-segment-hover-1dD-mx:var(--color-grey-900-w0NbTF);
  --color-border-tiles-disabled-1D4yG6:var(--color-transparent-25e1K_);
  --color-border-tabs-2fNNxL:var(--color-grey-400-30uhvb);
  --color-border-tooltip-sSa4Z-:var(--color-grey-900-w0NbTF);
  --color-border-popover-hSim3z:var(--color-grey-300-q6XEFF);
  --color-border-button-normal-default-3zU3ek:var(--color-grey-600-1cltHv);
  --color-border-button-normal-hover-ots1Pn:var(--color-grey-900-w0NbTF);
  --color-border-button-normal-disabled-11nezK:var(--color-grey-300-q6XEFF);
  --color-border-button-primary-hover-2NzUKZ:var(--color-orange-700-2D5pfk);
  --color-border-button-primary-disabled-11nezK:var(--color-grey-300-q6XEFF);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-default-38loDV:var(--color-grey-200-XNKkLQ);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-layout-11nezK:var(--color-grey-300-q6XEFF);
  --color-border-code-editor-default-1MzhzU:var(--color-grey-300-q6XEFF);
  --color-border-code-editor-pane-item-hover-3mUVYZ:var(--color-grey-550-1_pyCK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-critical-3OhIPH:var(--color-charts-red-1000-10wpD-);
  --color-charts-status-high-2Kj1VI:var(--color-charts-red-600-sMhEtb);
  --color-charts-status-medium-1G_3c2:var(--color-charts-orange-400-3rh-oy);
  --color-charts-status-low-336vTu:var(--color-charts-yellow-300-1QSL1Q);
  --color-charts-status-positive-2PAhQm:var(--color-charts-green-300-Gk-x5D);
  --color-charts-status-info-3f36yo:var(--color-charts-blue-1-400-3EeZFw);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-threshold-negative-1jj2sL:var(--color-red-600-2N9Epo);
  --color-charts-threshold-positive-CuCgtA:var(--color-green-600-3iv9Fc);
  --color-charts-threshold-info-Bc0LvG:var(--color-blue-600-3mTfxB);
  --color-charts-threshold-neutral-2ZkiuH:var(--color-grey-550-1_pyCK);
  --color-charts-line-grid-11nezK:var(--color-grey-300-q6XEFF);
  --color-charts-line-tick-11nezK:var(--color-grey-300-q6XEFF);
  --color-charts-line-axis-11nezK:var(--color-grey-300-q6XEFF);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --size-icon-normal-2nJwAn:1.6rem;
  --size-icon-big-3huAay:3.2rem;
  --size-icon-large-OupcJw:4.8rem;
  --size-control-raVHfl:1.4rem;
  --motion-duration-extra-fast-1YDA0Q:45ms;
  --motion-duration-fast-oPeLPK:90ms;
  --motion-duration-moderate-3V3IbE:135ms;
  --motion-duration-slow-39Jq2G:180ms;
  --motion-duration-extra-slow-39YF13:270ms;
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-ease-out-quart-cJfwJd:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-show-quick-25z_bd:ease-out;
  --motion-easing-show-paced-25z_bd:ease-out;
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-transition-show-quick-2nchNs:linear;
  --motion-easing-transition-show-paced-25z_bd:ease-out;
  --motion-easing-transition-quick-2nchNs:linear;
  --space-none-2rGI2r:0;
  --space-xxxs-10EX_Z:.2rem;
  --space-xxs-137Hig:.4rem;
  --space-xs-1QR0Ng:.8rem;
  --space-s-2tKeb0:1.2rem;
  --space-m-25I9Cr:1.6rem;
  --space-l-3cXvov:2.0rem;
  --space-xl-3JXLlv:2.4rem;
  --space-xxl-3_q1V_:3.2rem;
  --space-xxxl-3dxeuJ:4.0rem;
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-xxxs-1yX_gQ:var(--space-xxxs-10EX_Z);
  --space-scaled-xxs-wz9S8K:var(--space-xxs-137Hig);
  --space-scaled-xs-19adjU:var(--space-xs-1QR0Ng);
  --space-scaled-s-1sAHT4:var(--space-s-2tKeb0);
  --space-scaled-m-p3tAne:var(--space-m-25I9Cr);
  --space-scaled-l-17GFGI:var(--space-l-3cXvov);
  --space-scaled-xl-axUbkA:var(--space-xl-3JXLlv);
  --space-scaled-xxl-238u6Z:var(--space-xxl-3_q1V_);
  --space-scaled-xxxl-3VX7k3:var(--space-xxxl-3dxeuJ);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-xxxs-1yX_gQ:var(--space-xxxs-10EX_Z);
  --space-scaled-2x-xxs-wz9S8K:var(--space-xxs-137Hig);
  --space-scaled-2x-xs-19adjU:var(--space-xs-1QR0Ng);
  --space-scaled-2x-s-1sAHT4:var(--space-s-2tKeb0);
  --space-scaled-2x-m-p3tAne:var(--space-m-25I9Cr);
  --space-scaled-2x-l-17GFGI:var(--space-l-3cXvov);
  --space-scaled-2x-xl-axUbkA:var(--space-xl-3JXLlv);
  --space-scaled-2x-xxl-238u6Z:var(--space-xxl-3_q1V_);
  --space-scaled-2x-xxxl-3VX7k3:var(--space-xxxl-3dxeuJ);
  --space-grid-gutter-17GFGI:var(--space-l-3cXvov);
  --size-vertical-input-3_q1V_:3.2rem;
  --size-vertical-panel-icon-offset-2Iktcb:1.5rem;
}

.awsui-polaris-dark-mode:not(#\9){
  --color-grey-100-2AKqb7:#fafafa;
  --color-grey-150-3kuzTS:#f2f3f3;
  --color-grey-200-XNKkLQ:#eaeded;
  --color-grey-300-q6XEFF:#d5dbdb;
  --color-grey-400-30uhvb:#aab7b8;
  --color-grey-450-3RXUpq:#95a5a6;
  --color-grey-500-RUsejK:#879596;
  --color-grey-550-1_pyCK:#687078;
  --color-grey-600-1cltHv:#545b64;
  --color-grey-650-3EXt7H:#414750;
  --color-grey-700-2ayya1:#2a2e33;
  --color-grey-750-vMCyzl:#21252c;
  --color-grey-800-1UF29O:#1a2029;
  --color-grey-900-w0NbTF:#16191f;
  --color-grey-950-1aaxHz:#222222;
  --color-grey-transparent-heavy-3Le0m-:rgba(0, 0, 0, 0.5);
  --color-grey-transparent-1Qa-ea:rgba(0, 0, 0, 0.3);
  --color-grey-transparent-light-5LOYx8:rgba(0, 0, 0, 0.3);
  --color-grey-opaque-25-3gH_CO:rgba(255, 255, 255, 0.25);
  --color-grey-opaque-40-TOWdmw:rgba(0, 0, 0, 0.4);
  --color-grey-opaque-50-Y1DpJP:rgba(0, 0, 0, 0.5);
  --color-grey-opaque-70-1KHXJU:rgba(255, 255, 255, 0.7);
  --color-grey-opaque-80-1Db7V9:rgba(22, 25, 31, 0.8);
  --color-grey-opaque-90-2vUzuC:rgba(242, 243, 243, 0.9);
  --color-orange-100-2th-I8:#fef6f0;
  --color-orange-500-2Or5uE:#ec7211;
  --color-orange-600-FMAoZX:#eb5f07;
  --color-orange-700-2D5pfk:#dd6b10;
  --color-blue-100-1UlEIX:#f1faff;
  --color-blue-300-3K_wpm:#99cbe4;
  --color-blue-400-2_UE0j:#44b9d6;
  --color-blue-500-2Y609w:#00a1c9;
  --color-blue-600-3mTfxB:#0073bb;
  --color-blue-700-1Gxewg:#0a4a74;
  --color-blue-900-1NqxOj:#12293b;
  --color-blue-opaque-O9fuAK:rgba(51, 136, 221, 0.5);
  --color-green-100-1FQHEX:#f2f8f0;
  --color-green-500-jl-Bwc:#6aaf35;
  --color-green-600-3iv9Fc:#1d8102;
  --color-green-700-28ULdF:#1a520f;
  --color-green-900-jZfd0l:#172211;
  --color-red-100-1fXiDO:#fdf3f1;
  --color-red-500-c21Nsx:#ff5d64;
  --color-red-600-2N9Epo:#d13212;
  --color-red-700-BBZR7Y:#7c2718;
  --color-red-900-3SXvmY:#270a11;
  --color-black-2XzW-x:#000000;
  --color-white-3kAlam:#ffffff;
  --color-aws-squid-ink-3ksxto:#232f3e;
  --color-amazon-orange-1i3bQi:#ff9900;
  --color-transparent-25e1K_:transparent;
  --color-charts-red-300-iHH87A:#d63f38;
  --color-charts-red-400-2JfTL4:#ed5958;
  --color-charts-red-500-2KGzVl:#fe6e73;
  --color-charts-red-600-sMhEtb:#ff8a8a;
  --color-charts-red-700-1vSLrI:#ffa09e;
  --color-charts-red-800-2cvTzr:#ffb3b0;
  --color-charts-red-900-lyJiKz:#ffc4c0;
  --color-charts-red-1000-10wpD-:#ffd2cf;
  --color-charts-red-1100-2b6xrX:#ffe0dd;
  --color-charts-red-1200-3h9HjA:#ffecea;
  --color-charts-orange-300-3Fazlv:#c55305;
  --color-charts-orange-400-3rh-oy:#de6923;
  --color-charts-orange-500-29F5cF:#f27c36;
  --color-charts-orange-600-3j_60e:#f89256;
  --color-charts-orange-700-d-ZF6X:#fca572;
  --color-charts-orange-800-3dMR9p:#ffb68b;
  --color-charts-orange-900-3eCLL9:#ffc6a4;
  --color-charts-orange-1000-1ft3LA:#ffd4bb;
  --color-charts-orange-1100-1rFHIx:#ffe1cf;
  --color-charts-orange-1200-haIcdJ:#ffede2;
  --color-charts-yellow-300-1QSL1Q:#977001;
  --color-charts-yellow-400-1pmUpr:#b08400;
  --color-charts-yellow-500-Fbq27g:#c59600;
  --color-charts-yellow-600-x0XTst:#d3a61c;
  --color-charts-yellow-700-uYNHas:#dfb52c;
  --color-charts-yellow-800-2jsiC7:#eac33a;
  --color-charts-yellow-900-1leOJ5:#f1cf65;
  --color-charts-yellow-1000-G-JOv_:#f7db8a;
  --color-charts-yellow-1100-38YDD5:#fce5a8;
  --color-charts-yellow-1200-ZEWsG4:#ffefc9;
  --color-charts-green-300-Gk-x5D:#48851a;
  --color-charts-green-400-3Gp5UC:#5a9b29;
  --color-charts-green-500-1UIHe-:#69ae34;
  --color-charts-green-600-11aNBs:#7dbd4c;
  --color-charts-green-700-ZgWDvM:#8fca61;
  --color-charts-green-800-2ZMVcX:#9fd673;
  --color-charts-green-900-h9AbBY:#b2df8d;
  --color-charts-green-1000-2Obz4Q:#c5e7a8;
  --color-charts-green-1100-X5c9eG:#d5efbe;
  --color-charts-green-1200-3NUZfG:#e4f7d5;
  --color-charts-teal-300-1cN8Cn:#018574;
  --color-charts-teal-400-2vraJk:#009d89;
  --color-charts-teal-500-9FrMoX:#00b09b;
  --color-charts-teal-600-2UUnei:#40bfa9;
  --color-charts-teal-700-NU-4qs:#5fccb7;
  --color-charts-teal-800-1mmgce:#77d7c3;
  --color-charts-teal-900-2R6Ae7:#94e0d0;
  --color-charts-teal-1000-3UqmIz:#ace9db;
  --color-charts-teal-1100-1DI6zP:#c2f0e6;
  --color-charts-teal-1200-1e8w30:#d7f7f0;
  --color-charts-blue-1-300-NVZfma:#00819c;
  --color-charts-blue-1-400-3EeZFw:#0497ba;
  --color-charts-blue-1-500-1l-PV1:#08aad2;
  --color-charts-blue-1-600-G6Tezt:#44b9dd;
  --color-charts-blue-1-700-2TnoOg:#63c6e7;
  --color-charts-blue-1-800-1eLbUq:#79d2f0;
  --color-charts-blue-1-900-3_JXMA:#98dcf5;
  --color-charts-blue-1-1000-pMtBAm:#b3e4f8;
  --color-charts-blue-1-1100-1tENEi:#caedfc;
  --color-charts-blue-1-1200-1EYZ5e:#ddf4ff;
  --color-charts-blue-2-300-17FRhH:#486de8;
  --color-charts-blue-2-400-3axVbM:#6384f5;
  --color-charts-blue-2-500-2QbTAr:#7698fe;
  --color-charts-blue-2-600-w14n0w:#8ea9ff;
  --color-charts-blue-2-700-37mL3D:#a2b8ff;
  --color-charts-blue-2-800-bUsied:#b1c5ff;
  --color-charts-blue-2-900-33Y3FX:#c3d1ff;
  --color-charts-blue-2-1000-rzcdFI:#d2dcff;
  --color-charts-blue-2-1100-3q7MzB:#dfe6ff;
  --color-charts-blue-2-1200-2SqsCy:#ecf0ff;
  --color-charts-purple-300-La1b0W:#8d59de;
  --color-charts-purple-400-2L2Pop:#a173ea;
  --color-charts-purple-500-2NvZZb:#b088f5;
  --color-charts-purple-600-Zh4UZk:#bf9bf9;
  --color-charts-purple-700-2hpuWi:#cbabfc;
  --color-charts-purple-800-mt0PPD:#d6baff;
  --color-charts-purple-900-1iuo0e:#dfc8ff;
  --color-charts-purple-1000-1LOfvT:#e8d5ff;
  --color-charts-purple-1100-dcmh3E:#efe2ff;
  --color-charts-purple-1200-3Bndoz:#f5edff;
  --color-charts-pink-300-2XoT6f:#c64a70;
  --color-charts-pink-400-1YTbxv:#d56889;
  --color-charts-pink-500-1ueMSc:#e07f9d;
  --color-charts-pink-600-rMNZvg:#eb92ad;
  --color-charts-pink-700-2zpGOH:#f5a2bb;
  --color-charts-pink-800--or4cb:#ffb0c8;
  --color-charts-pink-900-mI7qx_:#ffc1d4;
  --color-charts-pink-1000-3Xhw0C:#ffd1de;
  --color-charts-pink-1100-8q1611:#ffdfe8;
  --color-charts-pink-1200-2Q8hyB:#ffecf1;
  --space-none-yzsLHp:0;
  --space-xxxs-10Z_bY:.2rem;
  --space-xxs-Pyh2mI:.4rem;
  --space-xs-3Eb7Zt:.8rem;
  --space-s-24EEro:1.2rem;
  --space-m-2nJwAn:1.6rem;
  --space-l-3JwfI_:2.0rem;
  --space-xl-1_REgj:2.4rem;
  --space-xxl-3huAay:3.2rem;
  --space-xxxl-IhpmRq:4.0rem;
  --size-base-oOehH1:1rem;
  --size-font-body-50-24EEro:1.2rem;
  --size-font-body-100-raVHfl:1.4rem;
  --size-font-heading-100-2nJwAn:1.6rem;
  --size-font-heading-200-1ZSlHP:1.8rem;
  --size-font-heading-500-14QHat:2.8rem;
  --size-font-display-200-AStMo2:4.4rem;
  --size-lineHeight-body-50-2nJwAn:1.6rem;
  --size-lineHeight-body-100-3TEa20:2.2rem;
  --size-lineHeight-heading-100-3JwfI_:2.0rem;
  --size-lineHeight-heading-200-3TEa20:2.2rem;
  --size-lineHeight-heading-500-3DvmnZ:3.6rem;
  --size-lineHeight-display-200-2-t5qR:5.6rem;
  --font-fontFamily-base-1sydl0:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  --font-fontFamily-monospace-1bAn4w:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  --font-fontWeight-bold-2stUXL:700;
  --font-fontWeight-normal-3o4GPz:400;
  --font-fontWeight-lighter-1ehTUD:300;
  --color-text-body-default-mcmuEw:var(--color-grey-300-q6XEFF);
  --color-text-body-secondary-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-heading-default-2S6vEC:var(--color-grey-200-XNKkLQ);
  --color-text-heading-secondary-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-home-header-default-pzFO4O:var(--color-grey-200-XNKkLQ);
  --color-text-home-header-secondary-1OFKGI:var(--color-grey-300-q6XEFF);
  --color-text-label-3qT4rX:var(--color-grey-450-3RXUpq);
  --color-text-accent-NYv9af:var(--color-orange-500-2Or5uE);
  --color-text-empty-6_f-gS:var(--color-grey-300-q6XEFF);
  --color-text-link-default-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-text-interactive-default-1oBHNI:var(--color-grey-300-q6XEFF);
  --color-text-interactive-disabled-TVd7uP:var(--color-grey-550-1_pyCK);
  --color-text-interactive-active-18qOom:var(--color-grey-100-2AKqb7);
  --color-text-interactive-hover-18qOom:var(--color-grey-100-2AKqb7);
  --color-text-small-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-inverted-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-disabled-TVd7uP:var(--color-grey-550-1_pyCK);
  --color-text-form-default-mcmuEw:var(--color-grey-300-q6XEFF);
  --color-text-form-secondary-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-form-label-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-input-disabled-3ki3SQ:var(--color-grey-550-1_pyCK);
  --color-text-input-placeholder-TVd7uP:var(--color-grey-550-1_pyCK);
  --color-text-control-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-status-error-1jj2sL:var(--color-red-500-c21Nsx);
  --color-text-status-warning-1jj2sL:var(--color-red-500-c21Nsx);
  --color-text-status-success-CuCgtA:var(--color-green-500-jl-Bwc);
  --color-text-status-info-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-text-status-inactive-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-status-pending-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-text-breadcrumb-6_f-gS:var(--color-grey-300-q6XEFF);
  --color-text-icon-caret-BxOb2m:var(--color-grey-450-3RXUpq);
  --color-text-icon-subtle-22ofsX:var(--color-grey-400-30uhvb);
  --color-text-column-header-3qT4rX:var(--color-grey-450-3RXUpq);
  --color-text-tooltip-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-tooltip-link-1yIcmc:var(--color-blue-400-2_UE0j);
  --color-text-dropdown-item-default-WiQFVx:var(--color-text-form-default-mcmuEw);
  --color-text-dropdown-item-highlighted-2S6vEC:var(--color-grey-200-XNKkLQ);
  --color-text-dropdown-item-secondary--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-dropdown-item-disabled-KGAbe7:var(--color-text-interactive-disabled-TVd7uP);
  --color-text-dropdown-item-filter-match-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-text-dropdown-group-label-2XOlKp:var(--color-text-label-3qT4rX);
  --color-text-dropdown-footer--r75hR:var(--color-text-form-secondary-2ZkiuH);
  --color-text-notification-default-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-notification-icon-default-Xkg9ln:var(--color-grey-300-q6XEFF);
  --color-text-notification-icon-hover-JWSlel:var(--color-grey-100-2AKqb7);
  --color-text-code-editor-gutter-default-mcmuEw:var(--color-grey-300-q6XEFF);
  --color-text-code-editor-gutter-active-line-DnITSk:var(--color-grey-900-w0NbTF);
  --color-text-code-editor-status-bar-disabled-3ki3SQ:var(--color-grey-550-1_pyCK);
  --color-text-code-editor-tab-button-error-DnITSk:var(--color-grey-900-w0NbTF);
  --color-foreground-control-default-3g5IJp:var(--color-white-3kAlam);
  --color-foreground-control-disabled-3d6ker:var(--color-grey-550-1_pyCK);
  --color-background-home-header-ScmmEP:var(--color-black-2XzW-x);
  --color-background-layout-main-3Bydyi:var(--color-grey-900-w0NbTF);
  --color-background-layout-panel-content-1Z_Abk:var(--color-background-container-content-3w9fMz);
  --color-background-layout-panel-hover-38loDV:var(--color-grey-650-3EXt7H);
  --color-background-item-selected-WkeJRF:var(--color-blue-900-1NqxOj);
  --color-background-modal-overlay-3ptHv2:var(--color-grey-opaque-80-1Db7V9);
  --color-background-status-error-3eo6Wg:var(--color-red-900-3SXvmY);
  --color-background-status-warning-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-status-success-_mCzOj:var(--color-green-900-jZfd0l);
  --color-background-status-info-WkeJRF:var(--color-blue-900-1NqxOj);
  --color-background-progress-bar-layout-default-38loDV:var(--color-grey-650-3EXt7H);
  --color-background-progress-bar-layout-in-flash-2azuvH:var(--color-grey-opaque-25-3gH_CO);
  --color-background-progress-bar-content-default-3uW6lA:var(--color-blue-500-2Y609w);
  --color-background-progress-bar-content-in-flash-1gkz93:var(--color-grey-100-2AKqb7);
  --color-background-calendar-today-3Bydyi:var(--color-grey-900-w0NbTF);
  --color-background-container-header-1OJlgw:var(--color-grey-750-vMCyzl);
  --color-background-container-content-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-control-default-528DLz:var(--color-grey-800-1UF29O);
  --color-background-control-checked-3uW6lA:var(--color-blue-500-2Y609w);
  --color-background-control-disabled-11nezK:var(--color-grey-650-3EXt7H);
  --color-background-input-default-528DLz:var(--color-grey-800-1UF29O);
  --color-background-input-disabled-38loDV:var(--color-grey-650-3EXt7H);
  --color-background-tiles-disabled-1gYBaj:var(--color-grey-700-2ayya1);
  --color-background-tooltip-sSa4Z-:var(--color-grey-900-w0NbTF);
  --color-background-popover-BPqwPK:var(--color-grey-750-vMCyzl);
  --color-background-toggle-default-3zU3ek:var(--color-grey-500-RUsejK);
  --color-background-toggle-checked-disabled-uMVvXl:var(--color-blue-700-1Gxewg);
  --color-background-button-normal-default-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-button-normal-disabled-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-button-normal-hover-1OJlgw:var(--color-grey-750-vMCyzl);
  --color-background-button-normal-active-2WWahd:var(--color-grey-900-w0NbTF);
  --color-background-button-primary-default-2YgUpW:var(--color-text-accent-NYv9af);
  --color-background-button-primary-disabled-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-button-primary-hover-39GfvT:var(--color-orange-600-FMAoZX);
  --color-background-button-primary-active-1lqtcO:var(--color-orange-500-2Or5uE);
  --color-background-button-link-hover-1OJlgw:var(--color-grey-750-vMCyzl);
  --color-background-button-link-active-2WWahd:var(--color-grey-900-w0NbTF);
  --color-background-dropdown-item-default-3w9fMz:var(--color-grey-700-2ayya1);
  --color-background-dropdown-item-selected-nrt43a:var(--color-background-item-selected-WkeJRF);
  --color-background-dropdown-item-hover-35tDd2:var(--color-grey-650-3EXt7H);
  --color-background-dropdown-item-filter-match-WkeJRF:var(--color-blue-900-1NqxOj);
  --color-background-notification-grey-3n2Isj:var(--color-grey-550-1_pyCK);
  --color-background-notification-green-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-background-notification-blue-Y4F8hz:var(--color-blue-600-3mTfxB);
  --color-background-notification-red-3bUMsg:var(--color-red-600-2N9Epo);
  --color-background-code-editor-gutter-default-ikVlHL:var(--color-grey-750-vMCyzl);
  --color-background-code-editor-gutter-active-line-default-3mUVYZ:var(--color-grey-500-RUsejK);
  --color-background-code-editor-gutter-active-line-error-1jj2sL:var(--color-red-500-c21Nsx);
  --color-background-code-editor-status-bar-ikVlHL:var(--color-grey-750-vMCyzl);
  --color-background-code-editor-pane-item-hover-38loDV:var(--color-grey-650-3EXt7H);
  --color-border-item-focused-2uP71z:var(--color-blue-500-2Y609w);
  --color-border-item-selected-6FRHtw:var(--color-blue-500-2Y609w);
  --color-border-status-success-1ceXEb:var(--color-green-600-3iv9Fc);
  --color-border-status-error-3bUMsg:var(--color-red-600-2N9Epo);
  --color-border-status-warning-27WVST:var(--color-grey-500-RUsejK);
  --color-border-status-info-3uW6lA:var(--color-blue-500-2Y609w);
  --color-border-container-top-1gYBaj:var(--color-grey-700-2ayya1);
  --color-border-control-default-27WVST:var(--color-grey-500-RUsejK);
  --color-border-control-checked-3JzNc6:var(--color-background-control-checked-3uW6lA);
  --color-border-control-disabled-Rx1zFC:var(--color-background-control-disabled-11nezK);
  --color-border-control-hover-3OSsIc:var(--color-grey-400-30uhvb);
  --color-border-input-default-27WVST:var(--color-grey-500-RUsejK);
  --color-border-input-disabled-1QUbGm:var(--color-background-input-disabled-38loDV);
  --color-border-segment-default-3mUVYZ:var(--color-grey-500-RUsejK);
  --color-border-segment-hover-1dD-mx:var(--color-white-3kAlam);
  --color-border-tiles-disabled-1D4yG6:var(--color-grey-650-3EXt7H);
  --color-border-tabs-2fNNxL:var(--color-grey-650-3EXt7H);
  --color-border-tooltip-sSa4Z-:var(--color-grey-900-w0NbTF);
  --color-border-popover-hSim3z:var(--color-grey-600-1cltHv);
  --color-border-button-normal-default-3zU3ek:var(--color-grey-500-RUsejK);
  --color-border-button-normal-hover-ots1Pn:var(--color-grey-400-30uhvb);
  --color-border-button-normal-disabled-11nezK:var(--color-grey-650-3EXt7H);
  --color-border-button-primary-hover-2NzUKZ:var(--color-orange-600-FMAoZX);
  --color-border-button-primary-disabled-11nezK:var(--color-grey-650-3EXt7H);
  --color-border-dropdown-item-default-2jqiTh:var(--color-border-divider-default-38loDV);
  --color-border-dropdown-item-selected-1BqG1q:var(--color-border-item-selected-6FRHtw);
  --color-border-dropdown-item-hover-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-dropdown-item-top-h3yn0h:var(--color-border-container-top-1gYBaj);
  --color-border-dropdown-group-PaVVo1:var(--color-border-dropdown-item-default-2jqiTh);
  --color-border-divider-default-38loDV:var(--color-grey-650-3EXt7H);
  --color-border-divider-active-3Saafg:var(--color-grey-500-RUsejK);
  --color-border-layout-11nezK:var(--color-grey-650-3EXt7H);
  --color-border-code-editor-default-1MzhzU:var(--color-grey-550-1_pyCK);
  --color-border-code-editor-pane-item-hover-3mUVYZ:var(--color-grey-500-RUsejK);
  --color-shadow-default-3LpQcN:var(--color-grey-transparent-heavy-3Le0m-);
  --color-shadow-medium-3CtI4L:var(--color-grey-transparent-1Qa-ea);
  --color-shadow-side-2cOaCx:var(--color-grey-transparent-light-5LOYx8);
  --color-charts-status-critical-3OhIPH:var(--color-charts-red-300-iHH87A);
  --color-charts-status-high-2Kj1VI:var(--color-charts-red-500-2KGzVl);
  --color-charts-status-medium-1G_3c2:var(--color-charts-orange-600-3j_60e);
  --color-charts-status-low-336vTu:var(--color-charts-yellow-700-uYNHas);
  --color-charts-status-positive-2PAhQm:var(--color-charts-green-500-1UIHe-);
  --color-charts-status-info-3f36yo:var(--color-charts-blue-1-500-1l-PV1);
  --color-charts-status-neutral-3Saafg:var(--color-grey-500-RUsejK);
  --color-charts-threshold-negative-1jj2sL:var(--color-red-500-c21Nsx);
  --color-charts-threshold-positive-CuCgtA:var(--color-green-500-jl-Bwc);
  --color-charts-threshold-info-Bc0LvG:var(--color-blue-400-2_UE0j);
  --color-charts-threshold-neutral-2ZkiuH:var(--color-grey-450-3RXUpq);
  --color-charts-line-grid-11nezK:var(--color-grey-650-3EXt7H);
  --color-charts-line-tick-11nezK:var(--color-grey-650-3EXt7H);
  --color-charts-line-axis-11nezK:var(--color-grey-650-3EXt7H);
  --color-charts-palette-categorical-1-1-pTru:var(--color-charts-blue-2-300-17FRhH);
  --color-charts-palette-categorical-2-34HR6o:var(--color-charts-pink-500-1ueMSc);
  --color-charts-palette-categorical-3-szhk2o:var(--color-charts-teal-300-1cN8Cn);
  --color-charts-palette-categorical-4-1Quw1Y:var(--color-charts-purple-500-2NvZZb);
  --color-charts-palette-categorical-5-3JVo2f:var(--color-charts-orange-300-3Fazlv);
  --color-charts-palette-categorical-6-SU6PW_:var(--color-charts-blue-2-600-w14n0w);
  --color-charts-palette-categorical-7-3IYXwW:var(--color-charts-pink-800--or4cb);
  --color-charts-palette-categorical-8-yHJ2-F:var(--color-charts-teal-600-2UUnei);
  --color-charts-palette-categorical-9-2qUf2R:var(--color-charts-purple-800-mt0PPD);
  --color-charts-palette-categorical-10-12jyfA:var(--color-charts-orange-600-3j_60e);
  --color-charts-palette-categorical-11-2pfiQa:var(--color-charts-blue-2-900-33Y3FX);
  --color-charts-palette-categorical-12-2PcgQF:var(--color-charts-pink-1100-8q1611);
  --color-charts-palette-categorical-13-37MGoo:var(--color-charts-teal-900-2R6Ae7);
  --color-charts-palette-categorical-14-3aISc9:var(--color-charts-purple-1100-dcmh3E);
  --color-charts-palette-categorical-15-2Gp9uo:var(--color-charts-orange-900-3eCLL9);
  --color-charts-palette-categorical-16-28q0ff:var(--color-charts-blue-2-1200-2SqsCy);
  --color-charts-palette-categorical-17-1aB9Zq:var(--color-charts-pink-400-1YTbxv);
  --color-charts-palette-categorical-18-2yMkrj:var(--color-charts-teal-1200-1e8w30);
  --color-charts-palette-categorical-19-1NJbV1:var(--color-charts-purple-400-2L2Pop);
  --color-charts-palette-categorical-20-1wLgQL:var(--color-charts-orange-1200-haIcdJ);
  --color-charts-palette-categorical-21-1FqHMb:var(--color-charts-blue-2-500-2QbTAr);
  --color-charts-palette-categorical-22-pC2Dnu:var(--color-charts-pink-700-2zpGOH);
  --color-charts-palette-categorical-23-26JiEH:var(--color-charts-teal-500-9FrMoX);
  --color-charts-palette-categorical-24-2WNr7z:var(--color-charts-purple-700-2hpuWi);
  --color-charts-palette-categorical-25-1bNnKJ:var(--color-charts-orange-500-29F5cF);
  --color-charts-palette-categorical-26-1eblPy:var(--color-charts-blue-2-800-bUsied);
  --color-charts-palette-categorical-27-1cCzE_:var(--color-charts-pink-1000-3Xhw0C);
  --color-charts-palette-categorical-28-3XHS8e:var(--color-charts-teal-800-1mmgce);
  --color-charts-palette-categorical-29-36AEib:var(--color-charts-purple-1000-1LOfvT);
  --color-charts-palette-categorical-30-1f8RyN:var(--color-charts-orange-800-3dMR9p);
  --color-charts-palette-categorical-31-3p8fai:var(--color-charts-blue-2-1100-3q7MzB);
  --color-charts-palette-categorical-32-2jN07T:var(--color-charts-pink-300-2XoT6f);
  --color-charts-palette-categorical-33-2iB0R0:var(--color-charts-teal-1100-1DI6zP);
  --color-charts-palette-categorical-34-3fOTRf:var(--color-charts-purple-300-La1b0W);
  --color-charts-palette-categorical-35-m3BG6Z:var(--color-charts-orange-1100-1rFHIx);
  --color-charts-palette-categorical-36-8yefO3:var(--color-charts-blue-2-400-3axVbM);
  --color-charts-palette-categorical-37-2K_jwO:var(--color-charts-pink-600-rMNZvg);
  --color-charts-palette-categorical-38-1PQZLc:var(--color-charts-teal-400-2vraJk);
  --color-charts-palette-categorical-39-1agnOi:var(--color-charts-purple-600-Zh4UZk);
  --color-charts-palette-categorical-40-1id8iv:var(--color-charts-orange-400-3rh-oy);
  --color-charts-palette-categorical-41-1E5KJb:var(--color-charts-blue-2-700-37mL3D);
  --color-charts-palette-categorical-42-JzwWC7:var(--color-charts-pink-900-mI7qx_);
  --color-charts-palette-categorical-43-194bmz:var(--color-charts-teal-700-NU-4qs);
  --color-charts-palette-categorical-44-1AQ6-c:var(--color-charts-purple-900-1iuo0e);
  --color-charts-palette-categorical-45-3YZvZb:var(--color-charts-orange-700-d-ZF6X);
  --color-charts-palette-categorical-46-2WkUD0:var(--color-charts-blue-2-1000-rzcdFI);
  --color-charts-palette-categorical-47-PPA-r9:var(--color-charts-pink-1200-2Q8hyB);
  --color-charts-palette-categorical-48-1VCkkT:var(--color-charts-teal-1000-3UqmIz);
  --color-charts-palette-categorical-49-2F1j6B:var(--color-charts-purple-1200-3Bndoz);
  --color-charts-palette-categorical-50-2V2z5L:var(--color-charts-orange-1000-1ft3LA);
  --size-icon-normal-2nJwAn:1.6rem;
  --size-icon-big-3huAay:3.2rem;
  --size-icon-large-OupcJw:4.8rem;
  --size-control-raVHfl:1.4rem;
}

.awsui-polaris-compact-mode:not(#\9){
  --space-none-2rGI2r:0;
  --space-xxxs-10EX_Z:.2rem;
  --space-xxs-137Hig:.4rem;
  --space-xs-1QR0Ng:.8rem;
  --space-s-2tKeb0:1.2rem;
  --space-m-25I9Cr:1.6rem;
  --space-l-3cXvov:2.0rem;
  --space-xl-3JXLlv:2.4rem;
  --space-xxl-3_q1V_:3.2rem;
  --space-xxxl-3dxeuJ:4.0rem;
  --space-scaled-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-xxxs-1yX_gQ:var(--space-none-2rGI2r);
  --space-scaled-xxs-wz9S8K:var(--space-xxxs-10EX_Z);
  --space-scaled-xs-19adjU:var(--space-xxs-137Hig);
  --space-scaled-s-1sAHT4:var(--space-xs-1QR0Ng);
  --space-scaled-m-p3tAne:var(--space-s-2tKeb0);
  --space-scaled-l-17GFGI:var(--space-m-25I9Cr);
  --space-scaled-xl-axUbkA:var(--space-l-3cXvov);
  --space-scaled-xxl-238u6Z:var(--space-xl-3JXLlv);
  --space-scaled-xxxl-3VX7k3:var(--space-xxl-3_q1V_);
  --space-scaled-2x-none-1ld6ZS:var(--space-none-2rGI2r);
  --space-scaled-2x-xxxs-1yX_gQ:var(--space-none-2rGI2r);
  --space-scaled-2x-xxs-wz9S8K:var(--space-none-2rGI2r);
  --space-scaled-2x-xs-19adjU:var(--space-none-2rGI2r);
  --space-scaled-2x-s-1sAHT4:var(--space-xxs-137Hig);
  --space-scaled-2x-m-p3tAne:var(--space-xs-1QR0Ng);
  --space-scaled-2x-l-17GFGI:var(--space-s-2tKeb0);
  --space-scaled-2x-xl-axUbkA:var(--space-m-25I9Cr);
  --space-scaled-2x-xxl-238u6Z:var(--space-l-3cXvov);
  --space-scaled-2x-xxxl-3VX7k3:var(--space-xl-3JXLlv);
  --space-grid-gutter-17GFGI:var(--space-m-25I9Cr);
  --size-vertical-input-3_q1V_:2.8rem;
  --size-vertical-panel-icon-offset-2Iktcb:1.3rem;
}

.awsui-motion-disabled:not(#\9){
  --motion-duration-extra-fast-1YDA0Q:0ms;
  --motion-duration-fast-oPeLPK:0ms;
  --motion-duration-moderate-3V3IbE:0ms;
  --motion-duration-slow-39Jq2G:0ms;
  --motion-duration-extra-slow-39YF13:0ms;
  --motion-duration-show-quick-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-rotate-90-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-rotate-180-TMbCmo:var(--motion-duration-moderate-3V3IbE);
  --motion-duration-transition-show-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-duration-transition-show-paced-2crOWV:var(--motion-duration-slow-39Jq2G);
  --motion-duration-transition-quick-2hvSaJ:var(--motion-duration-fast-oPeLPK);
  --motion-easing-ease-out-quart-cJfwJd:cubic-bezier(0.165, 0.84, 0.44, 1);
  --motion-easing-show-quick-25z_bd:ease-out;
  --motion-easing-show-paced-25z_bd:ease-out;
  --motion-easing-rotate-90-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-rotate-180-2ebTCo:var(--motion-easing-ease-out-quart-cJfwJd);
  --motion-easing-transition-show-quick-2nchNs:linear;
  --motion-easing-transition-show-paced-25z_bd:ease-out;
  --motion-easing-transition-quick-2nchNs:linear;
}
._undo-button_brdst_12utu_11:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:underline;
  cursor:pointer;
}
._undo-button_brdst_12utu_11:not(#\9):focus{
  outline:none;
}
._undo-button_brdst_12utu_11[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._root_14cnr_186r6_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:static;
  white-space:inherit;
  display:inline;
}
._root_14cnr_186r6_3 > ._trigger_14cnr_186r6_15:not(#\9){
  display:inline;
}
._root_14cnr_186r6_3 > ._dropdown_14cnr_186r6_18:not(#\9){
  position:static;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  background-color:#ffffff;
  background-color:var(--color-background-dropdown-item-default-3w9fMz, #ffffff);
  outline:none;
  border-top:none;
  border-bottom:none;
  display:flex;
  flex-direction:column;
  width:100%;
}
._root_14cnr_186r6_3 > ._dropdown_14cnr_186r6_18._nowrap_14cnr_186r6_29:not(#\9){
  white-space:nowrap;
}
._root_14cnr_186r6_3 > ._dropdown_14cnr_186r6_18._open_14cnr_186r6_32:not(#\9){
  display:flex;
}
._link_1kosq_9io39_3:not(#\9):after{
  display:none;
}

._breadcrumb_1kosq_9io39_7:not(#\9){
  display:flex;
}
._breadcrumb_1kosq_9io39_7 > ._icon_1kosq_9io39_10:not(#\9){
  margin:0 0.8rem;
  margin:0 var(--space-xs-1QR0Ng, 0.8rem);
}
._breadcrumb_1kosq_9io39_7 > a:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
}
._breadcrumb_1kosq_9io39_7 > a:not(#\9):hover{
  cursor:pointer;
}
._breadcrumb_1kosq_9io39_7 > a:not(#\9):focus{
  outline:none;
}
._breadcrumb_1kosq_9io39_7 > a:not(#\9):active, ._breadcrumb_1kosq_9io39_7 > a:not(#\9):focus, ._breadcrumb_1kosq_9io39_7 > a:not(#\9):hover{
  text-decoration:underline;
}
._breadcrumb_1kosq_9io39_7 > a[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._breadcrumb_1kosq_9io39_7._last_1kosq_9io39_32 > ._icon_1kosq_9io39_10:not(#\9){
  display:none;
}
._breadcrumb_1kosq_9io39_7._last_1kosq_9io39_32 > a:not(#\9){
  color:#687078;
  color:var(--color-text-breadcrumb-6_f-gS, #687078);
  text-decoration:none;
  cursor:default;
  pointer-events:none;
}

._compressed_1kosq_9io39_42:not(#\9){
  min-width:0;
  overflow:hidden;
}
._compressed_1kosq_9io39_42 > span:not(#\9){
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  display:block;
}
._help-panel_1d237_ir49z_4:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
  padding:2rem 3.2rem 0;
  padding:var(--space-scaled-l-17GFGI, 2rem) var(--space-scaled-xxl-238u6Z, 3.2rem) 0;
}
._help-panel_1d237_ir49z_4 hr:not(#\9){
  border:none;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:2.4rem calc(-1 * 0.8rem);
  margin:var(--space-scaled-xl-axUbkA, 2.4rem) calc(-1 * var(--space-xs-1QR0Ng, 0.8rem));
}
._help-panel_1d237_ir49z_4 ol:not(#\9),
._help-panel_1d237_ir49z_4 ul:not(#\9){
  padding-left:2rem;
  padding-left:var(--space-l-3cXvov, 2rem);
  list-style-position:outside;
}
._help-panel_1d237_ir49z_4 a:not(#\9),
._help-panel_1d237_ir49z_4 p:not(#\9),
._help-panel_1d237_ir49z_4 ul:not(#\9),
._help-panel_1d237_ir49z_4 ol:not(#\9),
._help-panel_1d237_ir49z_4 li:not(#\9),
._help-panel_1d237_ir49z_4 h2:not(#\9),
._help-panel_1d237_ir49z_4 h3:not(#\9),
._help-panel_1d237_ir49z_4 h4:not(#\9),
._help-panel_1d237_ir49z_4 h5:not(#\9),
._help-panel_1d237_ir49z_4 pre:not(#\9),
._help-panel_1d237_ir49z_4 code:not(#\9){
  margin:0.8rem 0;
  margin:var(--space-xs-1QR0Ng, 0.8rem) 0;
  padding-top:0;
  padding-bottom:0;
}
._help-panel_1d237_ir49z_4 code:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
  padding:0 0.4rem;
  padding:0 var(--space-xxs-137Hig, 0.4rem);
  word-wrap:break-word;
  white-space:pre-wrap;
}
._help-panel_1d237_ir49z_4 pre:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
  padding:0 0.4rem;
  padding:0 var(--space-xxs-137Hig, 0.4rem);
  word-wrap:break-word;
  white-space:pre-wrap;
  padding:0.4rem;
  padding:var(--space-xxs-137Hig, 0.4rem);
}
._help-panel_1d237_ir49z_4 dl:not(#\9){
  margin:0.8rem 0;
  margin:var(--space-xs-1QR0Ng, 0.8rem) 0;
}
._help-panel_1d237_ir49z_4 dl *:not(#\9){
  margin-top:0;
  margin-bottom:0;
}
._help-panel_1d237_ir49z_4 dt:not(#\9){
  margin-top:0.8rem;
  margin-top:var(--space-xs-1QR0Ng, 0.8rem);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}
._help-panel_1d237_ir49z_4 dd:not(#\9){
  margin:0 0 0.8rem;
  margin:0 0 var(--space-xs-1QR0Ng, 0.8rem);
}
._help-panel_1d237_ir49z_4 h2:not(#\9),
._help-panel_1d237_ir49z_4 h3:not(#\9),
._help-panel_1d237_ir49z_4 h4:not(#\9),
._help-panel_1d237_ir49z_4 h5:not(#\9),
._help-panel_1d237_ir49z_4 h6:not(#\9){
  margin-top:2.4rem;
  margin-top:var(--space-xl-3JXLlv, 2.4rem);
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}
._help-panel_1d237_ir49z_4 > :not(#\9):last-child{
  margin-bottom:4rem;
  margin-bottom:var(--space-xxxl-3dxeuJ, 4rem);
}
._help-panel_1d237_ir49z_4 p:not(#\9){
  color:inherit;
  text-decoration:none;
}
._help-panel_1d237_ir49z_4 h1:not(#\9){
  font-size:2.8rem;
  font-size:var(--size-font-heading-500-14QHat, 2.8rem);
  line-height:3.6rem;
  line-height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
}
._help-panel_1d237_ir49z_4 h2:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._help-panel_1d237_ir49z_4 h3:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._help-panel_1d237_ir49z_4 h4:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._help-panel_1d237_ir49z_4 h5:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._help-panel_1d237_ir49z_4 h3:not(#\9),
._help-panel_1d237_ir49z_4 h5:not(#\9){
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}
._help-panel_1d237_ir49z_4 h2:not(#\9),
._help-panel_1d237_ir49z_4 h4:not(#\9),
._help-panel_1d237_ir49z_4 b:not(#\9),
._help-panel_1d237_ir49z_4 strong:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}
._help-panel_1d237_ir49z_4 a:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
}
._help-panel_1d237_ir49z_4 a:not(#\9):hover, ._help-panel_1d237_ir49z_4 a:not(#\9):active, ._help-panel_1d237_ir49z_4 a:not(#\9):focus{
  text-decoration:underline;
}
._help-panel_1d237_ir49z_4 a:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
}
._help-panel_1d237_ir49z_4 ._header_1d237_ir49z_130:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  padding-bottom:2rem;
  padding-bottom:var(--space-scaled-l-17GFGI, 2rem);
  padding-left:3.2rem;
  padding-left:var(--space-scaled-xxl-238u6Z, 3.2rem);
  padding-right:calc(2.4rem + 3.2rem);
  padding-right:calc(var(--space-xl-3JXLlv, 2.4rem) + var(--space-scaled-xxl-238u6Z, 3.2rem));
  border:none;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:0 calc(-1 * 3.2rem) 2rem;
  margin:0 calc(-1 * var(--space-scaled-xxl-238u6Z, 3.2rem)) var(--space-scaled-l-17GFGI, 2rem);
}
._help-panel_1d237_ir49z_4 ._header_1d237_ir49z_130 h2:not(#\9),
._help-panel_1d237_ir49z_4 ._header_1d237_ir49z_130 h3:not(#\9),
._help-panel_1d237_ir49z_4 ._header_1d237_ir49z_130 h4:not(#\9),
._help-panel_1d237_ir49z_4 ._header_1d237_ir49z_130 h5:not(#\9),
._help-panel_1d237_ir49z_4 ._header_1d237_ir49z_130 h6:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
  padding-top:0;
  padding-bottom:0;
  margin-top:0;
  margin-bottom:0;
}

._content_1d237_ir49z_155:not(#\9){
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._content_1d237_ir49z_155 h2:not(#\9):first-child,
._content_1d237_ir49z_155 h3:not(#\9):first-child,
._content_1d237_ir49z_155 h4:not(#\9):first-child,
._content_1d237_ir49z_155 h5:not(#\9):first-child,
._content_1d237_ir49z_155 h6:not(#\9):first-child{
  margin-top:0;
}

._footer_1d237_ir49z_166:not(#\9){
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  margin:2.4rem calc(-1 * 0.8rem);
  margin:var(--space-scaled-xl-axUbkA, 2.4rem) calc(-1 * var(--space-xs-1QR0Ng, 0.8rem));
  padding:0 1.2rem;
  padding:0 var(--space-s-2tKeb0, 1.2rem);
}
._footer_1d237_ir49z_166 > :not(#\9):first-child{
  margin-top:2.4rem;
  margin-top:var(--space-scaled-xl-axUbkA, 2.4rem);
}
._footer_1d237_ir49z_166 ul:not(#\9){
  list-style:none;
  padding-left:0;
}
._content-enter_ig8mp_16axx_3:not(#\9){
  animation:_awsui-motion-fade-in_ig8mp_16axx_1 180ms ease-out;
  animation:_awsui-motion-fade-in_ig8mp_16axx_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in_ig8mp_16axx_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._content-enter_ig8mp_16axx_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._content-enter_ig8mp_16axx_3:not(#\9){
  animation:none;
  transition:none;
}

._tutorial-list_ig8mp_16axx_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding:0;
  margin:0;
}

._tutorial-box_ig8mp_16axx_37:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  list-style:none;
  border:1px solid #aab7b8;
  border:1px solid var(--color-border-control-default-27WVST, #aab7b8);
  padding:1.6rem;
  padding:var(--space-m-25I9Cr, 1.6rem);
}
._tutorial-box_ig8mp_16axx_37:not(#\9):not(:first-child){
  margin-top:2rem;
  margin-top:var(--space-l-3cXvov, 2rem);
}

._tutorial-box-title_ig8mp_16axx_53:not(#\9){
  display:flex;
  align-items:flex-start;
  justify-content:space-between;
}

._tutorial-description-plaintext_ig8mp_16axx_59:not(#\9){
  white-space:pre-line;
}
._tutorial-description_ig8mp_16axx_59 ul:not(#\9){
  list-style-type:disc;
}

._expandable-section_ig8mp_16axx_70:not(#\9){
  display:none;
  margin-top:1.6rem;
  margin-top:var(--space-m-25I9Cr, 1.6rem);
}
._expandable-section_ig8mp_16axx_70._expanded_ig8mp_16axx_74:not(#\9){
  display:block;
}

._button-wrapper_ig8mp_16axx_78:not(#\9){
  margin:calc(-1 * 0.4rem - 1px);
  margin:calc(-1 * var(--space-xxs-137Hig, 0.4rem) - 1px);
}

._download-link_ig8mp_16axx_98:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  text-decoration:none;
  display:inline;
  white-space:inherit;
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  letter-spacing:0.01em;
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._download-link_ig8mp_16axx_98:not(#\9):hover{
  cursor:pointer;
}
._download-link_ig8mp_16axx_98:not(#\9):focus{
  outline:none;
}
._download-link_ig8mp_16axx_98:not(#\9):active, ._download-link_ig8mp_16axx_98:not(#\9):focus, ._download-link_ig8mp_16axx_98:not(#\9):hover{
  text-decoration:underline;
}
._download-link_ig8mp_16axx_98[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
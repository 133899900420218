._button-trigger_18eso_1b6tt_3 > ._arrow_18eso_1b6tt_3:not(#\9){
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._button-trigger_18eso_1b6tt_3 > ._arrow_18eso_1b6tt_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._button-trigger_18eso_1b6tt_3 > ._arrow_18eso_1b6tt_3:not(#\9){
  animation:none;
  transition:none;
}

._button-trigger_18eso_1b6tt_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  display:flex;
  width:100%;
  justify-content:space-between;
  align-items:center;
  padding:0.4rem calc(1.6rem + (2 * 0.8rem)) 0.4rem 0.8rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) calc(var(--size-icon-normal-2nJwAn, 1.6rem) + (2 * var(--space-xs-1QR0Ng, 0.8rem))) var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xs-1QR0Ng, 0.8rem);
  background-color:#ffffff;
  background-color:var(--color-background-input-default-528DLz, #ffffff);
  border-radius:2px;
  border:1px solid #aab7b8;
  border:1px solid var(--color-border-input-default-27WVST, #aab7b8);
  min-height:3.2rem;
  min-height:var(--size-vertical-input-3_q1V_, 3.2rem);
}
._button-trigger_18eso_1b6tt_3 > ._placeholder_18eso_1b6tt_36:not(#\9){
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}
._button-trigger_18eso_1b6tt_3 > ._arrow_18eso_1b6tt_3:not(#\9){
  position:absolute;
  right:0.8rem;
  right:var(--space-xs-1QR0Ng, 0.8rem);
  top:calc(50% - 2.2rem / 2);
  top:calc(50% - var(--size-lineHeight-body-100-3TEa20, 2.2rem) / 2);
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._button-trigger_18eso_1b6tt_3:not(#\9):hover > ._arrow_18eso_1b6tt_3{
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._button-trigger_18eso_1b6tt_3._pressed_18eso_1b6tt_49 > ._arrow_18eso_1b6tt_3:not(#\9){
  transform:rotate(-180deg);
}
._button-trigger_18eso_1b6tt_3._disabled_18eso_1b6tt_52:not(#\9){
  background-color:#eaeded;
  background-color:var(--color-background-input-disabled-38loDV, #eaeded);
  border:1px solid #eaeded;
  border:1px solid var(--color-border-input-disabled-1QUbGm, #eaeded);
  color:#879596;
  color:var(--color-text-input-disabled-3ki3SQ, #879596);
  cursor:auto;
}
._button-trigger_18eso_1b6tt_3._disabled_18eso_1b6tt_52 > ._arrow_18eso_1b6tt_3:not(#\9){
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._button-trigger_18eso_1b6tt_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button-trigger_18eso_1b6tt_3:not(#\9):invalid{
  box-shadow:none;
}
._button-trigger_18eso_1b6tt_3._invalid_18eso_1b6tt_70:not(#\9), ._button-trigger_18eso_1b6tt_3._invalid_18eso_1b6tt_70:not(#\9):focus{
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  border-color:#d13212;
  border-color:var(--color-text-status-error-1jj2sL, #d13212);
  padding-left:calc( 0.8rem - (4px - 1px) );
  padding-left:calc( var(--space-xs-1QR0Ng, 0.8rem) - (4px - 1px) );
  border-left-width:4px;
}
._button-trigger_18eso_1b6tt_3:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._column-layout_vvxn7_13n55_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13:not(#\9){
  margin:calc(2rem / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / -2);
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-no-gutters_vvxn7_13n55_16:not(#\9){
  margin:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19:not(#\9){
  margin:calc(-1 * 2rem);
  margin:calc(-1 * var(--space-grid-gutter-17GFGI, 2rem));
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19 > *:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-breakpoint-default_vvxn7_13n55_25 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-1_vvxn7_13n55_28._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-1_vvxn7_13n55_28._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-2_vvxn7_13n55_34._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-2_vvxn7_13n55_34._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(3n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-variant-text-grid_vvxn7_13n55_19._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(4n+1){
  border-left-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13:not(#\9):not(._grid-no-gutters_vvxn7_13n55_16) > *{
  padding:calc(2rem / 2);
  padding:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13:not(#\9):not(._grid-no-gutters_vvxn7_13n55_16)._grid-variant-text-grid_vvxn7_13n55_19 > *{
  padding:0 2rem;
  padding:0 var(--space-grid-gutter-17GFGI, 2rem);
  margin:2rem 0;
  margin:var(--space-grid-gutter-17GFGI, 2rem) 0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59 > *:not(#\9){
  border-right:1px solid #eaeded;
  border-right:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-breakpoint-default_vvxn7_13n55_25 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-1_vvxn7_13n55_28._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-1_vvxn7_13n55_28._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-2_vvxn7_13n55_34._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-2_vvxn7_13n55_34._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(3n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-vertical-borders_vvxn7_13n55_59._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-child(4n){
  border-right-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89 > *:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89 > *:not(#\9):last-child{
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-2_vvxn7_13n55_34._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-2_vvxn7_13n55_34._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(2):nth-child(3n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(2):nth-child(3n+2){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-3_vvxn7_13n55_40._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(3):nth-child(3n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xxs_vvxn7_13n55_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(2):nth-child(4n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(2):nth-child(4n+2){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(2):nth-child(4n+3){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(3):nth-child(4n+1){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(3):nth-child(4n+2){
  border-bottom-width:0;
}
._column-layout_vvxn7_13n55_3 > ._grid_vvxn7_13n55_13._grid-horizontal-borders_vvxn7_13n55_89._grid-columns-4_vvxn7_13n55_46._grid-breakpoint-xs_vvxn7_13n55_31 > *:not(#\9):nth-last-child(4):nth-child(4n+1){
  border-bottom-width:0;
}
._root_1sdq3_1xxo6_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:center;
  max-width:calc(688px - 2 * 2rem);
  max-width:calc(688px - 2 * var(--space-l-3cXvov, 2rem));
}

._input_1sdq3_1xxo6_16:not(#\9){
  flex:1;
}

._results_1sdq3_1xxo6_20:not(#\9){
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  display:inline-block;
  box-sizing:border-box;
  white-space:nowrap;
}
._results-visible_1sdq3_1xxo6_26:not(#\9){
  padding-left:1.2rem;
  padding-left:var(--space-s-2tKeb0, 1.2rem);
}
._progress_11huc_h162d_5:not(#\9)::-webkit-progress-value{
  -webkit-transition:width 135ms linear;
  -webkit-transition:width var(--motion-duration-moderate-3V3IbE, 135ms) linear;
  transition:width 135ms linear;
  transition:width var(--motion-duration-moderate-3V3IbE, 135ms) linear;
}
@media (prefers-reduced-motion: reduce){
  ._progress_11huc_h162d_5:not(#\9)::-webkit-progress-value{
    animation:none;
    -webkit-transition:none;
    transition:none;
  }
}
.awsui-motion-disabled ._progress_11huc_h162d_5:not(#\9)::-webkit-progress-value{
  animation:none;
  -webkit-transition:none;
  transition:none;
}

._result-state_11huc_h162d_19:not(#\9){
  animation:_awsui-motion-fade-in_11huc_h162d_1 180ms ease-out;
  animation:_awsui-motion-fade-in_11huc_h162d_1 var(--motion-duration-transition-show-paced-2crOWV, 180ms) var(--motion-easing-transition-show-paced-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in_11huc_h162d_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._result-state_11huc_h162d_19:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._result-state_11huc_h162d_19:not(#\9){
  animation:none;
  transition:none;
}

._root_11huc_h162d_41:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._with-result-button_11huc_h162d_51:not(#\9){
  padding-right:1.6rem;
  padding-right:var(--space-m-25I9Cr, 1.6rem);
}

._result-button_11huc_h162d_63:not(#\9){
  display:inline-block;
  margin:0.4rem 0;
  margin:var(--space-scaled-xxs-wz9S8K, 0.4rem) 0;
}

._word-wrap_11huc_h162d_68:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._flash_11huc_h162d_74:not(#\9){
  color:inherit;
}

._progress-container_11huc_h162d_78:not(#\9){
  display:flex;
  align-items:center;
  max-width:800px;
  height:2.2rem;
  height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
}

._percentage-container_11huc_h162d_85:not(#\9){
  width:3.3rem;
  flex-shrink:0;
  white-space:nowrap;
  text-align:right;
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}

._progress_11huc_h162d_5:not(#\9){
  width:100%;
  margin-right:1.2rem;
  margin-right:var(--space-s-2tKeb0, 1.2rem);
  min-width:0;
  height:0.4rem;
  border:0 none;
  box-sizing:border-box;
  border-radius:10px;
  background-color:#eaeded;
  background-color:var(--color-background-progress-bar-layout-default-38loDV, #eaeded);
}
._progress_11huc_h162d_5:not(#\9)::-webkit-progress-bar{
  height:0.4rem;
  border:0 none;
  box-sizing:border-box;
  border-radius:10px;
  background-color:#eaeded;
  background-color:var(--color-background-progress-bar-layout-default-38loDV, #eaeded);
}
._progress_11huc_h162d_5:not(#\9)::-webkit-progress-value{
  border-radius:10px;
  background-color:#0073bb;
  background-color:var(--color-background-progress-bar-content-default-3uW6lA, #0073bb);
}
._progress_11huc_h162d_5:not(#\9)::-moz-progress-bar{
  border-radius:10px;
  background-color:#0073bb;
  background-color:var(--color-background-progress-bar-content-default-3uW6lA, #0073bb);
}
._progress_11huc_h162d_5:not(#\9)::-ms-fill{
  border-radius:10px;
  background-color:#0073bb;
  border:none;
}
._progress_11huc_h162d_5._progress-in-flash_11huc_h162d_128:not(#\9){
  background-color:rgba(255, 255, 255, 0.25);
  background-color:var(--color-background-progress-bar-layout-in-flash-2azuvH, rgba(255, 255, 255, 0.25));
}
._progress_11huc_h162d_5._progress-in-flash_11huc_h162d_128:not(#\9)::-webkit-progress-bar{
  background-color:rgba(255, 255, 255, 0.25);
  background-color:var(--color-background-progress-bar-layout-in-flash-2azuvH, rgba(255, 255, 255, 0.25));
}
._progress_11huc_h162d_5._progress-in-flash_11huc_h162d_128:not(#\9)::-webkit-progress-value{
  background-color:rgba(255, 255, 255, 0.7);
  background-color:var(--color-background-progress-bar-content-in-flash-1gkz93, rgba(255, 255, 255, 0.7));
}
._progress_11huc_h162d_5._progress-in-flash_11huc_h162d_128:not(#\9)::-moz-progress-bar{
  background-color:rgba(255, 255, 255, 0.7);
  background-color:var(--color-background-progress-bar-content-in-flash-1gkz93, rgba(255, 255, 255, 0.7));
}
._progress_11huc_h162d_5._progress-in-flash_11huc_h162d_128:not(#\9)::-ms-fill{
  background-color:rgba(255, 255, 255, 0.7);
}
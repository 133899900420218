._block-body-scroll_19d8l_5xden_3:not(#\9){
  overflow:hidden;
}

._mobile-bar_19d8l_5xden_7:not(#\9){
  position:-webkit-sticky;
  position:sticky;
  display:flex;
  align-items:center;
  flex-shrink:0;
  z-index:1000;
  width:100%;
  height:calc(2 * 1.6rem + 0.8rem);
  height:calc(2 * var(--space-m-25I9Cr, 1.6rem) + var(--space-scaled-xs-19adjU, 0.8rem));
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:var(--color-background-layout-panel-content-1Z_Abk, #ffffff);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
}

._mobile-bar-breadcrumbs_19d8l_5xden_20:not(#\9){
  min-width:0;
  flex:1;
  margin-left:1.6rem;
  margin-left:var(--space-m-25I9Cr, 1.6rem);
  margin-right:1.6rem;
  margin-right:var(--space-m-25I9Cr, 1.6rem);
}

._mobile-toggle_19d8l_5xden_27:not(#\9){
  box-sizing:border-box;
  width:4rem;
  padding:0.4rem 0.7rem;
  padding:var(--space-xxs-137Hig, 0.4rem) 0.7rem;
  cursor:pointer;
  z-index:1;
}
._mobile-toggle_19d8l_5xden_27:not(#\9):first-child{
  border-right:1px solid #d5dbdb;
  border-right:1px solid var(--color-border-layout-11nezK, #d5dbdb);
}
._mobile-toggle_19d8l_5xden_27:not(#\9):last-child{
  border-left:1px solid #d5dbdb;
  border-left:1px solid var(--color-border-layout-11nezK, #d5dbdb);
}
._mobile-toggle_19d8l_5xden_27:not(#\9):hover{
  background:#eaeded;
  background:var(--color-background-layout-panel-hover-38loDV, #eaeded);
}
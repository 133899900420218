._dropdown_qwoo0_hu9i3_3:not(#\9){
  animation:_awsui-motion-fade-in-dropdown_qwoo0_hu9i3_1 135ms ease-out;
  animation:_awsui-motion-fade-in-dropdown_qwoo0_hu9i3_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in-dropdown_qwoo0_hu9i3_1{
  from{
    opacity:0.4;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._dropdown_qwoo0_hu9i3_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._dropdown_qwoo0_hu9i3_3:not(#\9){
  animation:none;
  transition:none;
}

._root_qwoo0_hu9i3_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  white-space:inherit;
}
._root_qwoo0_hu9i3_25._interior_qwoo0_hu9i3_36:not(#\9){
  position:static;
  display:inline;
}
._root-inline_qwoo0_hu9i3_40:not(#\9){
  display:inline-block;
}
._root_qwoo0_hu9i3_25._stretch_qwoo0_hu9i3_43:not(#\9){
  width:100%;
}

._dropdown_qwoo0_hu9i3_3:not(#\9){
  position:absolute;
  display:none;
  flex-direction:row;
  z-index:2000;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
  background-color:#ffffff;
  background-color:var(--color-background-dropdown-item-default-3w9fMz, #ffffff);
  outline:none;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0;
  box-sizing:border-box;
  border-bottom:none;
}
@media screen and (-ms-high-contrast: active){
  ._dropdown_qwoo0_hu9i3_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none){
  ._dropdown_qwoo0_hu9i3_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
._dropdown-drop-up_qwoo0_hu9i3_71:not(#\9){
  box-shadow:0 -1px 1px 0 rgba(0, 28, 36, 0.3), 1px -1px 1px 0 rgba(0, 28, 36, 0.15), -1px -1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 -1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px -1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px -1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
}
._dropdown-drop-up_qwoo0_hu9i3_71._with-limited-width_qwoo0_hu9i3_74:not(#\9){
  border-top:none;
}
._dropdown-drop-left_qwoo0_hu9i3_77:not(#\9){
  right:0;
}
._dropdown-drop-right_qwoo0_hu9i3_80:not(#\9){
  left:0;
}
._dropdown_qwoo0_hu9i3_3._occupy-entire-width_qwoo0_hu9i3_83:not(#\9){
  min-width:100%;
}
._dropdown_qwoo0_hu9i3_3._hide-upper-border_qwoo0_hu9i3_86:not(#\9){
  border-top:none;
}
._dropdown_qwoo0_hu9i3_3._interior_qwoo0_hu9i3_36:not(#\9){
  margin-top:-1px;
}
._dropdown_qwoo0_hu9i3_3._open_qwoo0_hu9i3_92:not(#\9){
  display:flex;
}
._dropdown_qwoo0_hu9i3_3._open_qwoo0_hu9i3_92._nowrap_qwoo0_hu9i3_95:not(#\9){
  white-space:nowrap;
  overflow:scroll;
  display:block;
}

._ie11-wrapper_qwoo0_hu9i3_101:not(#\9){
  display:flex;
  flex-direction:column;
  width:100%;
}
._root_l0dv0_1imfv_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._header_l0dv0_1imfv_16:not(#\9){
  padding:2rem 3.2rem;
  padding:var(--space-scaled-l-17GFGI, 2rem) var(--space-xxl-3_q1V_, 3.2rem);
  padding-right:calc(3.2rem + 2.4rem);
  padding-right:calc(var(--space-scaled-xxl-238u6Z, 3.2rem) + var(--space-xl-3JXLlv, 2.4rem));
}

._header-link_l0dv0_1imfv_21:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._list_l0dv0_1imfv_28:not(#\9){
  margin:0;
  padding:0;
  padding-left:2rem;
  padding-left:var(--space-l-3cXvov, 2rem);
}

._list-variant-root_l0dv0_1imfv_34:not(#\9){
  margin:0;
  margin-bottom:4rem;
  margin-bottom:var(--space-scaled-xxxl-3VX7k3, 4rem);
  padding:0 3.2rem 0 3.2rem;
  padding:0 var(--space-scaled-xxl-238u6Z, 3.2rem) 0 var(--space-xxl-3_q1V_, 3.2rem);
}

._list-variant-expandable-link-group_l0dv0_1imfv_40:not(#\9){
  padding-left:4rem;
  padding-left:var(--space-xxxl-3dxeuJ, 4rem);
}

._list-item_l0dv0_1imfv_44:not(#\9){
  margin:0.8rem 0;
  margin:var(--space-scaled-xs-19adjU, 0.8rem) 0;
  padding:0;
  list-style:none;
}

._section_l0dv0_1imfv_50:not(#\9),
._expandable-link-group_l0dv0_1imfv_51:not(#\9){
  margin-left:calc(-1 * 2rem);
  margin-left:calc(-1 * var(--space-l-3cXvov, 2rem));
}

._section_l0dv0_1imfv_50:not(#\9){
  margin-top:2rem;
  margin-top:var(--space-scaled-2x-l-17GFGI, 2rem);
  margin-bottom:2rem;
  margin-bottom:var(--space-scaled-2x-l-17GFGI, 2rem);
}
._section_l0dv0_1imfv_50 > *:not(#\9){
  padding:0;
}

._section-header_l0dv0_1imfv_63:not(#\9){
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._link_l0dv0_1imfv_68:not(#\9){
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}

._link-active_l0dv0_1imfv_73:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
}

._header-link_l0dv0_1imfv_21:not(#\9),
._link_l0dv0_1imfv_68:not(#\9){
  text-decoration:none;
}
._header-link_l0dv0_1imfv_21:not(#\9):hover,
._link_l0dv0_1imfv_68:not(#\9):hover{
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
}
._header-link_l0dv0_1imfv_21:not(#\9):focus,
._link_l0dv0_1imfv_68:not(#\9):focus{
  outline:none;
}
._header-link_l0dv0_1imfv_21:not(#\9):hover, ._header-link_l0dv0_1imfv_21:not(#\9):focus, ._link_l0dv0_1imfv_68:not(#\9):hover, ._link_l0dv0_1imfv_68:not(#\9):focus{
  text-decoration:none;
}
._header-link_l0dv0_1imfv_21[data-awsui-focus-visible=true]:not(#\9):focus,
._link_l0dv0_1imfv_68[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}

._info_l0dv0_1imfv_103:not(#\9){
  margin-left:0.8rem;
  margin-left:var(--space-xs-1QR0Ng, 0.8rem);
}

._external-icon_l0dv0_1imfv_107:not(#\9){
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}

._divider_l0dv0_1imfv_111:not(#\9){
  border:none;
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  margin-bottom:2rem;
  margin-bottom:var(--space-scaled-l-17GFGI, 2rem);
}

._divider-default_l0dv0_1imfv_117:not(#\9){
  margin:2.4rem calc(-1 * 1.2rem);
  margin:var(--space-scaled-2x-xl-axUbkA, 2.4rem) calc(-1 * var(--space-s-2tKeb0, 1.2rem));
}

._divider-header_l0dv0_1imfv_121:not(#\9){
  margin-top:-1px;
}
._root_dm8gx_1fxhn_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  padding-top:0.8rem;
  padding-top:var(--space-xs-1QR0Ng, 0.8rem);
}

._token_dm8gx_1fxhn_14:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  padding:0.4rem 0.4rem 0.4rem 0.8rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem) var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xs-1QR0Ng, 0.8rem);
  display:flex;
  align-items:flex-start;
  background:#f1faff;
  background:var(--color-background-item-selected-WkeJRF, #f1faff);
  border-radius:2px;
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  height:100%;
  box-sizing:border-box;
}

._dismiss-button_dm8gx_1fxhn_26:not(#\9){
  margin:calc(-1 * 1px) calc(-1 * 1px) 0 0.4rem;
  margin:calc(-1 * 1px) calc(-1 * 1px) 0 var(--space-xxs-137Hig, 0.4rem);
  border:1px solid transparent;
  padding:0 0.4rem;
  padding:0 var(--space-xxs-137Hig, 0.4rem);
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  background-color:transparent;
}
._dismiss-button_dm8gx_1fxhn_26[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._dismiss-button_dm8gx_1fxhn_26[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(0px - 1px);
}
._dismiss-button_dm8gx_1fxhn_26[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:0px;
  top:0px;
  width:calc(100% + 2 * 0px);
  height:calc(100% + 2 * 0px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._dismiss-button_dm8gx_1fxhn_26:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._dismiss-button_dm8gx_1fxhn_26:not(#\9):hover{
  cursor:pointer;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}

._token-disabled_dm8gx_1fxhn_60:not(#\9){
  border-color:#d5dbdb;
  border-color:var(--color-border-control-disabled-Rx1zFC, #d5dbdb);
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  color:#aab7b8;
  color:var(--color-text-disabled-TVd7uP, #aab7b8);
  pointer-events:none;
}
._token-disabled_dm8gx_1fxhn_60 > ._dismiss-button_dm8gx_1fxhn_26:not(#\9){
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._token-disabled_dm8gx_1fxhn_60 > ._dismiss-button_dm8gx_1fxhn_26:not(#\9):hover{
  cursor:auto;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._selectable-item_15o6u_ptaaj_3:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  list-style:none;
  border:1px solid transparent;
  border-top-color:#eaeded;
  border-top-color:var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  border-bottom-color:#eaeded;
  border-bottom-color:var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  background-color:#ffffff;
  background-color:var(--color-background-dropdown-item-default-3w9fMz, #ffffff);
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
  border-left-width:0;
  border-right-width:0;
  padding:0.4rem calc(0.8rem + 1px);
  padding:var(--space-xxs-137Hig, 0.4rem) calc(var(--space-xs-1QR0Ng, 0.8rem) + 1px);
}
._selectable-item_15o6u_ptaaj_3._pad-bottom_15o6u_ptaaj_20:not(#\9){
  padding-bottom:calc(0.4rem + 0.2rem);
  padding-bottom:calc(var(--space-xxs-137Hig, 0.4rem) + var(--space-xxxs-10EX_Z, 0.2rem));
  border-bottom-color:transparent;
}
._selectable-item_15o6u_ptaaj_3:not(#\9):not(:first-child){
  margin-top:-1px;
}
._selectable-item_15o6u_ptaaj_3._has-background_15o6u_ptaaj_27:not(#\9){
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
}
._selectable-item_15o6u_ptaaj_3._highlighted_15o6u_ptaaj_30:not(#\9), ._selectable-item_15o6u_ptaaj_3._selected_15o6u_ptaaj_30:not(#\9){
  color:#16191f;
  color:var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f);
  border-left-width:1px;
  border-right-width:1px;
  padding-left:0.8rem;
  padding-left:var(--space-xs-1QR0Ng, 0.8rem);
  padding-right:0.8rem;
  padding-right:var(--space-xs-1QR0Ng, 0.8rem);
}
._selectable-item_15o6u_ptaaj_3._highlighted_15o6u_ptaaj_30:not(#\9){
  z-index:1;
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
  border-color:#879596;
  border-color:var(--color-border-dropdown-item-hover-3Saafg, #879596);
}
._selectable-item_15o6u_ptaaj_3._selected_15o6u_ptaaj_30:not(#\9){
  z-index:2;
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-selected-nrt43a, #f1faff);
  border-color:#00a1c9;
  border-color:var(--color-border-dropdown-item-selected-1BqG1q, #00a1c9);
}
._selectable-item_15o6u_ptaaj_3._highlighted_15o6u_ptaaj_30._selected_15o6u_ptaaj_30._is-keyboard_15o6u_ptaaj_47:not(#\9){
  box-shadow:inset 0 0 0 1px #00a1c9;
  box-shadow:inset 0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._selectable-item_15o6u_ptaaj_3._parent_15o6u_ptaaj_50:not(#\9){
  font-weight:bold;
  color:#545b64;
  color:var(--color-text-dropdown-group-label-2XOlKp, #545b64);
  border-top-color:#eaeded;
  border-top-color:var(--color-border-dropdown-group-PaVVo1, #eaeded);
  padding:0.8rem;
  padding:var(--space-xs-1QR0Ng, 0.8rem);
}
._selectable-item_15o6u_ptaaj_3._child_15o6u_ptaaj_56:not(#\9){
  padding-left:calc(3.2rem + 1px);
  padding-left:calc(var(--space-xxl-3_q1V_, 3.2rem) + 1px);
}
._selectable-item_15o6u_ptaaj_3._child_15o6u_ptaaj_56._highlighted_15o6u_ptaaj_30:not(#\9), ._selectable-item_15o6u_ptaaj_3._child_15o6u_ptaaj_56._selected_15o6u_ptaaj_30:not(#\9){
  padding-left:3.2rem;
  padding-left:var(--space-xxl-3_q1V_, 3.2rem);
}
._selectable-item_15o6u_ptaaj_3._disabled_15o6u_ptaaj_62:not(#\9){
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
}
._selectable-item_15o6u_ptaaj_3:not(#\9):not(._disabled_15o6u_ptaaj_62):not(._parent_15o6u_ptaaj_50){
  cursor:pointer;
}
._selectable-item_15o6u_ptaaj_3._virtual_15o6u_ptaaj_68:not(#\9){
  position:absolute;
  top:0;
  left:0;
  width:100%;
  box-sizing:border-box;
}

._measure-strut_15o6u_ptaaj_76:not(#\9){
  position:absolute;
  pointer-events:none;
  height:100%;
  width:100%;
  top:0;
  left:0;
  padding-top:1px;
}
._measure-strut-first_15o6u_ptaaj_85:not(#\9){
  padding-bottom:1px;
}
._root_1fn7j_glnlb_3:not(#\9){
  position:absolute;
  width:100%;
  height:100%;
  top:0;
  left:0;
}
._root_1fn7j_glnlb_3 > ._styled-box_1fn7j_glnlb_10:not(#\9){
  fill:#ffffff;
  fill:var(--color-background-control-default-528DLz, #ffffff);
  stroke:#aab7b8;
  stroke:var(--color-border-control-default-27WVST, #aab7b8);
  stroke-width:1;
  transition:fill 90ms linear, stroke 90ms linear;
  transition:fill var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear), stroke var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
._root_1fn7j_glnlb_3 > ._styled-box-checked_1fn7j_glnlb_16:not(#\9), ._root_1fn7j_glnlb_3 > ._styled-box-indeterminate_1fn7j_glnlb_16:not(#\9){
  fill:#0073bb;
  fill:var(--color-background-control-checked-3uW6lA, #0073bb);
  stroke:#0073bb;
  stroke:var(--color-border-control-checked-3JzNc6, #0073bb);
}
._root_1fn7j_glnlb_3 > ._styled-box-disabled_1fn7j_glnlb_20:not(#\9){
  fill:#d5dbdb;
  fill:var(--color-background-control-disabled-11nezK, #d5dbdb);
  stroke:#d5dbdb;
  stroke:var(--color-border-control-disabled-Rx1zFC, #d5dbdb);
}
@media (prefers-reduced-motion: reduce){
  ._root_1fn7j_glnlb_3 > ._styled-box_1fn7j_glnlb_10:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._root_1fn7j_glnlb_3 > ._styled-box_1fn7j_glnlb_10:not(#\9){
  animation:none;
  transition:none;
}
._root_1fn7j_glnlb_3 > ._styled-line_1fn7j_glnlb_34:not(#\9){
  stroke:#ffffff;
  stroke:var(--color-foreground-control-default-3g5IJp, #ffffff);
  stroke-width:2;
  fill:none;
}
._root_1fn7j_glnlb_3 > ._styled-line-disabled_1fn7j_glnlb_39:not(#\9){
  stroke:#ffffff;
  stroke:var(--color-foreground-control-disabled-3d6ker, #ffffff);
}
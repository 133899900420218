._root_k2y2q_rjvin_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._checkbox-control_k2y2q_rjvin_14:not(#\9){
  margin-top:calc((2.2rem - 1.4rem) / 2);
  margin-top:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - 1.4rem) / 2);
  min-height:1.4rem;
  min-width:1.4rem;
  height:1.4rem;
  width:1.4rem;
}

._input_k2y2q_rjvin_22[data-awsui-focus-visible=true]:not(#\9):focus + div{
  position:relative;
}
._input_k2y2q_rjvin_22[data-awsui-focus-visible=true]:not(#\9):focus + div{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._input_k2y2q_rjvin_22[data-awsui-focus-visible=true]:not(#\9):focus + div::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._header_16mm3_lbwti_3:not(#\9){
  position:relative;
  margin:0;
  color:#545b64;
  color:var(--color-text-dropdown-group-label-2XOlKp, #545b64);
  border:1px solid transparent;
  font-weight:bold;
  display:flex;
  justify-content:space-between;
  padding:0.4rem 2rem;
  padding:var(--space-xxs-137Hig, 0.4rem) var(--space-l-3cXvov, 2rem);
  z-index:1;
}
._header_16mm3_lbwti_3._disabled_16mm3_lbwti_14:not(#\9){
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
}
._header_16mm3_lbwti_3._expandable-header_16mm3_lbwti_17:not(#\9){
  border-top-color:#eaeded;
  border-top-color:var(--color-border-dropdown-group-PaVVo1, #eaeded);
  border-bottom-color:#eaeded;
  border-bottom-color:var(--color-border-dropdown-group-PaVVo1, #eaeded);
}
._header_16mm3_lbwti_3._expandable-header_16mm3_lbwti_17:not(#\9):focus{
  outline:none;
}
._header_16mm3_lbwti_3._expandable-header_16mm3_lbwti_17._rolled-down_16mm3_lbwti_24:not(#\9){
  border-bottom-color:transparent;
}
._header_16mm3_lbwti_3._expandable-header_16mm3_lbwti_17._highlighted_16mm3_lbwti_27:not(#\9), ._header_16mm3_lbwti_3._expandable-header_16mm3_lbwti_17:not(#\9):not(._disabled_16mm3_lbwti_14):hover{
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
  color:#16191f;
  color:var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f);
  border-color:#879596;
  border-color:var(--color-border-dropdown-item-hover-3Saafg, #879596);
  cursor:pointer;
  z-index:2;
}

._category_16mm3_lbwti_35:not(#\9){
  list-style:none;
  margin-top:-1px;
  padding:0;
}
._category_16mm3_lbwti_35:not(#\9):first-child{
  margin-top:0;
}
._category_16mm3_lbwti_35._expandable_16mm3_lbwti_17:not(#\9){
  border-top:0;
}
._category_16mm3_lbwti_35:not(#\9):last-child{
  border-bottom:none;
}

._expand-icon_16mm3_lbwti_50:not(#\9){
  position:relative;
  left:1.2rem;
  left:var(--space-s-2tKeb0, 1.2rem);
  width:1.6rem;
  width:var(--space-m-25I9Cr, 1.6rem);
  display:inline-block;
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
._expand-icon-up_16mm3_lbwti_57:not(#\9){
  transform:rotate(-180deg);
}
._expand-icon-right_16mm3_lbwti_60:not(#\9){
  transform:rotate(-90deg);
}
@media (prefers-reduced-motion: reduce){
  ._expand-icon_16mm3_lbwti_50:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._expand-icon_16mm3_lbwti_50:not(#\9){
  animation:none;
  transition:none;
}

._items-list-container_16mm3_lbwti_74:not(#\9){
  padding:0;
  margin:-1px 0 0 0;
  overflow-y:auto;
}
._series_1yz9c_13aag_3:not(#\9),
._series--bar_1yz9c_13aag_4 > rect:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._series_1yz9c_13aag_3:not(#\9),
._series--bar_1yz9c_13aag_4 > rect:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._series_1yz9c_13aag_3:not(#\9),
.awsui-motion-disabled ._series--bar_1yz9c_13aag_4 > rect:not(#\9){
  animation:none;
  transition:none;
}

._root_1yz9c_13aag_20:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  display:block;
}

._has-default-filter_1yz9c_13aag_36 > :not(#\9):first-child{
  flex:280px 0 1;
}

._chart-container_1yz9c_13aag_40:not(#\9){
  display:flex;
  width:100%;
  flex-direction:column;
}

._chart-container__vertical_1yz9c_13aag_46:not(#\9){
  display:flex;
  flex-direction:column;
  width:100%;
}

._chart-container__horizontal_1yz9c_13aag_52:not(#\9){
  display:flex;
}

._content_1yz9c_13aag_56:not(#\9){
  display:flex;
  position:relative;
  justify-content:center;
  align-items:center;
}

._content--reserve-filter_1yz9c_13aag_63:not(#\9){
  margin-top:calc(2 * 2.2rem);
  margin-top:calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem));
}

._content--reserve-legend_1yz9c_13aag_67:not(#\9){
  margin-bottom:calc(2 * 2.2rem);
  margin-bottom:calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem));
}

._svg_1yz9c_13aag_71:not(#\9){
  shape-rendering:auto;
  overflow:visible;
  display:flex;
  width:100%;
  max-width:100%;
}

._chart--clickable_1yz9c_13aag_79:not(#\9){
  cursor:pointer;
}

._axis_1yz9c_13aag_83:not(#\9), ._vertical-marker_1yz9c_13aag_83:not(#\9){
  stroke:#d5dbdb;
  stroke:var(--color-charts-line-axis-11nezK, #d5dbdb);
  stroke-width:1px;
}

._axis--emphasized_1yz9c_13aag_88:not(#\9){
  stroke-width:2px;
}

._vertical-marker_1yz9c_13aag_83:not(#\9){
  pointer-events:none;
}

._vertical-marker-circle_1yz9c_13aag_96:not(#\9){
  fill:#ffffff;
  fill:var(--color-background-container-content-3w9fMz, #ffffff);
  pointer-events:none;
  stroke-width:2px;
}

._vertical-marker-circle-active_1yz9c_13aag_102:not(#\9){
  cursor:pointer;
  stroke-width:2px;
}

._axis-label_1yz9c_13aag_107:not(#\9){
  font-weight:bold;
  fill:#16191f;
  fill:var(--color-text-body-default-mcmuEw, #16191f);
}

._grid_1yz9c_13aag_117:not(#\9){
  stroke:#d5dbdb;
  stroke:var(--color-charts-line-grid-11nezK, #d5dbdb);
  stroke-width:1px;
}

._ticks_1yz9c_13aag_122 > line:not(#\9){
  stroke:#d5dbdb;
  stroke:var(--color-charts-line-tick-11nezK, #d5dbdb);
  stroke-width:1px;
}
._ticks_1yz9c_13aag_122 > text:not(#\9){
  fill:#545b64;
  fill:var(--color-text-body-secondary-1oBHNI, #545b64);
}

._ticks--hidden_1yz9c_13aag_135:not(#\9){
  opacity:0;
}

._ticks--bottom_1yz9c_13aag_139 > text:not(#\9){
  text-anchor:middle;
  dominant-baseline:hanging;
}

._labels-left_1yz9c_13aag_144 > ._ticks_1yz9c_13aag_122 > text:not(#\9){
  text-anchor:end;
  dominant-baseline:central;
}

._series_1yz9c_13aag_3:not(#\9){
  display:block;
  stroke-width:2px;
  fill:none;
  stroke-linecap:round;
}

._series--focusable_1yz9c_13aag_161:not(#\9){
  outline:none;
}

._series--dimmed_1yz9c_13aag_165:not(#\9){
  opacity:0.3;
}

._series--bar_1yz9c_13aag_4 > rect:not(#\9){
  stroke:#ffffff;
  stroke:var(--color-background-container-content-3w9fMz, #ffffff);
  stroke-width:2px;
}

._series--threshold_1yz9c_13aag_174:not(#\9){
  stroke-dasharray:3 5;
}

._bar-groups__group_1yz9c_13aag_178:not(#\9){
  display:block;
  fill:none;
  pointer-events:all;
  cursor:pointer;
  outline:none;
}
._bar-groups__group_1yz9c_13aag_178[data-awsui-focus-visible=true]:not(#\9):focus{
  stroke:#00a1c9;
  stroke:var(--color-border-item-focused-2uP71z, #00a1c9);
  stroke-width:2px;
}

._bar-groups--pinned_1yz9c_13aag_190 > ._bar-groups__group_1yz9c_13aag_178:not(#\9){
  pointer-events:none;
}

._focus-outline_1yz9c_13aag_194:not(#\9){
  opacity:0;
  outline:none;
  pointer-events:none;
  fill:none;
  stroke:#00a1c9;
  stroke:var(--color-border-item-focused-2uP71z, #00a1c9);
  stroke-width:2px;
}
._focus-outline_1yz9c_13aag_194._focus-outline--active_1yz9c_13aag_202[data-awsui-focus-visible=true]:not(#\9){
  opacity:1;
}

._labels-left_1yz9c_13aag_144:not(#\9){
  position:relative;
  margin-right:12px;
}
._labels-left_1yz9c_13aag_144 > ._axis-label_1yz9c_13aag_107:not(#\9){
  position:absolute;
  visibility:visible;
  white-space:nowrap;
}

._labels-left__label_1yz9c_13aag_216:not(#\9){
  position:absolute;
}

._labels-left--hidden_1yz9c_13aag_220:not(#\9){
  visibility:hidden;
}
._labels-left--hidden_1yz9c_13aag_220 > ._labels-left__label_1yz9c_13aag_216:not(#\9){
  position:relative;
  white-space:nowrap;
}

._labels-bottom_1yz9c_13aag_228:not(#\9){
  position:relative;
  display:block;
  width:100%;
  overflow:visible;
}
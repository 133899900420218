._visible-content-title_tc96w_3z0ic_12:not(#\9){
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  margin-bottom:2rem;
  margin-bottom:var(--space-scaled-l-17GFGI, 2rem);
}

._visible-content-group-label_tc96w_3z0ic_19:not(#\9){
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
  padding-bottom:0.8rem;
  padding-bottom:var(--space-xs-1QR0Ng, 0.8rem);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._visible-content-option_tc96w_3z0ic_25:not(#\9){
  display:flex;
  flex-wrap:nowrap;
  justify-content:space-between;
  padding:0.8rem 0px 0.8rem 2rem;
  padding:var(--space-xs-1QR0Ng, 0.8rem) 0px var(--space-xs-1QR0Ng, 0.8rem) var(--space-scaled-l-17GFGI, 2rem);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._visible-content-option_tc96w_3z0ic_25:not(#\9):last-child{
  border-bottom:none;
}

._visible-content-option-label_tc96w_3z0ic_36:not(#\9){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
  padding-right:2rem;
  padding-right:var(--space-l-3cXvov, 2rem);
}

._second-column-small_tc96w_3z0ic_52:not(#\9){
  padding-top:calc(2 * 2rem);
  padding-top:calc(2 * var(--space-scaled-l-17GFGI, 2rem));
}
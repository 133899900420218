._resize-active_x7peu_1d4ww_3 *:not(#\9){
  cursor:col-resize;
  -webkit-user-select:none;
      -ms-user-select:none;
          user-select:none;
}

._resizer_x7peu_1d4ww_8:not(#\9){
  bottom:0;
  cursor:col-resize;
  position:absolute;
  right:calc(-1 * 2rem / 2);
  right:calc(-1 * var(--space-l-3cXvov, 2rem) / 2);
  top:0;
  width:2rem;
  width:var(--space-l-3cXvov, 2rem);
  z-index:10;
}
._resize-active_x7peu_1d4ww_3 ._resizer_x7peu_1d4ww_8:not(#\9){
  pointer-events:none;
}
th:not(#\9):last-child > ._resizer_x7peu_1d4ww_8{
  width:calc(2rem / 2);
  width:calc(var(--space-l-3cXvov, 2rem) / 2);
  right:0;
}
th:not(#\9):not(:last-child) > ._resizer_x7peu_1d4ww_8:hover::before, th:not(#\9):not(:last-child) > ._resizer-active_x7peu_1d4ww_24::before{
  content:"";
  position:absolute;
  left:calc(2rem / 2 - 2px);
  left:calc(var(--space-l-3cXvov, 2rem) / 2 - 2px);
  bottom:25%;
  height:50%;
  box-sizing:border-box;
  border-left:2px solid #879596;
  border-left:2px solid var(--color-border-divider-active-3Saafg, #879596);
}

._tracker_x7peu_1d4ww_34:not(#\9){
  display:none;
  position:absolute;
  border-left:1px dashed #879596;
  border-left:1px dashed var(--color-border-divider-active-3Saafg, #879596);
  width:0;
  top:0;
  bottom:0;
}
._resize-active_x7peu_1d4ww_3 ._tracker_x7peu_1d4ww_34:not(#\9){
  display:block;
}
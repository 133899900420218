._toggle_1rbf8_8fba5_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:center;
  background-color:transparent;
  border:1px solid transparent;
  margin-top:0.8rem;
  margin-top:var(--space-scaled-xs-19adjU, 0.8rem);
  padding:0;
  margin-left:-1px;
}
._toggle_1rbf8_8fba5_3:not(#\9):hover{
  cursor:pointer;
}
._toggle_1rbf8_8fba5_3:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._toggle_1rbf8_8fba5_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._toggle_1rbf8_8fba5_3 > ._description_1rbf8_8fba5_32:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}
._label_1ut8b_1uegz_7:not(#\9){
  display:flex;
  align-items:center;
  justify-content:center;
  position:absolute;
  padding-right:0.8rem;
  padding-right:var(--space-xs-1QR0Ng, 0.8rem);
  padding-left:0.8rem;
  padding-left:var(--space-xs-1QR0Ng, 0.8rem);
  padding-bottom:0.4rem;
  padding-bottom:var(--space-xxs-137Hig, 0.4rem);
  height:100%;
  top:0;
  width:100%;
  left:0;
  box-sizing:border-box;
  border-right:0.1rem solid transparent;
}

._stud_1ut8b_1uegz_23:not(#\9){
  visibility:hidden;
}
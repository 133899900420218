._content-enter_gwq0h_ccur0_3:not(#\9){
  animation:_awsui-motion-fade-in_gwq0h_ccur0_1 180ms ease-out;
  animation:_awsui-motion-fade-in_gwq0h_ccur0_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in_gwq0h_ccur0_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._content-enter_gwq0h_ccur0_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._content-enter_gwq0h_ccur0_3:not(#\9){
  animation:none;
  transition:none;
}

._trigger-expanded_gwq0h_ccur0_25:not(#\9){
  transition:border-bottom-color 180ms ease-out;
  transition:border-bottom-color var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@media (prefers-reduced-motion: reduce){
  ._trigger-expanded_gwq0h_ccur0_25:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._trigger-expanded_gwq0h_ccur0_25:not(#\9){
  animation:none;
  transition:none;
}

._icon_gwq0h_ccur0_39:not(#\9){
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform var(--motion-duration-rotate-90-TMbCmo, 135ms) var(--motion-easing-rotate-90-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._icon_gwq0h_ccur0_39:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._icon_gwq0h_ccur0_39:not(#\9){
  animation:none;
  transition:none;
}

._root_gwq0h_ccur0_53:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:block;
}

._icon_gwq0h_ccur0_39:not(#\9){
  transform:rotate(-90deg);
}
._icon_gwq0h_ccur0_39._expanded_gwq0h_ccur0_70:not(#\9){
  transform:rotate(0deg);
}

._icon-container_gwq0h_ccur0_74:not(#\9){
  position:relative;
  margin-left:calc((2.2rem - 1.6rem) / -2);
  margin-left:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - var(--size-icon-normal-2nJwAn, 1.6rem)) / -2);
  margin-right:calc(0.4rem + 1px);
  margin-right:calc(var(--space-xxs-137Hig, 0.4rem) + 1px);
}

._trigger_gwq0h_ccur0_25:not(#\9){
  cursor:pointer;
  box-sizing:border-box;
  display:flex;
  border:none;
  width:100%;
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  text-align:left;
}
._trigger-default_gwq0h_ccur0_89:not(#\9){
  padding:0.4rem 0.4rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem);
}
._trigger-footer_gwq0h_ccur0_92:not(#\9){
  padding:0.4rem 0;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) 0;
}
._trigger-default_gwq0h_ccur0_89:not(#\9), ._trigger-navigation_gwq0h_ccur0_95:not(#\9), ._trigger-footer_gwq0h_ccur0_92:not(#\9){
  border:1px solid transparent;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}
._trigger-default_gwq0h_ccur0_89:not(#\9):hover, ._trigger-navigation_gwq0h_ccur0_95:not(#\9):hover, ._trigger-footer_gwq0h_ccur0_92:not(#\9):hover{
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._trigger-container_gwq0h_ccur0_103:not(#\9){
  padding:1.2rem 2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-l-3cXvov, 2rem);
}
._trigger-container_gwq0h_ccur0_103[data-awsui-focus-visible=true]:not(#\9):focus{
  padding:calc(1.2rem - 1px) calc(2rem - 1px);
  padding:calc(var(--space-scaled-s-1sAHT4, 1.2rem) - 1px) calc(var(--space-l-3cXvov, 2rem) - 1px);
}
._trigger-default_gwq0h_ccur0_89._trigger-expanded_gwq0h_ccur0_25:not(#\9){
  border-bottom-color:#eaeded;
  border-bottom-color:var(--color-border-divider-default-38loDV, #eaeded);
}

._header_gwq0h_ccur0_113:not(#\9){
  display:flex;
}
._header-container_gwq0h_ccur0_116:not(#\9){
  width:100%;
}
._header-container_gwq0h_ccur0_116 > ._icon-container_gwq0h_ccur0_74:not(#\9){
  margin-top:0.8rem;
  margin-top:var(--space-scaled-xs-19adjU, 0.8rem);
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
}
._header-navigation_gwq0h_ccur0_123 > ._icon-container_gwq0h_ccur0_74:not(#\9){
  display:inline-flex;
  cursor:pointer;
  color:#879596;
  color:var(--color-text-icon-caret-BxOb2m, #879596);
  border:0;
  padding:0;
  background:transparent;
  outline:none;
  text-decoration:none;
  flex-direction:column;
}
._header-navigation_gwq0h_ccur0_123 > ._icon-container_gwq0h_ccur0_74:not(#\9):hover{
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._header-navigation_gwq0h_ccur0_123 > ._icon-container_gwq0h_ccur0_74[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._header-navigation_gwq0h_ccur0_123 > ._icon-container_gwq0h_ccur0_74[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._header-navigation_gwq0h_ccur0_123 > ._icon-container_gwq0h_ccur0_74[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._content_gwq0h_ccur0_3:not(#\9){
  display:none;
}
._content-default_gwq0h_ccur0_159:not(#\9){
  padding:0.8rem 0;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) 0;
}
._content-footer_gwq0h_ccur0_162:not(#\9){
  padding:0.8rem 0;
  padding:var(--space-xs-1QR0Ng, 0.8rem) 0;
}
._content-expanded_gwq0h_ccur0_165:not(#\9){
  display:block;
}

._focusable_gwq0h_ccur0_169:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._focusable_gwq0h_ccur0_169[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._link_4c84z_747vo_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  text-decoration:none;
  display:inline;
  white-space:inherit;
}
._link_4c84z_747vo_3:not(#\9):hover{
  cursor:pointer;
}
._link_4c84z_747vo_3:not(#\9):focus{
  outline:none;
}
._link_4c84z_747vo_3:not(#\9):active, ._link_4c84z_747vo_3:not(#\9):focus, ._link_4c84z_747vo_3:not(#\9):hover{
  text-decoration:underline;
}
._link_4c84z_747vo_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:thin dotted;
  outline:5px auto -webkit-focus-ring-color;
  outline-offset:2px;
  outline-color:#00a1c9;
  outline-color:var(--color-border-item-focused-2uP71z, #00a1c9);
}
._link_4c84z_747vo_3._weight-bold_4c84z_747vo_31:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  letter-spacing:0.01em;
}
._link_4c84z_747vo_3._weight-normal_4c84z_747vo_35:not(#\9){
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  letter-spacing:normal;
}
._link_4c84z_747vo_3._color-inverted_4c84z_747vo_39:not(#\9){
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
  text-decoration:underline;
}
._link_4c84z_747vo_3._font-size-body-s_4c84z_747vo_43:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}
._link_4c84z_747vo_3._font-size-body-m_4c84z_747vo_47:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
}
._link_4c84z_747vo_3._font-size-heading-xs_4c84z_747vo_51:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._link_4c84z_747vo_3._font-size-heading-s_4c84z_747vo_55:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._link_4c84z_747vo_3._font-size-heading-m_4c84z_747vo_59:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._link_4c84z_747vo_3._font-size-heading-l_4c84z_747vo_63:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._link_4c84z_747vo_3._font-size-heading-xl_4c84z_747vo_67:not(#\9){
  font-size:2.8rem;
  font-size:var(--size-font-heading-500-14QHat, 2.8rem);
  line-height:3.6rem;
  line-height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
}
._link_4c84z_747vo_3._font-size-display-l_4c84z_747vo_71:not(#\9){
  font-size:4.4rem;
  font-size:var(--size-font-display-200-AStMo2, 4.4rem);
  line-height:5.6rem;
  line-height:var(--size-lineHeight-display-200-2-t5qR, 5.6rem);
}

._icon_4c84z_747vo_76:not(#\9){
  display:inline-block;
}
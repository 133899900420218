@keyframes _spinner-rotator_1612d_44z7j_1{
  0%{
    transform:rotate(0deg);
  }
  100%{
    transform:rotate(360deg);
  }
}
@keyframes _spinner-line-left_1612d_44z7j_1{
  0%{
    transform:rotate(0deg);
  }
  50%{
    transform:rotate(120deg);
  }
  100%{
    transform:rotate(0deg);
  }
}
@keyframes _spinner-line-right_1612d_44z7j_1{
  0%{
    transform:rotate(90deg);
  }
  50%{
    transform:rotate(-30deg);
  }
  100%{
    transform:rotate(90deg);
  }
}
._root_1612d_44z7j_33:not(#\9){
  display:inline-block;
  vertical-align:top;
  animation:_spinner-rotator_1612d_44z7j_1 0.7s linear infinite;
  box-sizing:border-box;
  line-height:0;
}
._root_1612d_44z7j_33._size-normal_1612d_44z7j_41:not(#\9){
  width:1.6rem;
  width:var(--size-icon-normal-2nJwAn, 1.6rem);
  height:1.6rem;
  height:var(--size-icon-normal-2nJwAn, 1.6rem);
  padding:calc((1.6rem - 1.2rem) / 2);
  padding:calc((var(--size-icon-normal-2nJwAn, 1.6rem) - 1.2rem) / 2);
  margin-top:calc((2.2rem - 1.6rem) / 2);
  margin-top:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - var(--size-icon-normal-2nJwAn, 1.6rem)) / 2);
  margin-bottom:calc((2.2rem - 1.6rem) / 2);
  margin-bottom:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - var(--size-icon-normal-2nJwAn, 1.6rem)) / 2);
  box-sizing:border-box;
}
._root_1612d_44z7j_33._size-big_1612d_44z7j_49:not(#\9){
  width:3.2rem;
  width:var(--size-icon-big-3huAay, 3.2rem);
  height:3.2rem;
  height:var(--size-icon-big-3huAay, 3.2rem);
  padding:calc((3.2rem - 2.4rem) / 2);
  padding:calc((var(--size-icon-big-3huAay, 3.2rem) - 2.4rem) / 2);
  margin-top:calc((3.6rem - 3.2rem) / 2);
  margin-top:calc((var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem) - var(--size-icon-big-3huAay, 3.2rem)) / 2);
  margin-bottom:calc((3.6rem - 3.2rem) / 2);
  margin-bottom:calc((var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem) - var(--size-icon-big-3huAay, 3.2rem)) / 2);
  box-sizing:border-box;
}
._root_1612d_44z7j_33._size-large_1612d_44z7j_57:not(#\9){
  width:4.8rem;
  width:var(--size-icon-large-OupcJw, 4.8rem);
  height:4.8rem;
  height:var(--size-icon-large-OupcJw, 4.8rem);
  padding:calc((4.8rem - 3.6rem) / 2);
  padding:calc((var(--size-icon-large-OupcJw, 4.8rem) - 3.6rem) / 2);
  margin-top:calc((5.6rem - 4.8rem) / 2);
  margin-top:calc((var(--size-lineHeight-display-200-2-t5qR, 5.6rem) - var(--size-icon-large-OupcJw, 4.8rem)) / 2);
  margin-bottom:calc((5.6rem - 4.8rem) / 2);
  margin-bottom:calc((var(--size-lineHeight-display-200-2-t5qR, 5.6rem) - var(--size-icon-large-OupcJw, 4.8rem)) / 2);
  box-sizing:border-box;
}
._root_1612d_44z7j_33._variant-normal_1612d_44z7j_65:not(#\9){
  color:currentColor;
}
._root_1612d_44z7j_33._variant-disabled_1612d_44z7j_68:not(#\9){
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._root_1612d_44z7j_33._variant-inverted_1612d_44z7j_71:not(#\9){
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
}

._circle_1612d_44z7j_75:not(#\9){
  display:inline-block;
  width:50%;
  height:100%;
  overflow:hidden;
  position:relative;
}
._circle_1612d_44z7j_75:not(#\9):after{
  position:absolute;
  box-sizing:border-box;
  content:"";
  border-radius:50%;
  border:0.2rem solid;
  border-right-color:transparent;
  border-bottom-color:transparent;
}
._circle_1612d_44z7j_75:not(#\9):after{
  animation:1.5s ease-in-out infinite;
  top:0;
  left:0;
  height:100%;
  width:200%;
}
._circle_1612d_44z7j_75._circle-left_1612d_44z7j_99:not(#\9):after{
  left:0;
  animation-name:_spinner-line-left_1612d_44z7j_1;
}
._circle_1612d_44z7j_75._circle-right_1612d_44z7j_104:not(#\9):after{
  left:-100%;
  animation-name:_spinner-line-right_1612d_44z7j_1;
}
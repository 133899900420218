._calendar_145pa_1j3bt_3:not(#\9){
  animation:_awsui-motion-fade-in-0_145pa_1j3bt_1 135ms ease-out;
  animation:_awsui-motion-fade-in-0_145pa_1j3bt_1 var(--motion-duration-show-quick-TMbCmo, 135ms) var(--motion-easing-show-quick-25z_bd, ease-out);
  animation-fill-mode:both;
}
@keyframes _awsui-motion-fade-in-0_145pa_1j3bt_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._calendar_145pa_1j3bt_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._calendar_145pa_1j3bt_3:not(#\9){
  animation:none;
  transition:none;
}

._root_145pa_1j3bt_26:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._date-picker-container_145pa_1j3bt_36:not(#\9){
  position:relative;
  max-width:234px;
}

._date-picker-trigger_145pa_1j3bt_41:not(#\9){
  display:flex;
}

._date-picker-input_145pa_1j3bt_45:not(#\9){
  padding-right:0.8rem;
  padding-right:var(--space-xs-1QR0Ng, 0.8rem);
  width:100%;
}

._calendar_145pa_1j3bt_3:not(#\9){
  display:block;
  width:234px;
}
._calendar-inner_145pa_1j3bt_58:not(#\9){
  margin:0.8rem;
  margin:var(--space-xs-1QR0Ng, 0.8rem);
}
._calendar-header_145pa_1j3bt_61:not(#\9){
  display:flex;
  justify-content:space-between;
  align-items:center;
}
._calendar-header-month_145pa_1j3bt_66:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
}
._calendar-day-names_145pa_1j3bt_78:not(#\9){
  display:flex;
  justify-content:stretch;
}
._calendar-day-name_145pa_1j3bt_78:not(#\9){
  flex:1 1 0%;
  width:0;
  word-break:break-word;
  text-align:center;
  padding:1.2rem 0 0.4rem;
  padding:var(--space-s-2tKeb0, 1.2rem) 0 var(--space-xxs-137Hig, 0.4rem);
  color:#545b64;
  color:var(--color-text-dropdown-group-label-2XOlKp, #545b64);
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}
._calendar-dates_145pa_1j3bt_92:not(#\9){
  border:1px solid #eaeded;
  border:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
}
._calendar-week_145pa_1j3bt_95:not(#\9){
  display:flex;
  justify-content:stretch;
}
._calendar-day_145pa_1j3bt_78:not(#\9){
  flex:1 1 0%;
  width:0;
  word-break:break-word;
  text-align:center;
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  border-right:1px solid #eaeded;
  border-right:1px solid var(--color-border-dropdown-item-default-2jqiTh, #eaeded);
  padding:0.4rem 0;
  padding:var(--space-xxs-137Hig, 0.4rem) 0;
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
  position:relative;
}
._calendar-day_145pa_1j3bt_78:not(#\9):last-child{
  border-right:none;
}
._calendar-day-in-last-week_145pa_1j3bt_113:not(#\9){
  border-bottom:none;
}
._calendar-day-enabled_145pa_1j3bt_119:not(#\9){
  cursor:pointer;
  color:#687078;
  color:var(--color-text-dropdown-item-secondary--r75hR, #687078);
}
._calendar-day-enabled_145pa_1j3bt_119._calendar-day-current-month_145pa_1j3bt_123:not(#\9){
  color:#16191f;
  color:var(--color-text-dropdown-item-default-WiQFVx, #16191f);
}
._calendar-day-enabled_145pa_1j3bt_119._calendar-day-current-month_145pa_1j3bt_123:not(#\9):hover{
  color:var(var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f));
  background-color:#f2f3f3;
  background-color:var(--color-background-dropdown-item-hover-35tDd2, #f2f3f3);
}
._calendar-day-enabled_145pa_1j3bt_119._calendar-day-current-month_145pa_1j3bt_123:not(#\9):hover:not(._calendar-day-selected_145pa_1j3bt_130)::before{
  border:1px solid #879596;
  border:1px solid var(--color-border-dropdown-item-hover-3Saafg, #879596);
}
._calendar-day-today_145pa_1j3bt_133:not(#\9){
  background-color:#f2f3f3;
  background-color:var(--color-background-calendar-today-3Bydyi, #f2f3f3);
}
._calendar-day_145pa_1j3bt_78:not(#\9)::before{
  content:"";
  position:absolute;
  z-index:1;
  top:-1px;
  left:-1px;
  bottom:-1px;
  right:-1px;
  background-color:transparent;
}
._calendar-day_145pa_1j3bt_78 > span:not(#\9){
  position:relative;
  z-index:1;
}
._calendar-day_145pa_1j3bt_78:not(#\9):focus{
  outline:none;
}
._calendar-day_145pa_1j3bt_78:not(#\9):focus::before{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._calendar-day-selected_145pa_1j3bt_130:not(#\9){
  border-color:transparent;
  position:relative;
}
._calendar-day-selected_145pa_1j3bt_130:not(#\9)::before{
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-selected-nrt43a, #f1faff);
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-dropdown-item-selected-1BqG1q, #00a1c9);
  z-index:2;
}
._calendar-day-selected_145pa_1j3bt_130 > span:not(#\9){
  color:var(var(--color-text-dropdown-item-highlighted-2S6vEC, #16191f));
  position:relative;
  z-index:2;
}
._segment_8cbea_haisk_3:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  border:1px solid #687078;
  border:1px solid var(--color-border-segment-default-3mUVYZ, #687078);
  padding:0.4rem 2rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-l-3cXvov, 2rem);
  border-right-width:0;
  font-weight:bold;
  letter-spacing:0.25px;
  background:#ffffff;
  background:var(--color-background-button-normal-default-3w9fMz, #ffffff);
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  overflow:visible;
}
._segment_8cbea_haisk_3:not(#\9):focus{
  outline:none;
}
._segment_8cbea_haisk_3:not(#\9):nth-child(1){
  grid-column:1;
  -ms-grid-column:1;
}
._segment_8cbea_haisk_3:not(#\9):nth-child(2){
  grid-column:2;
  -ms-grid-column:2;
}
._segment_8cbea_haisk_3:not(#\9):nth-child(3){
  grid-column:3;
  -ms-grid-column:3;
}
._segment_8cbea_haisk_3:not(#\9):nth-child(4){
  grid-column:4;
  -ms-grid-column:4;
}
._segment_8cbea_haisk_3:not(#\9):nth-child(5){
  grid-column:5;
  -ms-grid-column:5;
}
._segment_8cbea_haisk_3:not(#\9):nth-child(6){
  grid-column:6;
  -ms-grid-column:6;
}
._segment_8cbea_haisk_3[data-awsui-focus-visible=true]:not(#\9):focus{
  z-index:1;
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
  padding-right:calc(2rem - 1px);
  padding-right:calc(var(--space-l-3cXvov, 2rem) - 1px);
}
._segment_8cbea_haisk_3[data-awsui-focus-visible=true]:not(#\9):focus + ._segment_8cbea_haisk_3{
  border-left-width:0;
  padding-left:calc(2rem + 1px);
  padding-left:calc(var(--space-l-3cXvov, 2rem) + 1px);
}
._segment_8cbea_haisk_3[data-awsui-focus-visible=true]:not(#\9):focus:first-child{
  border-radius:2px;
}
._segment_8cbea_haisk_3[data-awsui-focus-visible=true]:not(#\9):focus:last-child{
  border-radius:2px;
  padding-right:2rem;
  padding-right:var(--space-l-3cXvov, 2rem);
}
._segment_8cbea_haisk_3._disabled_8cbea_haisk_64:not(#\9){
  background:#ffffff;
  background:var(--color-background-button-normal-disabled-3w9fMz, #ffffff);
  border-color:#d5dbdb #687078;
  border-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb) var(--color-border-segment-default-3mUVYZ, #687078);
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._segment_8cbea_haisk_3:not(#\9):last-child{
  border-right-width:1px;
  border-radius:0 2px 2px 0;
}
._segment_8cbea_haisk_3._disabled_8cbea_haisk_64:not(#\9):last-child{
  border-right-color:#d5dbdb;
  border-right-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb);
}
._segment_8cbea_haisk_3:not(#\9):first-child{
  border-radius:2px 0 0 2px;
}
._segment_8cbea_haisk_3._disabled_8cbea_haisk_64:not(#\9):first-child{
  border-left-color:#d5dbdb;
  border-left-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb);
}
._segment_8cbea_haisk_3._selected_8cbea_haisk_82:not(#\9){
  background:#eaeded;
  background:var(--color-background-button-normal-active-2WWahd, #eaeded);
  border-color:#16191f;
  border-color:var(--color-border-segment-hover-1dD-mx, #16191f);
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._segment_8cbea_haisk_3._selected_8cbea_haisk_82 + ._segment_8cbea_haisk_3:not(#\9):not(:focus){
  border-left-color:#16191f;
  border-left-color:var(--color-border-segment-hover-1dD-mx, #16191f);
}
._segment_8cbea_haisk_3:not(#\9):hover:not(._selected_8cbea_haisk_82):not(._disabled_8cbea_haisk_64):not(:focus){
  background:#fafafa;
  background:var(--color-background-button-normal-hover-1OJlgw, #fafafa);
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
  border-color:#16191f;
  border-color:var(--color-border-segment-hover-1dD-mx, #16191f);
  cursor:pointer;
}
._segment_8cbea_haisk_3:not(#\9):hover:not(._selected_8cbea_haisk_82):not(._disabled_8cbea_haisk_64):not(:focus) + ._segment_8cbea_haisk_3{
  border-left-color:#16191f;
  border-left-color:var(--color-border-segment-hover-1dD-mx, #16191f);
}

._with-text_8cbea_haisk_104:not(#\9){
  position:relative;
  left:calc(-1 * 0.4rem);
  left:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
}

._with-no-text_8cbea_haisk_110:not(#\9){
  margin-right:auto;
  margin-left:auto;
  right:0;
  left:0;
}

._root_8cbea_haisk_117:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._segment-part_8cbea_haisk_127:not(#\9){
  display:-ms-inline-grid;
  display:inline-grid;
}
@media (max-width: 576px){
  ._segment-part_8cbea_haisk_127:not(#\9){
    display:none;
  }
}

._select_8cbea_haisk_82:not(#\9){
  display:none;
}
@media (max-width: 576px){
  ._select_8cbea_haisk_82:not(#\9){
    display:block;
  }
}

._segment-count-2_8cbea_haisk_146:not(#\9){
  -ms-grid-columns:1fr 1fr;
  grid-template-columns:1fr 1fr;
}

._segment-count-3_8cbea_haisk_151:not(#\9){
  -ms-grid-columns:1fr 1fr 1fr;
  grid-template-columns:1fr 1fr 1fr;
}

._segment-count-4_8cbea_haisk_156:not(#\9){
  -ms-grid-columns:1fr 1fr 1fr 1fr;
  grid-template-columns:1fr 1fr 1fr 1fr;
}

._segment-count-5_8cbea_haisk_161:not(#\9){
  -ms-grid-columns:1fr 1fr 1fr 1fr 1fr;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr;
}

._segment-count-6_8cbea_haisk_166:not(#\9){
  -ms-grid-columns:1fr 1fr 1fr 1fr 1fr 1fr;
  grid-template-columns:1fr 1fr 1fr 1fr 1fr 1fr;
}
._option_1p2cx_1jgan_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:flex-start;
  width:100%;
  min-width:0;
  box-sizing:border-box;
  color:inherit;
}
._option_1p2cx_1jgan_3:not(#\9):not(._disabled_1p2cx_1jgan_18){
  cursor:inherit;
}

._content_1p2cx_1jgan_22:not(#\9){
  width:100%;
  display:flex;
  flex-direction:column;
  min-width:0;
}

._label-content_1p2cx_1jgan_29:not(#\9){
  display:flex;
  justify-content:space-between;
  flex-wrap:wrap;
}

._label_1p2cx_1jgan_29:not(#\9),
._tag_1p2cx_1jgan_36:not(#\9),
._label-tag_1p2cx_1jgan_37:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._label_1p2cx_1jgan_29:not(#\9),
._tag_1p2cx_1jgan_36:not(#\9){
  flex-wrap:wrap;
}

._label-prefix_1p2cx_1jgan_48:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._label-tag_1p2cx_1jgan_37:not(#\9){
  padding-left:1.2rem;
  padding-left:var(--space-s-2tKeb0, 1.2rem);
  flex:auto;
  text-align:right;
}
._label-tag_1p2cx_1jgan_37:not(#\9):empty{
  display:none;
}

._tags_1p2cx_1jgan_61:not(#\9),
._description_1p2cx_1jgan_62:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  color:#687078;
  color:var(--color-text-dropdown-item-secondary--r75hR, #687078);
  flex-wrap:wrap;
}

._tags_1p2cx_1jgan_61:not(#\9){
  display:flex;
  align-items:stretch;
}

._tag_1p2cx_1jgan_36:not(#\9):not(:last-child){
  padding-right:1.6rem;
  padding-right:var(--space-m-25I9Cr, 1.6rem);
}

._icon_1p2cx_1jgan_78:not(#\9){
  padding-right:0.8rem;
  padding-right:var(--space-xs-1QR0Ng, 0.8rem);
  align-content:center;
  display:flex;
  flex-shrink:0;
}

._icon-size-big_1p2cx_1jgan_85:not(#\9){
  padding-top:0.2rem;
  padding-top:var(--space-xxxs-10EX_Z, 0.2rem);
}

._filtering-match-highlight_1p2cx_1jgan_89:not(#\9){
  background-color:#f1faff;
  background-color:var(--color-background-dropdown-item-filter-match-WkeJRF, #f1faff);
  color:#0073bb;
  color:var(--color-text-dropdown-item-filter-match-Bc0LvG, #0073bb);
  font-weight:bold;
}

._disabled_1p2cx_1jgan_18:not(#\9){
  color:#aab7b8;
  color:var(--color-text-dropdown-item-disabled-KGAbe7, #aab7b8);
  pointer-events:none;
}
._disabled_1p2cx_1jgan_18 > ._content_1p2cx_1jgan_22 > ._tags_1p2cx_1jgan_61:not(#\9),
._disabled_1p2cx_1jgan_18 > ._content_1p2cx_1jgan_22 > ._description_1p2cx_1jgan_62:not(#\9){
  color:currentColor;
}

._trigger-variant_1p2cx_1jgan_104:not(#\9){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
._segment_1edmh_o2yla_3 + path:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._segment_1edmh_o2yla_3 + path:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._segment_1edmh_o2yla_3 + path:not(#\9){
  animation:none;
  transition:none;
}

._segment_1edmh_o2yla_3:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._segment_1edmh_o2yla_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._segment_1edmh_o2yla_3:not(#\9){
  animation:none;
  transition:none;
}
._segment_1edmh_o2yla_3 > ._segment__hover_1edmh_o2yla_30:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._segment_1edmh_o2yla_3 > ._segment__hover_1edmh_o2yla_30:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._segment_1edmh_o2yla_3 > ._segment__hover_1edmh_o2yla_30:not(#\9){
  animation:none;
  transition:none;
}

._label_1edmh_o2yla_44:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._label_1edmh_o2yla_44:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._label_1edmh_o2yla_44:not(#\9){
  animation:none;
  transition:none;
}

._root_1edmh_o2yla_58:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._has-default-filter_1edmh_o2yla_73 > :not(#\9):first-child{
  flex:280px 0 1;
}

._pie_1edmh_o2yla_77:not(#\9){
  display:block;
  position:relative;
  width:100%;
  shape-rendering:geometricPrecision;
}

._content_1edmh_o2yla_84:not(#\9){
  display:flex;
  position:relative;
  justify-content:center;
  align-items:center;
}
._content_1edmh_o2yla_84._content--small_1edmh_o2yla_90:not(#\9){
  min-height:calc( 2 * (50px + 0.8rem + calc(2 * 2.2rem)) );
  min-height:calc( 2 * (50px + var(--space-xs-1QR0Ng, 0.8rem) + calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem))) );
}
._content_1edmh_o2yla_84._content--medium_1edmh_o2yla_93:not(#\9){
  min-height:calc( 2 * (100px + 1.2rem + calc(2 * 2.2rem)) );
  min-height:calc( 2 * (100px + var(--space-s-2tKeb0, 1.2rem) + calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem))) );
}
._content_1edmh_o2yla_84._content--large_1edmh_o2yla_96:not(#\9){
  min-height:calc( 2 * (140px + 1.2rem + calc(2 * 2.2rem)) );
  min-height:calc( 2 * (140px + var(--space-s-2tKeb0, 1.2rem) + calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem))) );
}
._content_1edmh_o2yla_84._content--reserve-filter_1edmh_o2yla_99:not(#\9){
  margin-top:calc(2 * 2.2rem);
  margin-top:calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem));
}
._content_1edmh_o2yla_84._content--reserve-legend_1edmh_o2yla_102:not(#\9){
  margin-bottom:calc(2 * 2.2rem);
  margin-bottom:calc(2 * var(--size-lineHeight-body-100-3TEa20, 2.2rem));
}

._chart-container_1edmh_o2yla_110:not(#\9){
  display:flex;
  flex:1;
}

._inner-content_1edmh_o2yla_115:not(#\9){
  display:flex;
  position:absolute;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  text-align:center;
  top:0;
  left:0;
  bottom:0;
  right:0;
  pointer-events:none;
}

._segment__highlight_1edmh_o2yla_129:not(#\9){
  stroke:none;
  opacity:0;
}

._focus-outline_1edmh_o2yla_134:not(#\9){
  display:none;
  pointer-events:none;
  fill:none;
  stroke:#00a1c9;
  stroke:var(--color-border-item-focused-2uP71z, #00a1c9);
  stroke-width:2px;
}

._segment_1edmh_o2yla_3:not(#\9){
  stroke:#ffffff;
  stroke:var(--color-background-container-content-3w9fMz, #ffffff);
  stroke-width:2px;
  stroke-linejoin:round;
  opacity:1;
  cursor:pointer;
}
._segment_1edmh_o2yla_3:not(#\9):focus{
  outline:none;
}
._segment_1edmh_o2yla_3[data-awsui-focus-visible=true]:not(#\9):focus > ._focus-outline_1edmh_o2yla_134{
  display:block;
}
._segment_1edmh_o2yla_3._segment--dimmed_1edmh_o2yla_155:not(#\9){
  opacity:0.35;
}
._segment_1edmh_o2yla_3._segment--highlighted_1edmh_o2yla_158 > ._segment__highlight_1edmh_o2yla_129:not(#\9), ._segment_1edmh_o2yla_3:not(#\9):hover:not(._segment--dimmed_1edmh_o2yla_155) > ._segment__highlight_1edmh_o2yla_129{
  opacity:1;
}

._label_1edmh_o2yla_44:not(#\9){
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
  opacity:1;
}
._label_1edmh_o2yla_44 > line:not(#\9){
  stroke:#aab7b8;
  stroke:var(--color-border-control-default-27WVST, #aab7b8);
  stroke-width:1px;
}
._label_1edmh_o2yla_44 > div:not(#\9){
  margin-top:-0.75em;
}
._label_1edmh_o2yla_44._label--dimmed_1edmh_o2yla_173:not(#\9){
  opacity:0.35;
}
._label_1edmh_o2yla_44._label--align-right_1edmh_o2yla_176 > div:not(#\9){
  text-align:right;
}

._label-text_1edmh_o2yla_180 > text:not(#\9){
  fill:#16191f;
  fill:var(--color-text-heading-default-2S6vEC, #16191f);
  dominant-baseline:middle;
}

text._label__description_1edmh_o2yla_185:not(#\9){
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
  fill:#545b64;
  fill:var(--color-text-label-3qT4rX, #545b64);
}

._popover-header_1edmh_o2yla_198:not(#\9){
  display:inline-flex;
  align-items:center;
}
._root_14mhv_1ar4f_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  box-sizing:border-box;
}

._label_14mhv_1ar4f_14:not(#\9){
  box-sizing:border-box;
  color:#16191f;
  color:var(--color-text-form-label-WiQFVx, #16191f);
  display:inline;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
}
._label_14mhv_1ar4f_14:not(#\9):only-child{
  margin:0;
}

._description_14mhv_1ar4f_30:not(#\9),
._constraint_14mhv_1ar4f_31:not(#\9){
  color:#687078;
  color:var(--color-text-form-secondary-2ZkiuH, #687078);
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}

._hints_14mhv_1ar4f_37:not(#\9),
._constraint-has-error_14mhv_1ar4f_38:not(#\9){
  padding-top:0.4rem;
  padding-top:var(--space-xxs-137Hig, 0.4rem);
}

._controls_14mhv_1ar4f_46:not(#\9):not(._label-hidden_14mhv_1ar4f_46){
  padding-top:0.4rem;
  padding-top:var(--space-xxs-137Hig, 0.4rem);
}

._control_14mhv_1ar4f_46:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._error_14mhv_1ar4f_56:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  display:flex;
  align-items:flex-start;
}

._error__message_14mhv_1ar4f_64:not(#\9){
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}

._visually-hidden_14mhv_1ar4f_68:not(#\9){
  position:absolute !important;
  top:-9999px !important;
  left:-9999px !important;
}
._marker_1kjc7_6qpzw_3:not(#\9){
  transition:opacity 90ms linear;
  transition:opacity var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
@media (prefers-reduced-motion: reduce){
  ._marker_1kjc7_6qpzw_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._marker_1kjc7_6qpzw_3:not(#\9){
  animation:none;
  transition:none;
}

._root_1kjc7_6qpzw_17:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._list_1kjc7_6qpzw_31:not(#\9){
  display:flex;
  flex-wrap:wrap;
  list-style:none;
  margin:0;
  padding:0;
}

._marker_1kjc7_6qpzw_3:not(#\9){
  display:inline-flex;
  align-items:center;
  margin-right:1.6rem;
  margin-right:var(--space-m-25I9Cr, 1.6rem);
  padding:0;
  cursor:pointer;
  opacity:1;
}
._marker_1kjc7_6qpzw_3:not(#\9):focus{
  outline:none;
}
._marker_1kjc7_6qpzw_3[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._marker_1kjc7_6qpzw_3[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._marker_1kjc7_6qpzw_3[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._marker_1kjc7_6qpzw_3:not(#\9):last-child{
  margin-right:0;
}
._marker_1kjc7_6qpzw_3._marker--dimmed_1kjc7_6qpzw_71:not(#\9){
  opacity:0.35;
}
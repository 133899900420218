._wrapper_cqcqk_ejf7k_3:not(#\9){
  position:relative;
}

._elementWrapper_cqcqk_ejf7k_7:not(#\9){
  flex:1;
}

._markerWrapper_cqcqk_ejf7k_11:not(#\9){
  position:absolute;
  top:0;
}

._placement-right_cqcqk_ejf7k_16:not(#\9){
  left:100%;
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}

._placement-left_cqcqk_ejf7k_21:not(#\9){
  right:100%;
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
}

._inlineWrapper_cqcqk_ejf7k_26:not(#\9){
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}
._root_2qdw9_1yaee_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  cursor:inherit;
  display:flex;
  width:100%;
  flex-wrap:wrap;
  justify-content:space-between;
}
._root-no-actions_2qdw9_1yaee_17._root-variant-h2_2qdw9_1yaee_17:not(#\9), ._root-no-actions_2qdw9_1yaee_17._root-variant-h3_2qdw9_1yaee_17:not(#\9), ._root-has-description_2qdw9_1yaee_17._root-variant-h2_2qdw9_1yaee_17:not(#\9), ._root-has-description_2qdw9_1yaee_17._root-variant-h3_2qdw9_1yaee_17:not(#\9){
  padding-bottom:0.4rem;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._main_2qdw9_1yaee_21:not(#\9){
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
}
:not(#\9):not(._root-no-actions_2qdw9_1yaee_17) > ._main_2qdw9_1yaee_21{
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
}
._main-variant-h1_2qdw9_1yaee_29:not(#\9){
  padding:0.4rem 0;
  padding:var(--space-scaled-2x-xxs-wz9S8K, 0.4rem) 0;
}
._main-variant-h2_2qdw9_1yaee_32:not(#\9), ._main-variant-h3_2qdw9_1yaee_32:not(#\9){
  padding-top:0.4rem;
  padding-top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}
:not(#\9):not(._root-has-description_2qdw9_1yaee_17) > ._main-variant-h2_2qdw9_1yaee_32, :not(#\9):not(._root-has-description_2qdw9_1yaee_17) > ._main-variant-h3_2qdw9_1yaee_32{
  padding-bottom:0.2rem;
  padding-bottom:var(--space-xxxs-10EX_Z, 0.2rem);
}

._actions_2qdw9_1yaee_39:not(#\9){
  display:flex;
  align-items:flex-start;
}
._actions-variant-h1_2qdw9_1yaee_43:not(#\9){
  padding:0.8rem 0;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) 0;
}
._actions-variant-h2_2qdw9_1yaee_46:not(#\9), ._actions-variant-h3_2qdw9_1yaee_46:not(#\9){
  padding:0.4rem 0;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) 0;
}
._root-has-description_2qdw9_1yaee_17 > ._actions-variant-h2_2qdw9_1yaee_46:not(#\9), ._root-has-description_2qdw9_1yaee_17 > ._actions-variant-h3_2qdw9_1yaee_46:not(#\9){
  padding-bottom:0;
}

._title_2qdw9_1yaee_53:not(#\9){
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}
._title-variant-h2_2qdw9_1yaee_59:not(#\9), ._title-variant-h3_2qdw9_1yaee_59:not(#\9){
  padding-top:0.4rem;
  padding-top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
  padding-bottom:0.4rem;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 0.4rem);
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}

._info_2qdw9_1yaee_66:not(#\9){
  padding-right:1.2rem;
  padding-right:var(--space-s-2tKeb0, 1.2rem);
}

._description_2qdw9_1yaee_70:not(#\9){
  margin:0;
  padding:0;
  color:#545b64;
  color:var(--color-text-heading-secondary-1oBHNI, #545b64);
}
._description-variant-h1_2qdw9_1yaee_75:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  padding-top:0.4rem;
  padding-top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}
._description-variant-h2_2qdw9_1yaee_80:not(#\9), ._description-variant-h3_2qdw9_1yaee_80:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  padding-bottom:0.4rem;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._heading_2qdw9_1yaee_86:not(#\9){
  margin:0;
  display:inline;
  font-size:inherit;
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}
._heading_2qdw9_1yaee_86:not(#\9):only-child{
  margin:0;
}
._heading-variant-h1_2qdw9_1yaee_96:not(#\9){
  font-size:2.8rem;
  font-size:var(--size-font-heading-500-14QHat, 2.8rem);
  line-height:3.6rem;
  line-height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
}
._heading-variant-h2_2qdw9_1yaee_100:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._counter_2qdw9_1yaee_108:not(#\9){
  color:#687078;
  color:var(--color-text-small-2ZkiuH, #687078);
  font-weight:normal;
}
._tabs-header_14rmt_lypoa_5:not(#\9){
  margin:0;
  padding:0;
  display:flex;
  flex-wrap:wrap;
  max-width:100%;
}

._tabs-tab_14rmt_lypoa_13:not(#\9){
  list-style:none;
  padding:0;
  flex-shrink:0;
  display:block;
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-l-3cXvov, 2rem));
}

._tabs-tab-label_14rmt_lypoa_21:not(#\9){
  display:inline-block;
  padding:0.4rem 2rem;
  padding:var(--space-scaled-2x-xxs-wz9S8K, 0.4rem) var(--space-l-3cXvov, 2rem);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
}

._tabs-tab_14rmt_lypoa_13:not(#\9):not(:last-child) > a > ._tabs-tab-label_14rmt_lypoa_21{
  margin-right:-1px;
  border-right:1px solid #aab7b8;
  border-right:1px solid var(--color-border-tabs-2fNNxL, #aab7b8);
}

._tabs-tab-link_14rmt_lypoa_34:not(#\9){
  position:relative;
  display:block;
  cursor:pointer;
  padding:1.2rem 0;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) 0;
  border:1px solid transparent;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._tabs-tab-link_14rmt_lypoa_34:not(#\9):hover{
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
  text-decoration:none;
}
._tabs-tab-link_14rmt_lypoa_34:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._tabs-tab-link_14rmt_lypoa_34[data-awsui-focus-visible=true]:not(#\9):focus{
  z-index:1;
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._tabs-tab-link_14rmt_lypoa_34[data-awsui-focus-visible=true]:not(#\9):focus > a > ._tabs-tab-label_14rmt_lypoa_21{
  border-right-color:transparent;
}

._tabs-tab_14rmt_lypoa_13:not(#\9):first-child{
  margin-left:1px;
}

._tabs-tab_14rmt_lypoa_13:not(#\9):last-child{
  margin-right:1px;
}

._tabs-tab-disabled_14rmt_lypoa_72:not(#\9), ._tabs-tab-disabled_14rmt_lypoa_72:not(#\9):hover{
  cursor:default;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}

._tabs-tab-link_14rmt_lypoa_34:not(#\9):not(._tabs-tab-disabled_14rmt_lypoa_72):before{
  content:"";
  position:absolute;
  left:0;
  width:100%;
  bottom:-1px;
  height:0.2rem;
  background:#16191f;
  background:var(--color-text-interactive-hover-18qOom, #16191f);
  opacity:0;
  transition:opacity var(--awsui-motion-duration-transition-quick) var(--awsui-motion-easing-transition-quick);
}
@media (prefers-reduced-motion: reduce){
  ._tabs-tab-link_14rmt_lypoa_34:not(#\9):not(._tabs-tab-disabled_14rmt_lypoa_72):before{
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._tabs-tab-link_14rmt_lypoa_34:not(#\9):not(._tabs-tab-disabled_14rmt_lypoa_72):before{
  animation:none;
  transition:none;
}

._tabs-tab-active_14rmt_lypoa_100:not(#\9):not(._tabs-tab-disabled_14rmt_lypoa_72){
  color:#ec7211;
  color:var(--color-text-accent-NYv9af, #ec7211);
}
._tabs-tab-active_14rmt_lypoa_100:not(#\9):not(._tabs-tab-disabled_14rmt_lypoa_72):before{
  opacity:1;
}

._tabs-header--variant-default_14rmt_lypoa_107:not(#\9){
  border-bottom:1px solid #aab7b8;
  border-bottom:1px solid var(--color-border-tabs-2fNNxL, #aab7b8);
  border-top:1px solid transparent;
}

._tabs_14rmt_lypoa_5:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
  width:100%;
}

._tabs-content-wrapper_14rmt_lypoa_128:not(#\9){
  padding:1.6rem 0;
  padding:var(--space-scaled-m-p3tAne, 1.6rem) 0;
}

._tabs-content_14rmt_lypoa_128:not(#\9){
  border:1px solid transparent;
}

._tabs-content-active_14rmt_lypoa_136:not(#\9):focus{
  outline:none;
}
._tabs-content-active_14rmt_lypoa_136[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._tabs-content-inactive_14rmt_lypoa_146:not(#\9){
  display:none;
}
._arrow_1hpp3_gbda8_3:not(#\9){
  width:2rem;
  height:1rem;
}
._arrow-outer_1hpp3_gbda8_7:not(#\9), ._arrow-inner_1hpp3_gbda8_7:not(#\9){
  position:absolute;
  overflow:hidden;
  width:2rem;
  height:1rem;
  top:0;
  left:0;
}
._arrow-outer_1hpp3_gbda8_7:not(#\9)::after, ._arrow-inner_1hpp3_gbda8_7:not(#\9)::after{
  content:"";
  box-sizing:border-box;
  display:inline-block;
  position:absolute;
  border-radius:2px 0 0 0;
  bottom:0;
  left:0;
  width:1.4rem;
  height:1.4rem;
  transform:rotate(45deg);
  transform-origin:0 100%;
}
._arrow-outer_1hpp3_gbda8_7:not(#\9)::after{
  background-color:#0073bb;
  background-color:var(--color-border-status-info-3uW6lA, #0073bb);
}
._arrow-inner_1hpp3_gbda8_7:not(#\9){
  top:2px;
}
._arrow-inner_1hpp3_gbda8_7:not(#\9)::after{
  border-radius:1px 0 0 0;
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
}
._arrow-position-right-top_1hpp3_gbda8_38 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after, ._arrow-position-right-bottom_1hpp3_gbda8_38 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after{
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-left-top_1hpp3_gbda8_41 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after, ._arrow-position-left-bottom_1hpp3_gbda8_41 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after{
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-top-center_1hpp3_gbda8_44 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after, ._arrow-position-top-responsive_1hpp3_gbda8_44 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after{
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-bottom-center_1hpp3_gbda8_47 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after, ._arrow-position-bottom-responsive_1hpp3_gbda8_47 > ._arrow-outer_1hpp3_gbda8_7:not(#\9)::after{
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}

._description_1hpp3_gbda8_59:not(#\9){
  overflow:hidden;
  margin-top:0.4rem;
  margin-top:var(--space-xxs-137Hig, 0.4rem);
}

._actionBar_1hpp3_gbda8_64:not(#\9){
  display:flex;
  justify-content:space-between;
  align-items:center;
  position:relative;
}

._stepCounter_1hpp3_gbda8_71:not(#\9){
  margin-right:2rem;
}

._divider_1hpp3_gbda8_75:not(#\9){
  border-bottom:1px solid #c1c1c1;
}

._hotspot_1hpp3_gbda8_79:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  background:transparent;
  border:none;
  padding:0;
  cursor:pointer;
  width:16px;
  height:16px;
}
._hotspot_1hpp3_gbda8_79:not(#\9):focus{
  outline:none;
}
._hotspot_1hpp3_gbda8_79[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._hotspot_1hpp3_gbda8_79[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._hotspot_1hpp3_gbda8_79[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:50%;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._hotspot_1hpp3_gbda8_79 > svg:not(#\9){
  position:relative;
  stroke:#0073bb;
  stroke:var(--color-text-link-default-Bc0LvG, #0073bb);
}
@keyframes _modal-slide-up_1d2i7_j7i5k_1{
  0%{
    transform:translate(0, 10px);
  }
  100%{
    transform:translate(0, 0);
  }
}
._dialog_1d2i7_j7i5k_11:not(#\9){
  animation:_modal-slide-up_1d2i7_j7i5k_1 180ms ease-out, _awsui-motion-fade-in-0_1d2i7_j7i5k_1 180ms ease-out;
  animation:_modal-slide-up_1d2i7_j7i5k_1 var(--motion-duration-slow-39Jq2G, 180ms) ease-out, _awsui-motion-fade-in-0_1d2i7_j7i5k_1 var(--motion-duration-slow-39Jq2G, 180ms) ease-out;
  animation-delay:90ms;
  animation-delay:var(--motion-duration-fast-oPeLPK, 90ms);
  animation-fill-mode:both;
}
@keyframes _awsui-motion-fade-in-0_1d2i7_j7i5k_1{
  from{
    opacity:0;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._dialog_1d2i7_j7i5k_11:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._dialog_1d2i7_j7i5k_11:not(#\9){
  animation:none;
  transition:none;
}

._overlay_1d2i7_j7i5k_35:not(#\9){
  animation:_awsui-motion-fade-in_1d2i7_j7i5k_1 270ms ease-out;
  animation:_awsui-motion-fade-in_1d2i7_j7i5k_1 var(--motion-duration-extra-slow-39YF13, 270ms) ease-out;
}
@keyframes _awsui-motion-fade-in_1d2i7_j7i5k_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._overlay_1d2i7_j7i5k_35:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._overlay_1d2i7_j7i5k_35:not(#\9){
  animation:none;
  transition:none;
}

._root_1d2i7_j7i5k_57:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  align-items:center;
  justify-items:center;
  bottom:0;
  left:0;
  right:0;
  top:0;
  position:fixed;
  z-index:5000;
  outline:0;
  overflow:auto;
  cursor:pointer;
}
._root_1d2i7_j7i5k_57._hidden_1d2i7_j7i5k_78:not(#\9){
  display:none;
}

._overlay_1d2i7_j7i5k_35:not(#\9){
  background-color:rgba(242, 243, 243, 0.9);
  background-color:var(--color-background-modal-overlay-3ptHv2, rgba(242, 243, 243, 0.9));
  position:fixed;
  z-index:4999;
  right:0;
  top:0;
  bottom:0;
  left:0;
}

._focus-lock_1d2i7_j7i5k_92:not(#\9){
  align-self:flex-start;
  margin:auto;
  padding:calc(2 * 3.2rem) 0;
  padding:calc(2 * var(--space-scaled-xxl-238u6Z, 3.2rem)) 0;
  z-index:5000;
  pointer-events:none;
}

._dialog_1d2i7_j7i5k_11:not(#\9){
  position:static;
  top:0;
  transform:translate(0, 0);
  width:calc(100vw - 2rem);
  width:calc(100vw - var(--space-l-3cXvov, 2rem));
  box-sizing:border-box;
  outline:none;
  z-index:5000;
  pointer-events:all;
}
._dialog_1d2i7_j7i5k_11._small_1d2i7_j7i5k_110:not(#\9){
  max-width:320px;
}
._dialog_1d2i7_j7i5k_11._medium_1d2i7_j7i5k_113:not(#\9){
  max-width:600px;
}
._dialog_1d2i7_j7i5k_11._large_1d2i7_j7i5k_116:not(#\9){
  max-width:820px;
}
._dialog_1d2i7_j7i5k_11._max_1d2i7_j7i5k_119._breakpoint-xs_1d2i7_j7i5k_119:not(#\9){
  max-width:calc(100vw - (2 * 4rem + 4rem));
  max-width:calc(100vw - (2 * 4rem + var(--space-xxxl-3dxeuJ, 4rem)));
  margin:auto;
}

._container_1d2i7_j7i5k_124:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0;
  box-sizing:border-box;
  word-wrap:break-word;
}
@media screen and (-ms-high-contrast: active){
  ._container_1d2i7_j7i5k_124:not(#\9){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}

._content_1d2i7_j7i5k_146:not(#\9){
  padding:1.6rem 2rem 2rem;
  padding:var(--space-scaled-m-p3tAne, 1.6rem) var(--space-l-3cXvov, 2rem) var(--space-scaled-l-17GFGI, 2rem);
}

._inner-header_1d2i7_j7i5k_150:not(#\9){
  flex-wrap:nowrap;
  padding:0.2rem 0;
  padding:var(--space-xxxs-10EX_Z, 0.2rem) 0;
}

._header_1d2i7_j7i5k_155:not(#\9){
  padding:0.8rem 2rem;
  padding:var(--space-xs-1QR0Ng, 0.8rem) var(--space-l-3cXvov, 2rem);
  background-color:#fafafa;
  background-color:var(--color-background-container-header-1OJlgw, #fafafa);
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}

._footer_1d2i7_j7i5k_165:not(#\9){
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  padding:1.2rem 2rem;
  padding:var(--space-s-2tKeb0, 1.2rem) var(--space-l-3cXvov, 2rem);
}
._footer_1d2i7_j7i5k_165:not(#\9):after{
  content:"";
  display:table;
  clear:both;
}

._dismiss-control_1d2i7_j7i5k_175:not(#\9){
  margin-right:calc(-1 * 0.4rem);
  margin-right:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
}

._modal-open_1d2i7_j7i5k_179:not(#\9){
  overflow:hidden;
  box-sizing:border-box;
}
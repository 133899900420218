._card-inner_p8a6i_1gmsz_3:not(#\9){
  transition-property:background-color, border-top-color, border-bottom-color, border-left-color, border-right-color;
  transition-duration:180ms;
  transition-duration:var(--motion-duration-transition-show-paced-2crOWV, 180ms);
  transition-timing-function:ease-out;
  transition-timing-function:var(--motion-easing-transition-show-paced-25z_bd, ease-out);
}
@media (prefers-reduced-motion: reduce){
  ._card-inner_p8a6i_1gmsz_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._card-inner_p8a6i_1gmsz_3:not(#\9){
  animation:none;
  transition:none;
}

._root_p8a6i_1gmsz_19:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._header_p8a6i_1gmsz_34:not(#\9){
  padding:1.2rem 2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-l-3cXvov, 2rem);
}

._list_p8a6i_1gmsz_38:not(#\9){
  display:flex;
  flex-wrap:wrap;
  box-sizing:border-box;
  padding:0;
  list-style:none;
  margin:0 0 0 calc(2rem * -1);
  margin:0 0 0 calc(var(--space-grid-gutter-17GFGI, 2rem) * -1);
}
._list_p8a6i_1gmsz_38._list-grid-1_p8a6i_1gmsz_46 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:100%;
}
._list_p8a6i_1gmsz_38._list-grid-2_p8a6i_1gmsz_49 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:50%;
}
._list_p8a6i_1gmsz_38._list-grid-3_p8a6i_1gmsz_52 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:33.3333333333%;
}
._list_p8a6i_1gmsz_38._list-grid-4_p8a6i_1gmsz_55 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:25%;
}
._list_p8a6i_1gmsz_38._list-grid-5_p8a6i_1gmsz_58 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:20%;
}
._list_p8a6i_1gmsz_38._list-grid-6_p8a6i_1gmsz_61 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:16.6666666667%;
}
._list_p8a6i_1gmsz_38._list-grid-7_p8a6i_1gmsz_64 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:14.2857142857%;
}
._list_p8a6i_1gmsz_38._list-grid-8_p8a6i_1gmsz_67 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:12.5%;
}
._list_p8a6i_1gmsz_38._list-grid-9_p8a6i_1gmsz_70 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:11.1111111111%;
}
._list_p8a6i_1gmsz_38._list-grid-10_p8a6i_1gmsz_73 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:10%;
}
._list_p8a6i_1gmsz_38._list-grid-11_p8a6i_1gmsz_76 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:9.0909090909%;
}
._list_p8a6i_1gmsz_38._list-grid-12_p8a6i_1gmsz_79 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:8.3333333333%;
}
._list_p8a6i_1gmsz_38._list-grid-13_p8a6i_1gmsz_82 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:7.6923076923%;
}
._list_p8a6i_1gmsz_38._list-grid-14_p8a6i_1gmsz_85 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:7.1428571429%;
}
._list_p8a6i_1gmsz_38._list-grid-15_p8a6i_1gmsz_88 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:6.6666666667%;
}
._list_p8a6i_1gmsz_38._list-grid-16_p8a6i_1gmsz_91 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:6.25%;
}
._list_p8a6i_1gmsz_38._list-grid-17_p8a6i_1gmsz_94 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:5.8823529412%;
}
._list_p8a6i_1gmsz_38._list-grid-18_p8a6i_1gmsz_97 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:5.5555555556%;
}
._list_p8a6i_1gmsz_38._list-grid-19_p8a6i_1gmsz_100 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:5.2631578947%;
}
._list_p8a6i_1gmsz_38._list-grid-20_p8a6i_1gmsz_103 > ._card_p8a6i_1gmsz_3:not(#\9){
  width:5%;
}

._selection-control_p8a6i_1gmsz_107:not(#\9){
  position:absolute;
  box-sizing:border-box;
  width:5.4rem;
  top:0;
  right:0;
  padding:2rem 2rem;
  padding:var(--space-scaled-l-17GFGI, 2rem) var(--space-l-3cXvov, 2rem);
}

._loading_p8a6i_1gmsz_116:not(#\9),
._empty_p8a6i_1gmsz_117:not(#\9){
  overflow:hidden;
  text-align:center;
  color:#687078;
  color:var(--color-text-empty-6_f-gS, #687078);
  margin-bottom:2rem;
  margin-bottom:var(--space-scaled-l-17GFGI, 2rem);
}

._has-header_p8a6i_1gmsz_124:not(#\9){
  margin-top:2rem;
  margin-top:var(--space-grid-gutter-17GFGI, 2rem);
}

._card_p8a6i_1gmsz_3:not(#\9){
  display:flex;
  overflow-wrap:break-word;
  word-wrap:break-word;
  margin:0;
  padding:0;
  list-style:none;
}
._card-inner_p8a6i_1gmsz_3:not(#\9){
  position:relative;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
  margin:0 0 2rem 2rem;
  margin:0 0 var(--space-grid-gutter-17GFGI, 2rem) var(--space-grid-gutter-17GFGI, 2rem);
  padding:2rem 2rem 1.2rem;
  padding:var(--space-scaled-l-17GFGI, 2rem) var(--space-l-3cXvov, 2rem) var(--space-s-2tKeb0, 1.2rem);
  border-color:transparent;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0;
  box-sizing:border-box;
  width:100%;
  min-width:0;
  box-sizing:border-box;
}
@media screen and (-ms-high-contrast: active){
  ._card-inner_p8a6i_1gmsz_3:not(#\9){
    padding:calc(2rem - 1px) calc(2rem - 1px) calc(1.2rem - 1px);
    padding:calc(var(--space-scaled-l-17GFGI, 2rem) - 1px) calc(var(--space-l-3cXvov, 2rem) - 1px) calc(var(--space-s-2tKeb0, 1.2rem) - 1px);
  }
}
@media screen and (-ms-high-contrast: active){
  ._card-inner_p8a6i_1gmsz_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
._card-header_p8a6i_1gmsz_160:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._card-header-inner_p8a6i_1gmsz_164:not(#\9){
  width:100%;
  display:inline-block;
}
._card-selectable_p8a6i_1gmsz_168 > ._card-inner_p8a6i_1gmsz_3 > ._card-header_p8a6i_1gmsz_160:not(#\9){
  width:90%;
}
._card-selected_p8a6i_1gmsz_171 > ._card-inner_p8a6i_1gmsz_3:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  background-color:#f1faff;
  background-color:var(--color-background-item-selected-WkeJRF, #f1faff);
  padding:2rem calc(2rem - 1px) calc(1.2rem - 1px) calc(2rem - 1px);
  padding:var(--space-scaled-l-17GFGI, 2rem) calc(var(--space-l-3cXvov, 2rem) - 1px) calc(var(--space-s-2tKeb0, 1.2rem) - 1px) calc(var(--space-l-3cXvov, 2rem) - 1px);
}
._card-selected_p8a6i_1gmsz_171 > ._card-inner_p8a6i_1gmsz_3 > ._card-header_p8a6i_1gmsz_160 > ._selection-control_p8a6i_1gmsz_107:not(#\9){
  margin-right:calc(-1 * 1px);
}

._section_p8a6i_1gmsz_180:not(#\9){
  display:inline-block;
  box-sizing:border-box;
  padding:0.8rem 0 0.8rem 0;
  padding:var(--space-xs-1QR0Ng, 0.8rem) 0 var(--space-scaled-xs-19adjU, 0.8rem) 0;
  vertical-align:top;
}
._section-header_p8a6i_1gmsz_186:not(#\9){
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
}
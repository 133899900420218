._root_fvjdu_1piey_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  box-sizing:border-box;
  padding-left:0;
  margin:0;
  list-style:none;
}
._root_fvjdu_1piey_3 > li:not(#\9){
  margin:0.4rem 0.4rem;
  margin:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem);
  text-align:center;
  box-sizing:border-box;
  padding:0;
}
._root_fvjdu_1piey_3 > li:not(#\9):first-child{
  margin-left:0;
}
._root_fvjdu_1piey_3 > li:not(#\9):last-child{
  margin-right:0;
}

._button_fvjdu_1piey_32:not(#\9),
._dots_fvjdu_1piey_33:not(#\9){
  min-width:2rem;
  min-width:var(--space-l-3cXvov, 2rem);
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  border:1px solid transparent;
}

._root-disabled_fvjdu_1piey_39 > ._dots_fvjdu_1piey_33:not(#\9){
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}

._button_fvjdu_1piey_32:not(#\9){
  cursor:pointer;
  text-align:center;
  box-sizing:border-box;
  background:transparent;
  line-height:inherit;
  padding:0;
}
._button_fvjdu_1piey_32:not(#\9):focus{
  outline:none;
}
._button_fvjdu_1piey_32[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-focused-2uP71z, #00a1c9);
  border-radius:2px;
  box-shadow:0 0 0 1px #00a1c9;
  box-shadow:0 0 0 1px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button-disabled_fvjdu_1piey_60:not(#\9){
  cursor:default;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._button-current_fvjdu_1piey_64:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  color:#16191f;
  color:var(--color-text-interactive-active-18qOom, #16191f);
}
._button-current_fvjdu_1piey_64._button-disabled_fvjdu_1piey_60:not(#\9){
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._button_fvjdu_1piey_32:not(#\9):not(._button-disabled_fvjdu_1piey_60):hover{
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._root_18582_mrz62_3:not(#\9){
  display:flex;
}
._horizontal_18582_mrz62_14:not(#\9){
  flex-direction:row;
  flex-wrap:wrap;
}
._horizontal-xxxs_18582_mrz62_18:not(#\9){
  margin-left:calc(-1 * 0.2rem);
  margin-left:calc(-1 * var(--space-xxxs-10EX_Z, 0.2rem));
  margin-bottom:calc(-1 * 0.2rem);
  margin-bottom:calc(-1 * var(--space-xxxs-10EX_Z, 0.2rem));
}
._horizontal-xxs_18582_mrz62_22:not(#\9){
  margin-left:calc(-1 * 0.4rem);
  margin-left:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
  margin-bottom:calc(-1 * 0.4rem);
  margin-bottom:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
}
._horizontal-xs_18582_mrz62_26:not(#\9){
  margin-left:calc(-1 * 0.8rem);
  margin-left:calc(-1 * var(--space-xs-1QR0Ng, 0.8rem));
  margin-bottom:calc(-1 * 0.8rem);
  margin-bottom:calc(-1 * var(--space-xs-1QR0Ng, 0.8rem));
}
._horizontal-s_18582_mrz62_30:not(#\9){
  margin-left:calc(-1 * 1.2rem);
  margin-left:calc(-1 * var(--space-s-2tKeb0, 1.2rem));
  margin-bottom:calc(-1 * 1.2rem);
  margin-bottom:calc(-1 * var(--space-s-2tKeb0, 1.2rem));
}
._horizontal-m_18582_mrz62_34:not(#\9){
  margin-left:calc(-1 * 1.6rem);
  margin-left:calc(-1 * var(--space-m-25I9Cr, 1.6rem));
  margin-bottom:calc(-1 * 1.6rem);
  margin-bottom:calc(-1 * var(--space-m-25I9Cr, 1.6rem));
}
._horizontal-l_18582_mrz62_38:not(#\9){
  margin-left:calc(-1 * 2rem);
  margin-left:calc(-1 * var(--space-l-3cXvov, 2rem));
  margin-bottom:calc(-1 * 2rem);
  margin-bottom:calc(-1 * var(--space-l-3cXvov, 2rem));
}
._horizontal-xl_18582_mrz62_42:not(#\9){
  margin-left:calc(-1 * 2.4rem);
  margin-left:calc(-1 * var(--space-xl-3JXLlv, 2.4rem));
  margin-bottom:calc(-1 * 2.4rem);
  margin-bottom:calc(-1 * var(--space-xl-3JXLlv, 2.4rem));
}
._horizontal-xxl_18582_mrz62_46:not(#\9){
  margin-left:calc(-1 * 3.2rem);
  margin-left:calc(-1 * var(--space-xxl-3_q1V_, 3.2rem));
  margin-bottom:calc(-1 * 3.2rem);
  margin-bottom:calc(-1 * var(--space-xxl-3_q1V_, 3.2rem));
}

._child-horizontal-xxxs_18582_mrz62_51:not(#\9){
  margin-left:0.2rem;
  margin-left:var(--space-xxxs-10EX_Z, 0.2rem);
  margin-bottom:0.2rem;
  margin-bottom:var(--space-xxxs-10EX_Z, 0.2rem);
  min-width:1px;
}

._child-horizontal-xxs_18582_mrz62_57:not(#\9){
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
  margin-bottom:0.4rem;
  margin-bottom:var(--space-xxs-137Hig, 0.4rem);
  min-width:1px;
}

._child-horizontal-xs_18582_mrz62_63:not(#\9){
  margin-left:0.8rem;
  margin-left:var(--space-xs-1QR0Ng, 0.8rem);
  margin-bottom:0.8rem;
  margin-bottom:var(--space-xs-1QR0Ng, 0.8rem);
  min-width:1px;
}

._child-horizontal-s_18582_mrz62_69:not(#\9){
  margin-left:1.2rem;
  margin-left:var(--space-s-2tKeb0, 1.2rem);
  margin-bottom:1.2rem;
  margin-bottom:var(--space-s-2tKeb0, 1.2rem);
  min-width:1px;
}

._child-horizontal-m_18582_mrz62_75:not(#\9){
  margin-left:1.6rem;
  margin-left:var(--space-m-25I9Cr, 1.6rem);
  margin-bottom:1.6rem;
  margin-bottom:var(--space-m-25I9Cr, 1.6rem);
  min-width:1px;
}

._child-horizontal-l_18582_mrz62_81:not(#\9){
  margin-left:2rem;
  margin-left:var(--space-l-3cXvov, 2rem);
  margin-bottom:2rem;
  margin-bottom:var(--space-l-3cXvov, 2rem);
  min-width:1px;
}

._child-horizontal-xl_18582_mrz62_87:not(#\9){
  margin-left:2.4rem;
  margin-left:var(--space-xl-3JXLlv, 2.4rem);
  margin-bottom:2.4rem;
  margin-bottom:var(--space-xl-3JXLlv, 2.4rem);
  min-width:1px;
}

._child-horizontal-xxl_18582_mrz62_93:not(#\9){
  margin-left:3.2rem;
  margin-left:var(--space-xxl-3_q1V_, 3.2rem);
  margin-bottom:3.2rem;
  margin-bottom:var(--space-xxl-3_q1V_, 3.2rem);
  min-width:1px;
}
._vertical_18582_mrz62_102:not(#\9){
  flex-direction:column;
}

._child-vertical-xxxs_18582_mrz62_106:not(#\9):not(:last-child){
  margin-bottom:0.2rem;
  margin-bottom:var(--space-xxxs-10EX_Z, 0.2rem);
}

._child-vertical-xxs_18582_mrz62_110:not(#\9):not(:last-child){
  margin-bottom:0.4rem;
  margin-bottom:var(--space-xxs-137Hig, 0.4rem);
}

._child-vertical-xs_18582_mrz62_114:not(#\9):not(:last-child){
  margin-bottom:0.8rem;
  margin-bottom:var(--space-xs-1QR0Ng, 0.8rem);
}

._child-vertical-s_18582_mrz62_118:not(#\9):not(:last-child){
  margin-bottom:1.2rem;
  margin-bottom:var(--space-scaled-s-1sAHT4, 1.2rem);
}

._child-vertical-m_18582_mrz62_122:not(#\9):not(:last-child){
  margin-bottom:1.6rem;
  margin-bottom:var(--space-scaled-m-p3tAne, 1.6rem);
}

._child-vertical-l_18582_mrz62_126:not(#\9):not(:last-child){
  margin-bottom:2rem;
  margin-bottom:var(--space-scaled-l-17GFGI, 2rem);
}

._child-vertical-xl_18582_mrz62_130:not(#\9):not(:last-child){
  margin-bottom:2.4rem;
  margin-bottom:var(--space-scaled-xl-axUbkA, 2.4rem);
}

._child-vertical-xxl_18582_mrz62_134:not(#\9):not(:last-child){
  margin-bottom:3.2rem;
  margin-bottom:var(--space-scaled-xxl-238u6Z, 3.2rem);
}
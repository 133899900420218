._badge_1yjyg_16mxx_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  display:inline-block;
  border-radius:1.6rem;
  padding:0 0.8rem;
  padding:0 var(--space-xs-1QR0Ng, 0.8rem);
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
}
._badge_1yjyg_16mxx_3._badge-color-grey_1yjyg_16mxx_19:not(#\9){
  background-color:#545b64;
  background-color:var(--color-background-notification-grey-3n2Isj, #545b64);
}
._badge_1yjyg_16mxx_3._badge-color-green_1yjyg_16mxx_22:not(#\9){
  background-color:#1d8102;
  background-color:var(--color-background-notification-green-1ceXEb, #1d8102);
}
._badge_1yjyg_16mxx_3._badge-color-blue_1yjyg_16mxx_25:not(#\9){
  background-color:#0073bb;
  background-color:var(--color-background-notification-blue-Y4F8hz, #0073bb);
}
._badge_1yjyg_16mxx_3._badge-color-red_1yjyg_16mxx_28:not(#\9){
  background-color:#d13212;
  background-color:var(--color-background-notification-red-3bUMsg, #d13212);
}
._placeholder_dwuol_32cef_3:not(#\9){
  color:#aab7b8;
  color:var(--color-text-input-placeholder-TVd7uP, #aab7b8);
  font-style:italic;
}

._item_dwuol_32cef_8:not(#\9){
  display:flex;
  align-items:center;
}
._item_dwuol_32cef_8 > ._checkbox_dwuol_32cef_12:not(#\9){
  position:relative;
  min-height:1.4rem;
  min-width:1.4rem;
  height:1.4rem;
  width:1.4rem;
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
}

._filter_dwuol_32cef_21:not(#\9){
  z-index:3;
  flex-shrink:0;
}

._trigger_dwuol_32cef_26:not(#\9){
  white-space:nowrap;
  overflow:hidden;
  text-overflow:ellipsis;
}
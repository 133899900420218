._root_18wu0_q7ru3_3:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._box_18wu0_q7ru3_16._p-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._b-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._strong-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._p-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._code-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._pre-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._samp-variant_18wu0_q7ru3_16._color-default_18wu0_q7ru3_16:not(#\9){
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
}

._box_18wu0_q7ru3_16._h1-variant_18wu0_q7ru3_20._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._h2-variant_18wu0_q7ru3_20._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._h3-variant_18wu0_q7ru3_20._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._h4-variant_18wu0_q7ru3_20._color-default_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._h5-variant_18wu0_q7ru3_20._color-default_18wu0_q7ru3_16:not(#\9){
  color:#16191f;
  color:var(--color-text-heading-default-2S6vEC, #16191f);
}

._box_18wu0_q7ru3_16._small-variant_18wu0_q7ru3_24._color-default_18wu0_q7ru3_16:not(#\9){
  color:#687078;
  color:var(--color-text-small-2ZkiuH, #687078);
}

._box_18wu0_q7ru3_16._a-variant_18wu0_q7ru3_28._color-default_18wu0_q7ru3_16:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
}

._box_18wu0_q7ru3_16._small-variant_18wu0_q7ru3_24._font-size-default_18wu0_q7ru3_32:not(#\9), ._box_18wu0_q7ru3_16._code-variant_18wu0_q7ru3_16._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}

._box_18wu0_q7ru3_16._p-variant_18wu0_q7ru3_16._font-size-default_18wu0_q7ru3_32:not(#\9), ._box_18wu0_q7ru3_16._b-variant_18wu0_q7ru3_16._font-size-default_18wu0_q7ru3_32:not(#\9), ._box_18wu0_q7ru3_16._strong-variant_18wu0_q7ru3_16._font-size-default_18wu0_q7ru3_32:not(#\9), ._box_18wu0_q7ru3_16._pre-variant_18wu0_q7ru3_16._font-size-default_18wu0_q7ru3_32:not(#\9), ._box_18wu0_q7ru3_16._samp-variant_18wu0_q7ru3_16._font-size-default_18wu0_q7ru3_32:not(#\9), ._box_18wu0_q7ru3_16._a-variant_18wu0_q7ru3_28._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
}

._box_18wu0_q7ru3_16._h5-variant_18wu0_q7ru3_20._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}

._box_18wu0_q7ru3_16._h4-variant_18wu0_q7ru3_20._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}

._box_18wu0_q7ru3_16._h3-variant_18wu0_q7ru3_20._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}

._box_18wu0_q7ru3_16._h2-variant_18wu0_q7ru3_20._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}

._box_18wu0_q7ru3_16._h1-variant_18wu0_q7ru3_20._font-size-default_18wu0_q7ru3_32:not(#\9){
  font-size:2.8rem;
  font-size:var(--size-font-heading-500-14QHat, 2.8rem);
  line-height:3.6rem;
  line-height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
}

._box_18wu0_q7ru3_16._h1-variant_18wu0_q7ru3_20._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._h3-variant_18wu0_q7ru3_20._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._h5-variant_18wu0_q7ru3_20._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._p-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._p-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._code-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._pre-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._samp-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._small-variant_18wu0_q7ru3_24._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._a-variant_18wu0_q7ru3_28._font-weight-default_18wu0_q7ru3_67:not(#\9){
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}

._box_18wu0_q7ru3_16._h2-variant_18wu0_q7ru3_20._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._h4-variant_18wu0_q7ru3_20._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._strong-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9), ._box_18wu0_q7ru3_16._b-variant_18wu0_q7ru3_16._font-weight-default_18wu0_q7ru3_67:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._box_18wu0_q7ru3_16._h1-variant_18wu0_q7ru3_20:not(#\9), ._box_18wu0_q7ru3_16._h2-variant_18wu0_q7ru3_20:not(#\9), ._box_18wu0_q7ru3_16._h3-variant_18wu0_q7ru3_20:not(#\9), ._box_18wu0_q7ru3_16._h4-variant_18wu0_q7ru3_20:not(#\9), ._box_18wu0_q7ru3_16._h5-variant_18wu0_q7ru3_20:not(#\9), ._box_18wu0_q7ru3_16._p-variant_18wu0_q7ru3_16:not(#\9){
  margin:0;
  text-decoration:none;
  padding:0.4rem 0;
  padding:var(--space-xxs-137Hig, 0.4rem) 0;
}
._box_18wu0_q7ru3_16._small-variant_18wu0_q7ru3_24:not(#\9){
  display:inline-block;
}
._box_18wu0_q7ru3_16._code-variant_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._pre-variant_18wu0_q7ru3_16:not(#\9), ._box_18wu0_q7ru3_16._samp-variant_18wu0_q7ru3_16:not(#\9){
  font-family:Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace;
  font-family:var(--font-fontFamily-monospace-1bAn4w, Monaco, Menlo, Consolas, "Courier Prime", Courier, "Courier New", monospace);
}

._box_18wu0_q7ru3_16._color-inverted_18wu0_q7ru3_87:not(#\9){
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
}
._box_18wu0_q7ru3_16._color-text-label_18wu0_q7ru3_90:not(#\9){
  color:#545b64;
  color:var(--color-text-label-3qT4rX, #545b64);
}
._box_18wu0_q7ru3_16._color-text-body-secondary_18wu0_q7ru3_93:not(#\9){
  color:#545b64;
  color:var(--color-text-body-secondary-1oBHNI, #545b64);
}
._box_18wu0_q7ru3_16._color-text-status-error_18wu0_q7ru3_96:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._box_18wu0_q7ru3_16._color-text-status-success_18wu0_q7ru3_99:not(#\9){
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._box_18wu0_q7ru3_16._color-text-status-info_18wu0_q7ru3_102:not(#\9){
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._box_18wu0_q7ru3_16._color-text-status-inactive_18wu0_q7ru3_105:not(#\9){
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._box_18wu0_q7ru3_16._color-inherit_18wu0_q7ru3_108:not(#\9){
  color:inherit;
}
._box_18wu0_q7ru3_16._font-size-body-s_18wu0_q7ru3_111:not(#\9){
  font-size:1.2rem;
  font-size:var(--size-font-body-50-24EEro, 1.2rem);
  line-height:1.6rem;
  line-height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
}
._box_18wu0_q7ru3_16._font-size-body-m_18wu0_q7ru3_115:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
}
._box_18wu0_q7ru3_16._font-size-heading-xs_18wu0_q7ru3_119:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._box_18wu0_q7ru3_16._font-size-heading-s_18wu0_q7ru3_123:not(#\9){
  font-size:1.6rem;
  font-size:var(--size-font-heading-100-2nJwAn, 1.6rem);
  line-height:2rem;
  line-height:var(--size-lineHeight-heading-100-3JwfI_, 2rem);
}
._box_18wu0_q7ru3_16._font-size-heading-m_18wu0_q7ru3_127:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._box_18wu0_q7ru3_16._font-size-heading-l_18wu0_q7ru3_131:not(#\9){
  font-size:1.8rem;
  font-size:var(--size-font-heading-200-1ZSlHP, 1.8rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-heading-200-3TEa20, 2.2rem);
}
._box_18wu0_q7ru3_16._font-size-heading-xl_18wu0_q7ru3_135:not(#\9){
  font-size:2.8rem;
  font-size:var(--size-font-heading-500-14QHat, 2.8rem);
  line-height:3.6rem;
  line-height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
}
._box_18wu0_q7ru3_16._font-size-display-l_18wu0_q7ru3_139:not(#\9){
  font-size:4.4rem;
  font-size:var(--size-font-display-200-AStMo2, 4.4rem);
  line-height:5.6rem;
  line-height:var(--size-lineHeight-display-200-2-t5qR, 5.6rem);
}
._box_18wu0_q7ru3_16._font-weight-light_18wu0_q7ru3_143:not(#\9){
  font-weight:300;
  font-weight:var(--font-fontWeight-lighter-1ehTUD, 300);
}
._box_18wu0_q7ru3_16._font-weight-normal_18wu0_q7ru3_146:not(#\9){
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
}
._box_18wu0_q7ru3_16._font-weight-bold_18wu0_q7ru3_149:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._t-left_18wu0_q7ru3_153:not(#\9){
  text-align:left;
}

._t-right_18wu0_q7ru3_157:not(#\9){
  text-align:right;
}

._t-center_18wu0_q7ru3_161:not(#\9){
  text-align:center;
}
._box_18wu0_q7ru3_16._p-n_18wu0_q7ru3_168:not(#\9){
  padding:0 0;
  padding:var(--space-scaled-none-1ld6ZS, 0) var(--space-none-2rGI2r, 0);
}

._box_18wu0_q7ru3_16._p-top-n_18wu0_q7ru3_172:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-n_18wu0_q7ru3_173:not(#\9){
  padding-top:0;
  padding-top:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_q7ru3_16._p-right-n_18wu0_q7ru3_177:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-n_18wu0_q7ru3_178:not(#\9){
  padding-right:0;
  padding-right:var(--space-none-2rGI2r, 0);
}

._box_18wu0_q7ru3_16._p-bottom-n_18wu0_q7ru3_182:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-n_18wu0_q7ru3_173:not(#\9){
  padding-bottom:0;
  padding-bottom:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_q7ru3_16._p-left-n_18wu0_q7ru3_187:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-n_18wu0_q7ru3_178:not(#\9){
  padding-left:0;
  padding-left:var(--space-none-2rGI2r, 0);
}

._box_18wu0_q7ru3_16._p-xxxs_18wu0_q7ru3_192:not(#\9){
  padding:0.2rem 0.2rem;
  padding:var(--space-scaled-xxxs-1yX_gQ, 0.2rem) var(--space-xxxs-10EX_Z, 0.2rem);
}

._box_18wu0_q7ru3_16._p-top-xxxs_18wu0_q7ru3_196:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xxxs_18wu0_q7ru3_197:not(#\9){
  padding-top:0.2rem;
  padding-top:var(--space-scaled-xxxs-1yX_gQ, 0.2rem);
}

._box_18wu0_q7ru3_16._p-right-xxxs_18wu0_q7ru3_201:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xxxs_18wu0_q7ru3_202:not(#\9){
  padding-right:0.2rem;
  padding-right:var(--space-xxxs-10EX_Z, 0.2rem);
}

._box_18wu0_q7ru3_16._p-bottom-xxxs_18wu0_q7ru3_206:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xxxs_18wu0_q7ru3_197:not(#\9){
  padding-bottom:0.2rem;
  padding-bottom:var(--space-scaled-xxxs-1yX_gQ, 0.2rem);
}

._box_18wu0_q7ru3_16._p-left-xxxs_18wu0_q7ru3_211:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xxxs_18wu0_q7ru3_202:not(#\9){
  padding-left:0.2rem;
  padding-left:var(--space-xxxs-10EX_Z, 0.2rem);
}

._box_18wu0_q7ru3_16._p-xxs_18wu0_q7ru3_216:not(#\9){
  padding:0.4rem 0.4rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem);
}

._box_18wu0_q7ru3_16._p-top-xxs_18wu0_q7ru3_220:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xxs_18wu0_q7ru3_221:not(#\9){
  padding-top:0.4rem;
  padding-top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._box_18wu0_q7ru3_16._p-right-xxs_18wu0_q7ru3_225:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xxs_18wu0_q7ru3_226:not(#\9){
  padding-right:0.4rem;
  padding-right:var(--space-xxs-137Hig, 0.4rem);
}

._box_18wu0_q7ru3_16._p-bottom-xxs_18wu0_q7ru3_230:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xxs_18wu0_q7ru3_221:not(#\9){
  padding-bottom:0.4rem;
  padding-bottom:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._box_18wu0_q7ru3_16._p-left-xxs_18wu0_q7ru3_235:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xxs_18wu0_q7ru3_226:not(#\9){
  padding-left:0.4rem;
  padding-left:var(--space-xxs-137Hig, 0.4rem);
}

._box_18wu0_q7ru3_16._p-xs_18wu0_q7ru3_240:not(#\9){
  padding:0.8rem 0.8rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-xs-1QR0Ng, 0.8rem);
}

._box_18wu0_q7ru3_16._p-top-xs_18wu0_q7ru3_244:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xs_18wu0_q7ru3_245:not(#\9){
  padding-top:0.8rem;
  padding-top:var(--space-scaled-xs-19adjU, 0.8rem);
}

._box_18wu0_q7ru3_16._p-right-xs_18wu0_q7ru3_249:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xs_18wu0_q7ru3_250:not(#\9){
  padding-right:0.8rem;
  padding-right:var(--space-xs-1QR0Ng, 0.8rem);
}

._box_18wu0_q7ru3_16._p-bottom-xs_18wu0_q7ru3_254:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xs_18wu0_q7ru3_245:not(#\9){
  padding-bottom:0.8rem;
  padding-bottom:var(--space-scaled-xs-19adjU, 0.8rem);
}

._box_18wu0_q7ru3_16._p-left-xs_18wu0_q7ru3_259:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xs_18wu0_q7ru3_250:not(#\9){
  padding-left:0.8rem;
  padding-left:var(--space-xs-1QR0Ng, 0.8rem);
}

._box_18wu0_q7ru3_16._p-s_18wu0_q7ru3_264:not(#\9){
  padding:1.2rem 1.2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-s-2tKeb0, 1.2rem);
}

._box_18wu0_q7ru3_16._p-top-s_18wu0_q7ru3_268:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-s_18wu0_q7ru3_269:not(#\9){
  padding-top:1.2rem;
  padding-top:var(--space-scaled-s-1sAHT4, 1.2rem);
}

._box_18wu0_q7ru3_16._p-right-s_18wu0_q7ru3_273:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-s_18wu0_q7ru3_274:not(#\9){
  padding-right:1.2rem;
  padding-right:var(--space-s-2tKeb0, 1.2rem);
}

._box_18wu0_q7ru3_16._p-bottom-s_18wu0_q7ru3_278:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-s_18wu0_q7ru3_269:not(#\9){
  padding-bottom:1.2rem;
  padding-bottom:var(--space-scaled-s-1sAHT4, 1.2rem);
}

._box_18wu0_q7ru3_16._p-left-s_18wu0_q7ru3_283:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-s_18wu0_q7ru3_274:not(#\9){
  padding-left:1.2rem;
  padding-left:var(--space-s-2tKeb0, 1.2rem);
}

._box_18wu0_q7ru3_16._p-m_18wu0_q7ru3_288:not(#\9){
  padding:1.6rem 1.6rem;
  padding:var(--space-scaled-m-p3tAne, 1.6rem) var(--space-m-25I9Cr, 1.6rem);
}

._box_18wu0_q7ru3_16._p-top-m_18wu0_q7ru3_292:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-m_18wu0_q7ru3_293:not(#\9){
  padding-top:1.6rem;
  padding-top:var(--space-scaled-m-p3tAne, 1.6rem);
}

._box_18wu0_q7ru3_16._p-right-m_18wu0_q7ru3_297:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-m_18wu0_q7ru3_298:not(#\9){
  padding-right:1.6rem;
  padding-right:var(--space-m-25I9Cr, 1.6rem);
}

._box_18wu0_q7ru3_16._p-bottom-m_18wu0_q7ru3_302:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-m_18wu0_q7ru3_293:not(#\9){
  padding-bottom:1.6rem;
  padding-bottom:var(--space-scaled-m-p3tAne, 1.6rem);
}

._box_18wu0_q7ru3_16._p-left-m_18wu0_q7ru3_307:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-m_18wu0_q7ru3_298:not(#\9){
  padding-left:1.6rem;
  padding-left:var(--space-m-25I9Cr, 1.6rem);
}

._box_18wu0_q7ru3_16._p-l_18wu0_q7ru3_187:not(#\9){
  padding:2rem 2rem;
  padding:var(--space-scaled-l-17GFGI, 2rem) var(--space-l-3cXvov, 2rem);
}

._box_18wu0_q7ru3_16._p-top-l_18wu0_q7ru3_316:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-l_18wu0_q7ru3_317:not(#\9){
  padding-top:2rem;
  padding-top:var(--space-scaled-l-17GFGI, 2rem);
}

._box_18wu0_q7ru3_16._p-right-l_18wu0_q7ru3_321:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-l_18wu0_q7ru3_322:not(#\9){
  padding-right:2rem;
  padding-right:var(--space-l-3cXvov, 2rem);
}

._box_18wu0_q7ru3_16._p-bottom-l_18wu0_q7ru3_326:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-l_18wu0_q7ru3_317:not(#\9){
  padding-bottom:2rem;
  padding-bottom:var(--space-scaled-l-17GFGI, 2rem);
}

._box_18wu0_q7ru3_16._p-left-l_18wu0_q7ru3_331:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-l_18wu0_q7ru3_322:not(#\9){
  padding-left:2rem;
  padding-left:var(--space-l-3cXvov, 2rem);
}

._box_18wu0_q7ru3_16._p-xl_18wu0_q7ru3_336:not(#\9){
  padding:2.4rem 2.4rem;
  padding:var(--space-scaled-xl-axUbkA, 2.4rem) var(--space-xl-3JXLlv, 2.4rem);
}

._box_18wu0_q7ru3_16._p-top-xl_18wu0_q7ru3_340:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xl_18wu0_q7ru3_341:not(#\9){
  padding-top:2.4rem;
  padding-top:var(--space-scaled-xl-axUbkA, 2.4rem);
}

._box_18wu0_q7ru3_16._p-right-xl_18wu0_q7ru3_345:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xl_18wu0_q7ru3_346:not(#\9){
  padding-right:2.4rem;
  padding-right:var(--space-xl-3JXLlv, 2.4rem);
}

._box_18wu0_q7ru3_16._p-bottom-xl_18wu0_q7ru3_350:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xl_18wu0_q7ru3_341:not(#\9){
  padding-bottom:2.4rem;
  padding-bottom:var(--space-scaled-xl-axUbkA, 2.4rem);
}

._box_18wu0_q7ru3_16._p-left-xl_18wu0_q7ru3_355:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xl_18wu0_q7ru3_346:not(#\9){
  padding-left:2.4rem;
  padding-left:var(--space-xl-3JXLlv, 2.4rem);
}

._box_18wu0_q7ru3_16._p-xxl_18wu0_q7ru3_360:not(#\9){
  padding:3.2rem 3.2rem;
  padding:var(--space-scaled-xxl-238u6Z, 3.2rem) var(--space-xxl-3_q1V_, 3.2rem);
}

._box_18wu0_q7ru3_16._p-top-xxl_18wu0_q7ru3_364:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xxl_18wu0_q7ru3_365:not(#\9){
  padding-top:3.2rem;
  padding-top:var(--space-scaled-xxl-238u6Z, 3.2rem);
}

._box_18wu0_q7ru3_16._p-right-xxl_18wu0_q7ru3_369:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xxl_18wu0_q7ru3_370:not(#\9){
  padding-right:3.2rem;
  padding-right:var(--space-xxl-3_q1V_, 3.2rem);
}

._box_18wu0_q7ru3_16._p-bottom-xxl_18wu0_q7ru3_374:not(#\9),
._box_18wu0_q7ru3_16._p-vertical-xxl_18wu0_q7ru3_365:not(#\9){
  padding-bottom:3.2rem;
  padding-bottom:var(--space-scaled-xxl-238u6Z, 3.2rem);
}

._box_18wu0_q7ru3_16._p-left-xxl_18wu0_q7ru3_379:not(#\9),
._box_18wu0_q7ru3_16._p-horizontal-xxl_18wu0_q7ru3_370:not(#\9){
  padding-left:3.2rem;
  padding-left:var(--space-xxl-3_q1V_, 3.2rem);
}

._box_18wu0_q7ru3_16._m-n_18wu0_q7ru3_384:not(#\9){
  margin:0 0;
  margin:var(--space-scaled-none-1ld6ZS, 0) var(--space-none-2rGI2r, 0);
}

._box_18wu0_q7ru3_16._m-top-n_18wu0_q7ru3_388:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-n_18wu0_q7ru3_389:not(#\9){
  margin-top:0;
  margin-top:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_q7ru3_16._m-right-n_18wu0_q7ru3_393:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-n_18wu0_q7ru3_394:not(#\9){
  margin-right:0;
  margin-right:var(--space-none-2rGI2r, 0);
}

._box_18wu0_q7ru3_16._m-bottom-n_18wu0_q7ru3_398:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-n_18wu0_q7ru3_389:not(#\9){
  margin-bottom:0;
  margin-bottom:var(--space-scaled-none-1ld6ZS, 0);
}

._box_18wu0_q7ru3_16._m-left-n_18wu0_q7ru3_403:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-n_18wu0_q7ru3_394:not(#\9){
  margin-left:0;
  margin-left:var(--space-none-2rGI2r, 0);
}

._box_18wu0_q7ru3_16._m-xxxs_18wu0_q7ru3_408:not(#\9){
  margin:0.2rem 0.2rem;
  margin:var(--space-scaled-xxxs-1yX_gQ, 0.2rem) var(--space-xxxs-10EX_Z, 0.2rem);
}

._box_18wu0_q7ru3_16._m-top-xxxs_18wu0_q7ru3_412:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xxxs_18wu0_q7ru3_413:not(#\9){
  margin-top:0.2rem;
  margin-top:var(--space-scaled-xxxs-1yX_gQ, 0.2rem);
}

._box_18wu0_q7ru3_16._m-right-xxxs_18wu0_q7ru3_417:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xxxs_18wu0_q7ru3_418:not(#\9){
  margin-right:0.2rem;
  margin-right:var(--space-xxxs-10EX_Z, 0.2rem);
}

._box_18wu0_q7ru3_16._m-bottom-xxxs_18wu0_q7ru3_422:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xxxs_18wu0_q7ru3_413:not(#\9){
  margin-bottom:0.2rem;
  margin-bottom:var(--space-scaled-xxxs-1yX_gQ, 0.2rem);
}

._box_18wu0_q7ru3_16._m-left-xxxs_18wu0_q7ru3_427:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xxxs_18wu0_q7ru3_418:not(#\9){
  margin-left:0.2rem;
  margin-left:var(--space-xxxs-10EX_Z, 0.2rem);
}

._box_18wu0_q7ru3_16._m-xxs_18wu0_q7ru3_432:not(#\9){
  margin:0.4rem 0.4rem;
  margin:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem);
}

._box_18wu0_q7ru3_16._m-top-xxs_18wu0_q7ru3_436:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xxs_18wu0_q7ru3_437:not(#\9){
  margin-top:0.4rem;
  margin-top:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._box_18wu0_q7ru3_16._m-right-xxs_18wu0_q7ru3_441:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xxs_18wu0_q7ru3_442:not(#\9){
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
}

._box_18wu0_q7ru3_16._m-bottom-xxs_18wu0_q7ru3_446:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xxs_18wu0_q7ru3_437:not(#\9){
  margin-bottom:0.4rem;
  margin-bottom:var(--space-scaled-xxs-wz9S8K, 0.4rem);
}

._box_18wu0_q7ru3_16._m-left-xxs_18wu0_q7ru3_451:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xxs_18wu0_q7ru3_442:not(#\9){
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}

._box_18wu0_q7ru3_16._m-xs_18wu0_q7ru3_456:not(#\9){
  margin:0.8rem 0.8rem;
  margin:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-xs-1QR0Ng, 0.8rem);
}

._box_18wu0_q7ru3_16._m-top-xs_18wu0_q7ru3_460:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xs_18wu0_q7ru3_461:not(#\9){
  margin-top:0.8rem;
  margin-top:var(--space-scaled-xs-19adjU, 0.8rem);
}

._box_18wu0_q7ru3_16._m-right-xs_18wu0_q7ru3_465:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xs_18wu0_q7ru3_466:not(#\9){
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
}

._box_18wu0_q7ru3_16._m-bottom-xs_18wu0_q7ru3_470:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xs_18wu0_q7ru3_461:not(#\9){
  margin-bottom:0.8rem;
  margin-bottom:var(--space-scaled-xs-19adjU, 0.8rem);
}

._box_18wu0_q7ru3_16._m-left-xs_18wu0_q7ru3_475:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xs_18wu0_q7ru3_466:not(#\9){
  margin-left:0.8rem;
  margin-left:var(--space-xs-1QR0Ng, 0.8rem);
}

._box_18wu0_q7ru3_16._m-s_18wu0_q7ru3_480:not(#\9){
  margin:1.2rem 1.2rem;
  margin:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-s-2tKeb0, 1.2rem);
}

._box_18wu0_q7ru3_16._m-top-s_18wu0_q7ru3_484:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-s_18wu0_q7ru3_485:not(#\9){
  margin-top:1.2rem;
  margin-top:var(--space-scaled-s-1sAHT4, 1.2rem);
}

._box_18wu0_q7ru3_16._m-right-s_18wu0_q7ru3_489:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-s_18wu0_q7ru3_490:not(#\9){
  margin-right:1.2rem;
  margin-right:var(--space-s-2tKeb0, 1.2rem);
}

._box_18wu0_q7ru3_16._m-bottom-s_18wu0_q7ru3_494:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-s_18wu0_q7ru3_485:not(#\9){
  margin-bottom:1.2rem;
  margin-bottom:var(--space-scaled-s-1sAHT4, 1.2rem);
}

._box_18wu0_q7ru3_16._m-left-s_18wu0_q7ru3_499:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-s_18wu0_q7ru3_490:not(#\9){
  margin-left:1.2rem;
  margin-left:var(--space-s-2tKeb0, 1.2rem);
}

._box_18wu0_q7ru3_16._m-m_18wu0_q7ru3_504:not(#\9){
  margin:1.6rem 1.6rem;
  margin:var(--space-scaled-m-p3tAne, 1.6rem) var(--space-m-25I9Cr, 1.6rem);
}

._box_18wu0_q7ru3_16._m-top-m_18wu0_q7ru3_508:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-m_18wu0_q7ru3_509:not(#\9){
  margin-top:1.6rem;
  margin-top:var(--space-scaled-m-p3tAne, 1.6rem);
}

._box_18wu0_q7ru3_16._m-right-m_18wu0_q7ru3_513:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-m_18wu0_q7ru3_514:not(#\9){
  margin-right:1.6rem;
  margin-right:var(--space-m-25I9Cr, 1.6rem);
}

._box_18wu0_q7ru3_16._m-bottom-m_18wu0_q7ru3_518:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-m_18wu0_q7ru3_509:not(#\9){
  margin-bottom:1.6rem;
  margin-bottom:var(--space-scaled-m-p3tAne, 1.6rem);
}

._box_18wu0_q7ru3_16._m-left-m_18wu0_q7ru3_523:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-m_18wu0_q7ru3_514:not(#\9){
  margin-left:1.6rem;
  margin-left:var(--space-m-25I9Cr, 1.6rem);
}

._box_18wu0_q7ru3_16._m-l_18wu0_q7ru3_403:not(#\9){
  margin:2rem 2rem;
  margin:var(--space-scaled-l-17GFGI, 2rem) var(--space-l-3cXvov, 2rem);
}

._box_18wu0_q7ru3_16._m-top-l_18wu0_q7ru3_532:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-l_18wu0_q7ru3_533:not(#\9){
  margin-top:2rem;
  margin-top:var(--space-scaled-l-17GFGI, 2rem);
}

._box_18wu0_q7ru3_16._m-right-l_18wu0_q7ru3_537:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-l_18wu0_q7ru3_538:not(#\9){
  margin-right:2rem;
  margin-right:var(--space-l-3cXvov, 2rem);
}

._box_18wu0_q7ru3_16._m-bottom-l_18wu0_q7ru3_542:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-l_18wu0_q7ru3_533:not(#\9){
  margin-bottom:2rem;
  margin-bottom:var(--space-scaled-l-17GFGI, 2rem);
}

._box_18wu0_q7ru3_16._m-left-l_18wu0_q7ru3_547:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-l_18wu0_q7ru3_538:not(#\9){
  margin-left:2rem;
  margin-left:var(--space-l-3cXvov, 2rem);
}

._box_18wu0_q7ru3_16._m-xl_18wu0_q7ru3_552:not(#\9){
  margin:2.4rem 2.4rem;
  margin:var(--space-scaled-xl-axUbkA, 2.4rem) var(--space-xl-3JXLlv, 2.4rem);
}

._box_18wu0_q7ru3_16._m-top-xl_18wu0_q7ru3_556:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xl_18wu0_q7ru3_557:not(#\9){
  margin-top:2.4rem;
  margin-top:var(--space-scaled-xl-axUbkA, 2.4rem);
}

._box_18wu0_q7ru3_16._m-right-xl_18wu0_q7ru3_561:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xl_18wu0_q7ru3_562:not(#\9){
  margin-right:2.4rem;
  margin-right:var(--space-xl-3JXLlv, 2.4rem);
}

._box_18wu0_q7ru3_16._m-bottom-xl_18wu0_q7ru3_566:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xl_18wu0_q7ru3_557:not(#\9){
  margin-bottom:2.4rem;
  margin-bottom:var(--space-scaled-xl-axUbkA, 2.4rem);
}

._box_18wu0_q7ru3_16._m-left-xl_18wu0_q7ru3_571:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xl_18wu0_q7ru3_562:not(#\9){
  margin-left:2.4rem;
  margin-left:var(--space-xl-3JXLlv, 2.4rem);
}

._box_18wu0_q7ru3_16._m-xxl_18wu0_q7ru3_576:not(#\9){
  margin:3.2rem 3.2rem;
  margin:var(--space-scaled-xxl-238u6Z, 3.2rem) var(--space-xxl-3_q1V_, 3.2rem);
}

._box_18wu0_q7ru3_16._m-top-xxl_18wu0_q7ru3_580:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xxl_18wu0_q7ru3_581:not(#\9){
  margin-top:3.2rem;
  margin-top:var(--space-scaled-xxl-238u6Z, 3.2rem);
}

._box_18wu0_q7ru3_16._m-right-xxl_18wu0_q7ru3_585:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xxl_18wu0_q7ru3_586:not(#\9){
  margin-right:3.2rem;
  margin-right:var(--space-xxl-3_q1V_, 3.2rem);
}

._box_18wu0_q7ru3_16._m-bottom-xxl_18wu0_q7ru3_590:not(#\9),
._box_18wu0_q7ru3_16._m-vertical-xxl_18wu0_q7ru3_581:not(#\9){
  margin-bottom:3.2rem;
  margin-bottom:var(--space-scaled-xxl-238u6Z, 3.2rem);
}

._box_18wu0_q7ru3_16._m-left-xxl_18wu0_q7ru3_595:not(#\9),
._box_18wu0_q7ru3_16._m-horizontal-xxl_18wu0_q7ru3_586:not(#\9){
  margin-left:3.2rem;
  margin-left:var(--space-xxl-3_q1V_, 3.2rem);
}

._box_18wu0_q7ru3_16._d-block_18wu0_q7ru3_600:not(#\9){
  display:block;
}
._box_18wu0_q7ru3_16._d-inline_18wu0_q7ru3_603:not(#\9){
  display:inline;
}
._box_18wu0_q7ru3_16._d-inline-block_18wu0_q7ru3_606:not(#\9){
  display:inline-block;
}
._box_18wu0_q7ru3_16._d-none_18wu0_q7ru3_609:not(#\9){
  display:none;
}

._f-left_18wu0_q7ru3_613:not(#\9){
  float:left;
}

._f-right_18wu0_q7ru3_617:not(#\9){
  float:right;
}
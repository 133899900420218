._marker_1isd1_1u8xi_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
  border-radius:2px;
  width:1.4rem;
}
._marker--line_1isd1_1u8xi_15:not(#\9){
  height:0.4rem;
}
._marker--rectangle_1isd1_1u8xi_18:not(#\9){
  height:1.4rem;
  border-radius:2px;
}
._marker--dashed_1isd1_1u8xi_22:not(#\9){
  height:0.4rem;
  width:0.6rem;
  margin-right:1.2rem;
}
._marker--dashed_1isd1_1u8xi_22:not(#\9)::after{
  content:"";
  display:block;
  width:0.6rem;
  height:0.4rem;
  margin-left:0.8rem;
  border-radius:2px;
  background-color:inherit;
}
._icon_h11ix_190jh_3:not(#\9){
  display:inline-block;
  vertical-align:top;
}
._icon_h11ix_190jh_3 > svg:not(#\9){
  pointer-events:none;
}
._icon_h11ix_190jh_3 > svg:not(#\9){
  fill:none;
}
._icon_h11ix_190jh_3 > svg *:not(#\9){
  stroke:currentColor;
}
._icon_h11ix_190jh_3 > svg .stroke-linejoin-round:not(#\9){
  stroke-linejoin:round;
}
._icon_h11ix_190jh_3 > svg .stroke-linecap-square:not(#\9){
  stroke-linecap:square;
}
._icon_h11ix_190jh_3 > svg .stroke-linecap-round:not(#\9){
  stroke-linecap:round;
}
._icon_h11ix_190jh_3 > svg .filled:not(#\9){
  fill:currentColor;
}
._icon_h11ix_190jh_3._size-small_h11ix_190jh_28:not(#\9){
  width:1.6rem;
  width:var(--size-icon-normal-2nJwAn, 1.6rem);
  height:1.6rem;
  height:var(--size-lineHeight-body-50-2nJwAn, 1.6rem);
  padding:calc((1.6rem - 1.6rem) / 2) 0;
  padding:calc((var(--size-lineHeight-body-50-2nJwAn, 1.6rem) - var(--size-icon-normal-2nJwAn, 1.6rem)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_190jh_3._size-small_h11ix_190jh_28 > svg:not(#\9),
._icon_h11ix_190jh_3._size-small_h11ix_190jh_28 > img:not(#\9){
  width:1.6rem;
  width:var(--size-icon-normal-2nJwAn, 1.6rem);
  height:1.6rem;
  height:var(--size-icon-normal-2nJwAn, 1.6rem);
  vertical-align:top;
}
._icon_h11ix_190jh_3._size-small_h11ix_190jh_28 > svg:not(#\9),
._icon_h11ix_190jh_3._size-small_h11ix_190jh_28 > svg *:not(#\9){
  stroke-width:2px;
}
._icon_h11ix_190jh_3._size-normal_h11ix_190jh_44:not(#\9){
  width:1.6rem;
  width:var(--size-icon-normal-2nJwAn, 1.6rem);
  height:2.2rem;
  height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  padding:calc((2.2rem - 1.6rem) / 2) 0;
  padding:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - var(--size-icon-normal-2nJwAn, 1.6rem)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_190jh_3._size-normal_h11ix_190jh_44 > svg:not(#\9),
._icon_h11ix_190jh_3._size-normal_h11ix_190jh_44 > img:not(#\9){
  width:1.6rem;
  width:var(--size-icon-normal-2nJwAn, 1.6rem);
  height:1.6rem;
  height:var(--size-icon-normal-2nJwAn, 1.6rem);
  vertical-align:top;
}
._icon_h11ix_190jh_3._size-normal_h11ix_190jh_44 > svg:not(#\9),
._icon_h11ix_190jh_3._size-normal_h11ix_190jh_44 > svg *:not(#\9){
  stroke-width:2px;
}
._icon_h11ix_190jh_3._size-big_h11ix_190jh_60:not(#\9){
  width:3.2rem;
  width:var(--size-icon-big-3huAay, 3.2rem);
  height:3.6rem;
  height:var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem);
  padding:calc((3.6rem - 3.2rem) / 2) 0;
  padding:calc((var(--size-lineHeight-heading-500-3DvmnZ, 3.6rem) - var(--size-icon-big-3huAay, 3.2rem)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_190jh_3._size-big_h11ix_190jh_60 > svg:not(#\9),
._icon_h11ix_190jh_3._size-big_h11ix_190jh_60 > img:not(#\9){
  width:3.2rem;
  width:var(--size-icon-big-3huAay, 3.2rem);
  height:3.2rem;
  height:var(--size-icon-big-3huAay, 3.2rem);
  vertical-align:top;
}
._icon_h11ix_190jh_3._size-big_h11ix_190jh_60 > svg:not(#\9),
._icon_h11ix_190jh_3._size-big_h11ix_190jh_60 > svg *:not(#\9){
  stroke-width:2px;
}
._icon_h11ix_190jh_3._size-large_h11ix_190jh_76:not(#\9){
  width:4.8rem;
  width:var(--size-icon-large-OupcJw, 4.8rem);
  height:5.6rem;
  height:var(--size-lineHeight-display-200-2-t5qR, 5.6rem);
  padding:calc((5.6rem - 4.8rem) / 2) 0;
  padding:calc((var(--size-lineHeight-display-200-2-t5qR, 5.6rem) - var(--size-icon-large-OupcJw, 4.8rem)) / 2) 0;
  box-sizing:border-box;
}
._icon_h11ix_190jh_3._size-large_h11ix_190jh_76 > svg:not(#\9),
._icon_h11ix_190jh_3._size-large_h11ix_190jh_76 > img:not(#\9){
  width:4.8rem;
  width:var(--size-icon-large-OupcJw, 4.8rem);
  height:4.8rem;
  height:var(--size-icon-large-OupcJw, 4.8rem);
  vertical-align:top;
}
._icon_h11ix_190jh_3._size-large_h11ix_190jh_76 > svg:not(#\9),
._icon_h11ix_190jh_3._size-large_h11ix_190jh_76 > svg *:not(#\9){
  stroke-width:1.2px;
}
._icon_h11ix_190jh_3._variant-normal_h11ix_190jh_92:not(#\9){
  color:currentColor;
}
._icon_h11ix_190jh_3._variant-disabled_h11ix_190jh_95:not(#\9){
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
}
._icon_h11ix_190jh_3._variant-inverted_h11ix_190jh_98:not(#\9){
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
}
._icon_h11ix_190jh_3._variant-subtle_h11ix_190jh_101:not(#\9){
  color:#687078;
  color:var(--color-text-icon-subtle-22ofsX, #687078);
}
._icon_h11ix_190jh_3._variant-warning_h11ix_190jh_104:not(#\9){
  color:#d13212;
  color:var(--color-text-status-warning-1jj2sL, #d13212);
}
._icon_h11ix_190jh_3._variant-error_h11ix_190jh_107:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._icon_h11ix_190jh_3._variant-success_h11ix_190jh_110:not(#\9){
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._icon_h11ix_190jh_3._variant-link_h11ix_190jh_113:not(#\9){
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
}
._arrow_lm6vo_woarc_3:not(#\9){
  width:2rem;
  height:1rem;
}
._arrow-outer_lm6vo_woarc_7:not(#\9), ._arrow-inner_lm6vo_woarc_7:not(#\9){
  position:absolute;
  overflow:hidden;
  width:2rem;
  height:1rem;
  top:0;
  left:0;
}
._arrow-outer_lm6vo_woarc_7:not(#\9)::after, ._arrow-inner_lm6vo_woarc_7:not(#\9)::after{
  content:"";
  box-sizing:border-box;
  display:inline-block;
  position:absolute;
  border-radius:2px 0 0 0;
  bottom:0;
  left:0;
  width:1.4rem;
  height:1.4rem;
  transform:rotate(45deg);
  transform-origin:0 100%;
}
._arrow-outer_lm6vo_woarc_7:not(#\9)::after{
  background-color:#0073bb;
  background-color:var(--color-border-status-info-3uW6lA, #0073bb);
}
._arrow-inner_lm6vo_woarc_7:not(#\9){
  top:2px;
}
._arrow-inner_lm6vo_woarc_7:not(#\9)::after{
  border-radius:1px 0 0 0;
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
}
._arrow-position-right-top_lm6vo_woarc_38 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after, ._arrow-position-right-bottom_lm6vo_woarc_38 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after{
  box-shadow:-0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-left-top_lm6vo_woarc_41 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after, ._arrow-position-left-bottom_lm6vo_woarc_41 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after{
  box-shadow:0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-top-center_lm6vo_woarc_44 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after, ._arrow-position-top-responsive_lm6vo_woarc_44 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after{
  box-shadow:-0.71px -0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:-0.71px -0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._arrow-position-bottom-center_lm6vo_woarc_47 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after, ._arrow-position-bottom-responsive_lm6vo_woarc_47 > ._arrow-outer_lm6vo_woarc_7:not(#\9)::after{
  box-shadow:0.71px 0.71px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0.71px 0.71px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}

._root_lm6vo_woarc_51:not(#\9),
._layout-wrapper_lm6vo_woarc_52:not(#\9){
  display:flex;
  flex-direction:column;
}

._close-button_lm6vo_woarc_57:not(#\9){
  position:absolute;
  outline:none;
  right:1.6rem;
  right:var(--space-m-25I9Cr, 1.6rem);
  top:1.5rem;
  top:var(--size-vertical-panel-icon-offset-2Iktcb, 1.5rem);
}

._layout_lm6vo_woarc_52:not(#\9){
  flex:1 1 auto;
  display:flex;
  align-items:stretch;
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
}
._layout-no-scroll_lm6vo_woarc_70:not(#\9){
  overflow:hidden;
}

._layout-main_lm6vo_woarc_74:not(#\9){
  flex:1;
  min-width:0;
  background-color:#f2f3f3;
  background-color:var(--color-background-layout-main-3Bydyi, #f2f3f3);
}
._layout-main-scrollable_lm6vo_woarc_79:not(#\9){
  overflow:auto;
}
._unfocusable_lm6vo_woarc_84 *:not(#\9){
  visibility:hidden;
}

._drawer_lm6vo_woarc_88:not(#\9){
  flex-shrink:0;
  position:relative;
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  word-wrap:break-word;
}
._drawer_lm6vo_woarc_88:not(#\9):not(._drawer-mobile_lm6vo_woarc_94){
  z-index:850;
}
._drawer-closed_lm6vo_woarc_97:not(#\9){
  cursor:pointer;
  min-width:4rem;
}
._drawer-closed_lm6vo_woarc_97._drawer-mobile_lm6vo_woarc_94:not(#\9){
  display:none;
}

._drawer-content_lm6vo_woarc_105:not(#\9){
  position:fixed;
  overflow:auto;
  background-color:#ffffff;
  background-color:var(--color-background-layout-panel-content-1Z_Abk, #ffffff);
}
._drawer-mobile_lm6vo_woarc_94 > ._drawer-content_lm6vo_woarc_105:not(#\9){
  z-index:1001;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
._drawer-closed_lm6vo_woarc_97 > ._drawer-content_lm6vo_woarc_105:not(#\9){
  width:4rem;
}
._drawer-closed_lm6vo_woarc_97 > ._drawer-content_lm6vo_woarc_105:not(#\9):hover{
  background:#eaeded;
  background:var(--color-background-layout-panel-hover-38loDV, #eaeded);
}
._drawer-content_lm6vo_woarc_105 > [aria-hidden=true]:not(#\9){
  display:none;
}

._toggle_lm6vo_woarc_127:not(#\9){
  box-sizing:border-box;
  padding:0.4rem 0.7rem;
  padding:var(--space-xxs-137Hig, 0.4rem) 0.7rem;
}

._breadcrumbs-desktop_lm6vo_woarc_132:not(#\9){
  padding:1.6rem 4rem 1.2rem;
  padding:var(--space-scaled-m-p3tAne, 1.6rem) var(--space-scaled-2x-xxxl-3VX7k3, 4rem) var(--space-scaled-s-1sAHT4, 1.2rem);
}

._content-wrapper_lm6vo_woarc_136:not(#\9){
  padding:0 4rem 2rem;
  padding:0 var(--space-scaled-2x-xxxl-3VX7k3, 4rem) var(--space-scaled-l-17GFGI, 2rem);
}
._content-wrapper-mobile_lm6vo_woarc_139:not(#\9){
  padding-left:2rem;
  padding-left:var(--space-l-3cXvov, 2rem);
  padding-right:2rem;
  padding-right:var(--space-l-3cXvov, 2rem);
}
._content-wrapper_lm6vo_woarc_136:not(#\9):first-child{
  padding-top:1.6rem;
  padding-top:var(--space-scaled-m-p3tAne, 1.6rem);
}

._notifications-sticky_lm6vo_woarc_147:not(#\9){
  top:0;
  position:-webkit-sticky;
  position:sticky;
  z-index:825;
}

._announcement-popover-target_lm6vo_woarc_153:not(#\9){
  position:fixed;
  pointer-events:none;
  transform:translateY(3.2rem);
  transform:translateY(var(--space-xxl-3_q1V_, 3.2rem));
}

._navigation-toggle_lm6vo_woarc_159 > *:not(#\9){
  pointer-events:none;
}

._tools-toggle_lm6vo_woarc_163 > *:not(#\9){
  pointer-events:none;
}
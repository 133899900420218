._column-layout_vj6p7_tlwt5_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13:not(#\9){
  margin:calc(2rem / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / -2);
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-no-gutters_vj6p7_tlwt5_16:not(#\9){
  margin:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19:not(#\9){
  margin:calc(-1 * 2rem);
  margin:calc(-1 * var(--space-grid-gutter-17GFGI, 2rem));
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19 > *:not(#\9){
  border-left:1px solid #eaeded;
  border-left:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-breakpoint-default_vj6p7_tlwt5_25 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-1_vj6p7_tlwt5_28._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-1_vj6p7_tlwt5_28._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(1n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-2_vj6p7_tlwt5_34._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-2_vj6p7_tlwt5_34._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(3n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(2n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-variant-text-grid_vj6p7_tlwt5_19._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(4n+1){
  border-left-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13:not(#\9):not(._grid-no-gutters_vj6p7_tlwt5_16) > *{
  padding:calc(2rem / 2);
  padding:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13:not(#\9):not(._grid-no-gutters_vj6p7_tlwt5_16)._grid-variant-text-grid_vj6p7_tlwt5_19 > *{
  padding:0 2rem;
  padding:0 var(--space-grid-gutter-17GFGI, 2rem);
  margin:2rem 0;
  margin:var(--space-grid-gutter-17GFGI, 2rem) 0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59 > *:not(#\9){
  border-right:1px solid #eaeded;
  border-right:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-breakpoint-default_vj6p7_tlwt5_25 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-1_vj6p7_tlwt5_28._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-1_vj6p7_tlwt5_28._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(1n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-2_vj6p7_tlwt5_34._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-2_vj6p7_tlwt5_34._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(3n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-child(2n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-vertical-borders_vj6p7_tlwt5_59._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-child(4n){
  border-right-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89 > *:not(#\9){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89 > *:not(#\9):last-child{
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-2_vj6p7_tlwt5_34._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-2_vj6p7_tlwt5_34._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(2):nth-child(3n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(2):nth-child(3n+2){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-3_vj6p7_tlwt5_40._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(3):nth-child(3n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xxs_vj6p7_tlwt5_28 > *:not(#\9):nth-last-child(2):nth-child(2n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(2):nth-child(4n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(2):nth-child(4n+2){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(2):nth-child(4n+3){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(3):nth-child(4n+1){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(3):nth-child(4n+2){
  border-bottom-width:0;
}
._column-layout_vj6p7_tlwt5_3 > ._grid_vj6p7_tlwt5_13._grid-horizontal-borders_vj6p7_tlwt5_89._grid-columns-4_vj6p7_tlwt5_46._grid-breakpoint-xs_vj6p7_tlwt5_31 > *:not(#\9):nth-last-child(4):nth-child(4n+1){
  border-bottom-width:0;
}

._root_vj6p7_tlwt5_135:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  display:block;
}

._tile-container_vj6p7_tlwt5_149:not(#\9){
  box-sizing:border-box;
  border:1px solid #aab7b8;
  border:1px solid var(--color-border-control-default-27WVST, #aab7b8);
  border-radius:2px;
  padding:0.8rem 1.6rem;
  padding:var(--space-xs-1QR0Ng, 0.8rem) var(--space-scaled-m-p3tAne, 1.6rem);
  display:flex;
  flex-direction:column;
  transition:border-color 90ms linear, background-color 90ms linear;
  transition:border-color var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear), background-color var(--motion-duration-transition-quick-2hvSaJ, 90ms) var(--motion-easing-transition-quick-2nchNs, linear);
}
._tile-container_vj6p7_tlwt5_149._has-metadata_vj6p7_tlwt5_158:not(#\9){
  padding-bottom:1.2rem;
  padding-bottom:var(--space-s-2tKeb0, 1.2rem);
}
._tile-container_vj6p7_tlwt5_149._selected_vj6p7_tlwt5_161:not(#\9){
  border:1px solid #00a1c9;
  border:1px solid var(--color-border-item-selected-6FRHtw, #00a1c9);
  background:#f1faff;
  background:var(--color-background-item-selected-WkeJRF, #f1faff);
}
._tile-container_vj6p7_tlwt5_149._disabled_vj6p7_tlwt5_165:not(#\9){
  border:1px solid transparent;
  border:1px solid var(--color-border-tiles-disabled-1D4yG6, transparent);
  background:#eaeded;
  background:var(--color-background-tiles-disabled-1gYBaj, #eaeded);
}
@media (prefers-reduced-motion: reduce){
  ._tile-container_vj6p7_tlwt5_149:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._tile-container_vj6p7_tlwt5_149:not(#\9){
  animation:none;
  transition:none;
}

._columns_vj6p7_tlwt5_180:not(#\9){
  display:flex;
  flex-wrap:wrap;
  margin:calc(2rem / -2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / -2);
}
._columns_vj6p7_tlwt5_180._column-1_vj6p7_tlwt5_185 > ._tile-container_vj6p7_tlwt5_149:not(#\9){
  margin:calc(2rem / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 2rem);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-1_vj6p7_tlwt5_185 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xs_vj6p7_tlwt5_192:not(#\9){
  flex:0 0;
  flex-basis:calc(100% - 2rem);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-1_vj6p7_tlwt5_185 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xxs_vj6p7_tlwt5_197:not(#\9){
  flex:0 0;
  flex-basis:calc(100% - 2rem);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-2_vj6p7_tlwt5_202 > ._tile-container_vj6p7_tlwt5_149:not(#\9){
  margin:calc(2rem / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 2rem);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-2_vj6p7_tlwt5_202 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xs_vj6p7_tlwt5_192:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 2rem);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(50% - 2rem);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-2_vj6p7_tlwt5_202 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xxs_vj6p7_tlwt5_197:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 2rem);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(50% - 2rem);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-3_vj6p7_tlwt5_219 > ._tile-container_vj6p7_tlwt5_149:not(#\9){
  margin:calc(2rem / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 2rem);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-3_vj6p7_tlwt5_219 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xs_vj6p7_tlwt5_192:not(#\9){
  flex:0 0;
  flex-basis:calc(33.3333333333% - 2rem);
  flex-basis:calc(33.3333333333% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(33.3333333333% - 2rem);
  max-width:calc(33.3333333333% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-3_vj6p7_tlwt5_219 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xxs_vj6p7_tlwt5_197:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 2rem);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(50% - 2rem);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-4_vj6p7_tlwt5_236 > ._tile-container_vj6p7_tlwt5_149:not(#\9){
  margin:calc(2rem / 2);
  margin:calc(var(--space-grid-gutter-17GFGI, 2rem) / 2);
  box-sizing:border-box;
  flex:0 0;
  flex-basis:calc(100% - 2rem);
  flex-basis:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(100% - 2rem);
  max-width:calc(100% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-4_vj6p7_tlwt5_236 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xs_vj6p7_tlwt5_192:not(#\9){
  flex:0 0;
  flex-basis:calc(25% - 2rem);
  flex-basis:calc(25% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(25% - 2rem);
  max-width:calc(25% - var(--space-grid-gutter-17GFGI, 2rem));
}
._columns_vj6p7_tlwt5_180._column-4_vj6p7_tlwt5_236 > ._tile-container_vj6p7_tlwt5_149._breakpoint-xxs_vj6p7_tlwt5_197:not(#\9){
  flex:0 0;
  flex-basis:calc(50% - 2rem);
  flex-basis:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
  max-width:calc(50% - 2rem);
  max-width:calc(50% - var(--space-grid-gutter-17GFGI, 2rem));
}

._control_vj6p7_tlwt5_254:not(#\9){
  flex-grow:1;
  margin-bottom:1.2rem;
  margin-bottom:var(--space-s-2tKeb0, 1.2rem);
}
._control_vj6p7_tlwt5_254._no-image_vj6p7_tlwt5_258:not(#\9){
  margin-bottom:0;
}

._image_vj6p7_tlwt5_262:not(#\9){
  text-align:center;
  flex:none;
}
._image_vj6p7_tlwt5_262 > img:not(#\9){
  max-width:100%;
}
._image_vj6p7_tlwt5_262._disabled_vj6p7_tlwt5_165 > img:not(#\9){
  opacity:0.3;
}
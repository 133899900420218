._root_4yi2u_db2mu_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
}

._input_4yi2u_db2mu_14[data-awsui-focus-visible=true]:not(#\9):focus + div{
  position:relative;
}
._input_4yi2u_db2mu_14[data-awsui-focus-visible=true]:not(#\9):focus + div{
  outline:2px dotted transparent;
  outline-offset:calc(2px - 1px);
}
._input_4yi2u_db2mu_14[data-awsui-focus-visible=true]:not(#\9):focus + div::before{
  content:" ";
  display:block;
  position:absolute;
  left:-2px;
  top:-2px;
  width:calc(100% + 2 * 2px);
  height:calc(100% + 2 * 2px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}

._toggle-control_4yi2u_db2mu_33:not(#\9){
  margin-top:calc((2.2rem - 1.6rem) / 2);
  margin-top:calc((var(--size-lineHeight-body-100-3TEa20, 2.2rem) - 1.6rem) / 2);
  min-height:1.6rem;
  min-width:2.4rem;
  height:1.6rem;
  width:2.4rem;
  background:#545b64;
  background:var(--color-background-toggle-default-3zU3ek, #545b64);
  border-radius:0.8rem;
}
._toggle-control-checked_4yi2u_db2mu_42:not(#\9){
  background:#0073bb;
  background:var(--color-background-control-checked-3uW6lA, #0073bb);
}
._toggle-control-disabled_4yi2u_db2mu_45:not(#\9){
  background:#d5dbdb;
  background:var(--color-background-control-disabled-11nezK, #d5dbdb);
}
._toggle-control-disabled_4yi2u_db2mu_45._toggle-control-checked_4yi2u_db2mu_42:not(#\9){
  background:#99cbe4;
  background:var(--color-background-toggle-checked-disabled-uMVvXl, #99cbe4);
}

._toggle-handle_4yi2u_db2mu_52:not(#\9){
  position:absolute;
  border-radius:0.6rem;
  background:#ffffff;
  background:var(--color-foreground-control-default-3g5IJp, #ffffff);
  box-shadow:1px 1px rgba(0, 0, 0, 0.25);
  width:1.2rem;
  height:1.2rem;
  top:0.2rem;
  left:0.2rem;
  transition:transform 90ms cubic-bezier(0.165, 0.84, 0.44, 1), background-color 90ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1)), background-color var(--motion-duration-fast-oPeLPK, 90ms) var(--motion-easing-ease-out-quart-cJfwJd, cubic-bezier(0.165, 0.84, 0.44, 1));
}
._toggle-handle-checked_4yi2u_db2mu_63:not(#\9){
  transform:translateX(0.8rem);
}
._toggle-handle-disabled_4yi2u_db2mu_66:not(#\9){
  background:#ffffff;
  background:var(--color-foreground-control-disabled-3d6ker, #ffffff);
  box-shadow:none;
}
@media (prefers-reduced-motion: reduce){
  ._toggle-handle_4yi2u_db2mu_52:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._toggle-handle_4yi2u_db2mu_52:not(#\9){
  animation:none;
  transition:none;
}
._button-dropdown_sne0l_12fv1_3:not(#\9){
  display:inline;
}

._items-list-container_sne0l_12fv1_7:not(#\9){
  padding:0;
  margin:0;
  animation:_awsui-motion-fade-in-0_sne0l_12fv1_1 500ms ease-out;
  animation:_awsui-motion-fade-in-0_sne0l_12fv1_1 500ms var(--motion-easing-show-quick-25z_bd, ease-out);
  animation-fill-mode:none;
}
@media (prefers-reduced-motion: reduce){
  ._items-list-container_sne0l_12fv1_7:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._items-list-container_sne0l_12fv1_7:not(#\9){
  animation:none;
  transition:none;
}

._rotate-up_sne0l_12fv1_24:not(#\9){
  transform:rotate(-180deg);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._rotate-up_sne0l_12fv1_24:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._rotate-up_sne0l_12fv1_24:not(#\9){
  animation:none;
  transition:none;
}

._rotate-down_sne0l_12fv1_39:not(#\9){
  transform:rotate(0deg);
  transition:transform 135ms cubic-bezier(0.165, 0.84, 0.44, 1);
  transition:transform var(--motion-duration-rotate-180-TMbCmo, 135ms) var(--motion-easing-rotate-180-2ebTCo, cubic-bezier(0.165, 0.84, 0.44, 1));
}
@media (prefers-reduced-motion: reduce){
  ._rotate-down_sne0l_12fv1_39:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._rotate-down_sne0l_12fv1_39:not(#\9){
  animation:none;
  transition:none;
}
._root_1cbgc_1xy9s_7:not(#\9){
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}
._root_1cbgc_1xy9s_7._status-error_1cbgc_1xy9s_14:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_1cbgc_1xy9s_7._status-warning_1cbgc_1xy9s_17:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_1cbgc_1xy9s_7._status-success_1cbgc_1xy9s_20:not(#\9){
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._root_1cbgc_1xy9s_7._status-info_1cbgc_1xy9s_23:not(#\9){
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._root_1cbgc_1xy9s_7._status-stopped_1cbgc_1xy9s_26:not(#\9){
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_1xy9s_7._status-pending_1cbgc_1xy9s_29:not(#\9){
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_1xy9s_7._status-in-progress_1cbgc_1xy9s_32:not(#\9){
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_1xy9s_7._status-loading_1cbgc_1xy9s_35:not(#\9){
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_1xy9s_7._color-override-red_1cbgc_1xy9s_38:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}
._root_1cbgc_1xy9s_7._color-override-grey_1cbgc_1xy9s_41:not(#\9){
  color:#687078;
  color:var(--color-text-status-inactive-2ZkiuH, #687078);
}
._root_1cbgc_1xy9s_7._color-override-blue_1cbgc_1xy9s_44:not(#\9){
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
._root_1cbgc_1xy9s_7._color-override-green_1cbgc_1xy9s_47:not(#\9){
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}
._icon_1cbgc_1xy9s_50:not(#\9){
  padding-right:0.4rem;
  padding-right:var(--space-xxs-137Hig, 0.4rem);
}

._container_1cbgc_1xy9s_54:not(#\9){
  display:inline-block;
  word-break:break-all;
  word-wrap:break-word;
}
._flashbar_1q84n_1uah8_3:not(#\9),
._flash_1q84n_1uah8_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
}

._flash_1q84n_1uah8_3:not(#\9){
  position:relative;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  padding:0.8rem 0.8rem;
  padding:var(--space-scaled-xs-19adjU, 0.8rem) var(--space-xs-1QR0Ng, 0.8rem);
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0;
  box-sizing:border-box;
  border-top:none;
  color:#fafafa;
  color:var(--color-text-notification-default-JWSlel, #fafafa);
  overflow-wrap:break-word;
  word-wrap:break-word;
}
@media screen and (-ms-high-contrast: active){
  ._flash_1q84n_1uah8_3:not(#\9){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}
._flash_1q84n_1uah8_3:not(#\9):not(:last-child){
  margin-bottom:0.2rem;
  margin-bottom:var(--space-xxxs-10EX_Z, 0.2rem);
}

._flash-body_1q84n_1uah8_38:not(#\9){
  display:flex;
  flex-grow:1;
  min-width:0;
}

._flash-icon_1q84n_1uah8_44:not(#\9){
  margin:0.4rem 0.4rem;
  margin:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem);
  flex:0 0 auto;
}

._flash-message_1q84n_1uah8_49:not(#\9){
  flex:1 1 0%;
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  margin:0.4rem 0.4rem;
  margin:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-xxs-137Hig, 0.4rem);
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
}

._flash-header_1q84n_1uah8_58:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._dismiss-button-wrapper_1q84n_1uah8_66:not(#\9){
  flex:0 0 auto;
  margin-left:0.8rem;
  margin-left:var(--space-xs-1QR0Ng, 0.8rem);
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
}

._action-button-wrapper_1q84n_1uah8_76:not(#\9){
  white-space:nowrap;
  margin-left:1.2rem;
  margin-left:var(--space-s-2tKeb0, 1.2rem);
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
}

._flashbar_1q84n_1uah8_3._breakpoint-default_1q84n_1uah8_82 > ._flash_1q84n_1uah8_3 > ._flash-body_1q84n_1uah8_38:not(#\9){
  display:block;
}
._flashbar_1q84n_1uah8_3._breakpoint-default_1q84n_1uah8_82 > ._flash_1q84n_1uah8_3 > ._flash-body_1q84n_1uah8_38 > ._action-button-wrapper_1q84n_1uah8_76:not(#\9){
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}

._flash-type-success_1q84n_1uah8_93:not(#\9){
  background-color:#1d8102;
  background-color:var(--color-background-notification-green-1ceXEb, #1d8102);
}

._flash-type-error_1q84n_1uah8_97:not(#\9){
  background-color:#d13212;
  background-color:var(--color-background-notification-red-3bUMsg, #d13212);
}

._flash-type-warning_1q84n_1uah8_101:not(#\9){
  background-color:#0073bb;
  background-color:var(--color-background-notification-blue-Y4F8hz, #0073bb);
}

._flash-type-info_1q84n_1uah8_105:not(#\9){
  background-color:#0073bb;
  background-color:var(--color-background-notification-blue-Y4F8hz, #0073bb);
}
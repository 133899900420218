._alert_mx3cw_j1xyn_3:not(#\9){
  animation:_awsui-motion-fade-in_mx3cw_j1xyn_1 180ms ease-out;
  animation:_awsui-motion-fade-in_mx3cw_j1xyn_1 var(--motion-duration-show-paced-2crOWV, 180ms) var(--motion-easing-show-paced-25z_bd, ease-out);
}
@keyframes _awsui-motion-fade-in_mx3cw_j1xyn_1{
  from{
    opacity:0.2;
  }
  to{
    opacity:1;
  }
}
@media (prefers-reduced-motion: reduce){
  ._alert_mx3cw_j1xyn_3:not(#\9){
    animation:none;
    transition:none;
  }
}
.awsui-motion-disabled ._alert_mx3cw_j1xyn_3:not(#\9){
  animation:none;
  transition:none;
}

._root_mx3cw_j1xyn_25:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  word-wrap:break-word;
  max-width:100%;
  overflow:hidden;
  display:block;
}
._root_mx3cw_j1xyn_25._hidden_mx3cw_j1xyn_38:not(#\9){
  display:none;
}

._alert_mx3cw_j1xyn_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  position:relative;
  display:flex;
  justify-content:flex-start;
  align-items:flex-start;
  border-radius:2px;
  border:1px solid;
  padding:1.2rem 2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-l-3cXvov, 2rem);
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
}

._body_mx3cw_j1xyn_60:not(#\9){
  display:flex;
  flex:1 1 0%;
  min-width:0;
}

._header_mx3cw_j1xyn_66:not(#\9){
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
}

._action_mx3cw_j1xyn_70:not(#\9){
  white-space:nowrap;
  margin-left:2rem;
  margin-left:var(--space-l-3cXvov, 2rem);
}

._root_mx3cw_j1xyn_25._breakpoint-default_mx3cw_j1xyn_79 > ._alert_mx3cw_j1xyn_3 > ._body_mx3cw_j1xyn_60:not(#\9){
  display:block;
}
._root_mx3cw_j1xyn_25._breakpoint-default_mx3cw_j1xyn_79 > ._alert_mx3cw_j1xyn_3 > ._body_mx3cw_j1xyn_60 > ._action_mx3cw_j1xyn_70:not(#\9){
  margin-left:0;
}

._message_mx3cw_j1xyn_86:not(#\9){
  flex:1 1 0%;
  margin:0.4rem 0;
  margin:var(--space-xxs-137Hig, 0.4rem) 0;
  min-width:0;
}

._dismiss_mx3cw_j1xyn_96:not(#\9){
  flex:0 0 auto;
  margin-right:calc(-1 * 0.4rem);
  margin-right:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
}

._dismiss-with-button_mx3cw_j1xyn_105:not(#\9){
  margin-left:1.2rem;
  margin-left:var(--space-s-2tKeb0, 1.2rem);
}

._icon_mx3cw_j1xyn_109:not(#\9){
  flex:0 0 auto;
  margin-right:0.8rem;
  margin-right:var(--space-xs-1QR0Ng, 0.8rem);
  margin-top:0.4rem;
  margin-top:var(--space-xxs-137Hig, 0.4rem);
}

._type-error_mx3cw_j1xyn_115:not(#\9){
  border-color:#d13212;
  border-color:var(--color-border-status-error-3bUMsg, #d13212);
  background-color:#fdf3f1;
  background-color:var(--color-background-status-error-3eo6Wg, #fdf3f1);
}
._type-error_mx3cw_j1xyn_115 > ._icon_mx3cw_j1xyn_109:not(#\9){
  color:#d13212;
  color:var(--color-text-status-error-1jj2sL, #d13212);
}

._type-warning_mx3cw_j1xyn_123:not(#\9){
  border-color:#aab7b8;
  border-color:var(--color-border-status-warning-27WVST, #aab7b8);
  background-color:#ffffff;
  background-color:var(--color-background-status-warning-3w9fMz, #ffffff);
}
._type-warning_mx3cw_j1xyn_123 > ._icon_mx3cw_j1xyn_109:not(#\9){
  color:#d13212;
  color:var(--color-text-status-warning-1jj2sL, #d13212);
}

._type-success_mx3cw_j1xyn_131:not(#\9){
  border-color:#1d8102;
  border-color:var(--color-border-status-success-1ceXEb, #1d8102);
  background-color:#f2f8f0;
  background-color:var(--color-background-status-success-_mCzOj, #f2f8f0);
}
._type-success_mx3cw_j1xyn_131 > ._icon_mx3cw_j1xyn_109:not(#\9){
  color:#1d8102;
  color:var(--color-text-status-success-CuCgtA, #1d8102);
}

._type-info_mx3cw_j1xyn_139:not(#\9){
  border-color:#0073bb;
  border-color:var(--color-border-status-info-3uW6lA, #0073bb);
  background-color:#f1faff;
  background-color:var(--color-background-status-info-WkeJRF, #f1faff);
}
._type-info_mx3cw_j1xyn_139 > ._icon_mx3cw_j1xyn_109:not(#\9){
  color:#0073bb;
  color:var(--color-text-status-info-Bc0LvG, #0073bb);
}
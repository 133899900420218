._root_14iqq_owlyp_3:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  display:block;
  word-wrap:break-word;
}
._root_14iqq_owlyp_3:not(#\9):not(._root-disable-content-borders_14iqq_owlyp_14){
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0;
  box-sizing:border-box;
  background-color:#ffffff;
  background-color:var(--color-background-container-content-3w9fMz, #ffffff);
}
@media screen and (-ms-high-contrast: active){
  ._root_14iqq_owlyp_3:not(#\9):not(._root-disable-content-borders_14iqq_owlyp_14){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}

._content_14iqq_owlyp_27:not(#\9):not(._content-no-paddings_14iqq_owlyp_27){
  padding:2rem 2rem;
  padding:var(--space-scaled-l-17GFGI, 2rem) var(--space-l-3cXvov, 2rem);
}
._content_14iqq_owlyp_27:not(#\9):not(._content-no-paddings_14iqq_owlyp_27)._content-has-header_14iqq_owlyp_30{
  padding-top:1.6rem;
  padding-top:var(--space-scaled-m-p3tAne, 1.6rem);
}

._header_14iqq_owlyp_34:not(#\9){
  background-color:#fafafa;
  background-color:var(--color-background-container-header-1OJlgw, #fafafa);
}
._header_14iqq_owlyp_34:not(#\9):not(:empty){
  border-bottom:1px solid #eaeded;
  border-bottom:1px solid var(--color-border-divider-default-38loDV, #eaeded);
}
._header_14iqq_owlyp_34:not(#\9):not(._header-no-paddings_14iqq_owlyp_40){
  padding:1.2rem 2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-l-3cXvov, 2rem);
}
._header-sticky_14iqq_owlyp_43:not(#\9){
  top:0;
  position:-webkit-sticky;
  position:sticky;
  z-index:800;
}
._header-stuck_14iqq_owlyp_48:not(#\9){
  box-shadow:0px 1px 4px -2px rgba(0, 28, 36, 0.5);
  box-shadow:0px 1px 4px -2px var(--color-shadow-default-3LpQcN, rgba(0, 28, 36, 0.5));
}
._root-disable-content-borders_14iqq_owlyp_14 > ._header_14iqq_owlyp_34:not(#\9):not(:empty){
  box-shadow:0 1px 1px 0 rgba(0, 28, 36, 0.3), 1px 1px 1px 0 rgba(0, 28, 36, 0.15), -1px 1px 1px 0 rgba(0, 28, 36, 0.15);
  box-shadow:0 1px 1px 0 var(--color-shadow-medium-3CtI4L, rgba(0, 28, 36, 0.3)), 1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15)), -1px 1px 1px 0 var(--color-shadow-side-2cOaCx, rgba(0, 28, 36, 0.15));
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  border-radius:0;
  box-sizing:border-box;
  border-bottom:none;
}
@media screen and (-ms-high-contrast: active){
  ._root-disable-content-borders_14iqq_owlyp_14 > ._header_14iqq_owlyp_34:not(#\9):not(:empty){
    border:1px solid #eaeded;
    border:1px solid var(--color-border-container-top-1gYBaj, #eaeded);
  }
}

._footer_14iqq_owlyp_64:not(#\9){
  border-top:1px solid #eaeded;
  border-top:1px solid var(--color-border-divider-default-38loDV, #eaeded);
  padding:1.2rem 2rem;
  padding:var(--space-scaled-s-1sAHT4, 1.2rem) var(--space-l-3cXvov, 2rem);
}
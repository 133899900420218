._button_vjswe_1j49f_7:not(#\9){
  border-collapse:separate;
  border-spacing:0;
  caption-side:top;
  cursor:auto;
  direction:ltr;
  empty-cells:show;
  font-family:serif;
  font-size:medium;
  font-style:normal;
  font-variant:normal;
  font-weight:normal;
  font-stretch:normal;
  line-height:normal;
  hyphens:none;
  letter-spacing:normal;
  list-style:disc outside none;
  tab-size:8;
  text-align:left;
  text-align-last:auto;
  text-indent:0;
  text-shadow:none;
  text-transform:none;
  visibility:visible;
  white-space:normal;
  widows:2;
  word-spacing:normal;
  box-sizing:border-box;
  font-size:1.4rem;
  font-size:var(--size-font-body-100-raVHfl, 1.4rem);
  line-height:2.2rem;
  line-height:var(--size-lineHeight-body-100-3TEa20, 2.2rem);
  color:#16191f;
  color:var(--color-text-body-default-mcmuEw, #16191f);
  font-weight:400;
  font-weight:var(--font-fontWeight-normal-3o4GPz, 400);
  font-family:"Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif;
  font-family:var(--font-fontFamily-base-1sydl0, "Amazon Ember", "Helvetica Neue", Roboto, Arial, sans-serif);
  min-width:0;
  -ms-word-break:break-all;
  word-break:break-word;
  border-radius:2px;
  border:1px solid;
  padding:0.4rem 2rem;
  padding:var(--space-scaled-xxs-wz9S8K, 0.4rem) var(--space-l-3cXvov, 2rem);
  font-weight:700;
  font-weight:var(--font-fontWeight-bold-2stUXL, 700);
  letter-spacing:0.25px;
  display:inline-block;
  text-decoration:none;
  cursor:pointer;
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27:not(#\9){
  background:#ffffff;
  background:var(--color-background-button-normal-default-3w9fMz, #ffffff);
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  border-color:#545b64;
  border-color:var(--color-border-button-normal-default-3zU3ek, #545b64);
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27:not(#\9):hover{
  background:#fafafa;
  background:var(--color-background-button-normal-hover-1OJlgw, #fafafa);
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
  border-color:#16191f;
  border-color:var(--color-border-button-normal-hover-ots1Pn, #16191f);
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27:not(#\9):active{
  background:#eaeded;
  background:var(--color-background-button-normal-active-2WWahd, #eaeded);
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j49f_7._variant-normal_vjswe_1j49f_27._disabled_vjswe_1j49f_66:not(#\9){
  background:#ffffff;
  background:var(--color-background-button-normal-disabled-3w9fMz, #ffffff);
  border-color:#d5dbdb;
  border-color:var(--color-border-button-normal-disabled-11nezK, #d5dbdb);
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74:not(#\9){
  background:#ec7211;
  background:var(--color-background-button-primary-default-2YgUpW, #ec7211);
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
  border-color:#ec7211;
  border-color:var(--color-background-button-primary-default-2YgUpW, #ec7211);
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74:not(#\9):hover{
  background:#eb5f07;
  background:var(--color-background-button-primary-hover-39GfvT, #eb5f07);
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
  border-color:#dd6b10;
  border-color:var(--color-border-button-primary-hover-2NzUKZ, #dd6b10);
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74:not(#\9):active{
  background:#dd6b10;
  background:var(--color-background-button-primary-active-1lqtcO, #dd6b10);
  color:#ffffff;
  color:var(--color-text-inverted-DnITSk, #ffffff);
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j49f_7._variant-primary_vjswe_1j49f_74._disabled_vjswe_1j49f_66:not(#\9){
  background:#ffffff;
  background:var(--color-background-button-primary-disabled-3w9fMz, #ffffff);
  border-color:#d5dbdb;
  border-color:var(--color-border-button-primary-disabled-11nezK, #d5dbdb);
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121:not(#\9){
  background:transparent;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  border-color:transparent;
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121:not(#\9):hover{
  background:#fafafa;
  background:var(--color-background-button-link-hover-1OJlgw, #fafafa);
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
  border-color:#fafafa;
  border-color:var(--color-background-button-link-hover-1OJlgw, #fafafa);
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121:not(#\9):active{
  background:#eaeded;
  background:var(--color-background-button-link-active-2WWahd, #eaeded);
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j49f_7._variant-link_vjswe_1j49f_121._disabled_vjswe_1j49f_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168:not(#\9){
  background:transparent;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
  border-color:transparent;
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168:not(#\9):hover{
  background:transparent;
  color:#16191f;
  color:var(--color-text-interactive-hover-18qOom, #16191f);
  border-color:transparent;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168:not(#\9):active{
  background:transparent;
  color:#545b64;
  color:var(--color-text-interactive-default-1oBHNI, #545b64);
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168._disabled_vjswe_1j49f_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215:not(#\9){
  background:transparent;
  color:#d5dbdb;
  color:var(--color-text-notification-icon-default-Xkg9ln, #d5dbdb);
  border-color:transparent;
  position:relative;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215:not(#\9):hover{
  background:transparent;
  color:#fafafa;
  color:var(--color-text-notification-icon-hover-JWSlel, #fafafa);
  border-color:transparent;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215:not(#\9):active{
  background:transparent;
  color:#d5dbdb;
  color:var(--color-text-notification-icon-default-Xkg9ln, #d5dbdb);
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215._disabled_vjswe_1j49f_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262:not(#\9){
  background:transparent;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  border-color:transparent;
  border-width:0;
  position:relative;
  text-decoration:none;
  padding:0;
  font-weight:normal;
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262:not(#\9):hover{
  background:transparent;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
  border-color:transparent;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262:not(#\9):active{
  background:transparent;
  color:#0073bb;
  color:var(--color-text-link-default-Bc0LvG, #0073bb);
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262:not(#\9):focus{
  outline:none;
  text-decoration:none;
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262[data-awsui-focus-visible=true]:not(#\9):focus{
  position:relative;
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262[data-awsui-focus-visible=true]:not(#\9):focus{
  outline:2px dotted transparent;
  outline-offset:calc(3px - 1px);
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262[data-awsui-focus-visible=true]:not(#\9):focus::before{
  content:" ";
  display:block;
  position:absolute;
  left:-3px;
  top:-3px;
  width:calc(100% + 2 * 3px);
  height:calc(100% + 2 * 3px);
  border-radius:2px;
  box-shadow:0 0 0 2px #00a1c9;
  box-shadow:0 0 0 2px var(--color-border-item-focused-2uP71z, #00a1c9);
}
._button_vjswe_1j49f_7._variant-breadcrumb-group_vjswe_1j49f_262._disabled_vjswe_1j49f_66:not(#\9){
  background:transparent;
  border-color:transparent;
  color:#aab7b8;
  color:var(--color-text-interactive-disabled-TVd7uP, #aab7b8);
  text-decoration:none;
  pointer-events:none;
  cursor:auto;
}
._button_vjswe_1j49f_7._button-no-text_vjswe_1j49f_312:not(#\9){
  padding-left:1.6rem;
  padding-left:var(--space-m-25I9Cr, 1.6rem);
  padding-right:1.6rem;
  padding-right:var(--space-m-25I9Cr, 1.6rem);
}
._button_vjswe_1j49f_7._button-no-wrap_vjswe_1j49f_316:not(#\9){
  white-space:nowrap;
}
._button_vjswe_1j49f_7._variant-icon_vjswe_1j49f_168:not(#\9), ._button_vjswe_1j49f_7._variant-flashbar-icon_vjswe_1j49f_215:not(#\9){
  padding-left:0.4rem;
  padding-left:var(--space-xxs-137Hig, 0.4rem);
  padding-right:0.4rem;
  padding-right:var(--space-xxs-137Hig, 0.4rem);
}
._button_vjswe_1j49f_7._variant-inline-icon_vjswe_1j49f_323:not(#\9){
  padding-top:0;
  padding-bottom:0;
  border:0;
}
._button_vjswe_1j49f_7 > ._icon-left_vjswe_1j49f_328:not(#\9){
  position:relative;
  left:calc(-1 * 0.4rem);
  left:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
  margin-right:0.4rem;
  margin-right:var(--space-xxs-137Hig, 0.4rem);
}
._button_vjswe_1j49f_7 > ._icon-right_vjswe_1j49f_333:not(#\9){
  position:relative;
  right:calc(-1 * 0.4rem);
  right:calc(-1 * var(--space-xxs-137Hig, 0.4rem));
  margin-left:0.4rem;
  margin-left:var(--space-xxs-137Hig, 0.4rem);
}
._button_vjswe_1j49f_7._button-no-text_vjswe_1j49f_312 > ._icon_vjswe_1j49f_328:not(#\9){
  margin-right:auto;
  margin-left:auto;
  right:0;
  left:0;
}